import { Layout } from "antd";
import { WebFooter } from "../../../components/WebFooter";
import { WebHeader } from "../../../components/WebHeader";
import "./index.less";

const { Content } = Layout;

export const WebTopNavLayout = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <Layout className="desktop-layout">
      <WebHeader />
      <Content className="desktop-layout-content">{children}</Content>
      <WebFooter />
    </Layout>
  );
};
