import { combineReducers, configureStore } from "@reduxjs/toolkit";
import createIdbStorage from "@piotr-cz/redux-persist-idb-storage";
// import { encryptTransform } from "redux-persist-transform-encrypt";
import storage from "redux-persist/lib/storage";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import userReducer from "../features/user/userSlice";
import dropdownParameterReducer from "../features/dropdownParameter/dropdownParameterSlice";
import applicationReducer from "../features/application/applicationSlice";
import smeApplicationReducer from "../features/smeApplication/smeApplicationSlice";
import smeAgreementReducer from "../features/smeAgreement/smeAgreementSlice";
import documentUploadReducer from "../features/documentUpload/documentUploadSlice";
import productReducer from "../features/product/productSlice";
import agreementReducer from "../features/agreement/agreementSlice";
import paymentReducer from "../features/payment/paymentSlice";
import preRecordedVideoReducer from "../features/preRecordedVideo/preRecordedVideoSlice";
import loanHistoryReducer from "../features/loanHistory/loanHistorySlice";
import configReducer from "../features/config/configSlice";
import collectionReducer from "../features/collection/collectionSlice";
import { clearLocalStorage } from "../utils/localStorage";

const combinedReducer = combineReducers({
  user: userReducer,
  dropdownParameter: dropdownParameterReducer,
  application: applicationReducer,
  smeApplication: smeApplicationReducer,
  documentUpload: documentUploadReducer,
  product: productReducer,
  agreement: agreementReducer,
  smeAgreement: smeAgreementReducer,
  collection: collectionReducer,
  payment: paymentReducer,
  preRecordedVideo: preRecordedVideoReducer,
  loanHistory: loanHistoryReducer,
  config: configReducer,
});

const rootReducer = (state: any, action: any) => {
  if (
    action.type === "user/logout/fulfilled" ||
    action.type === "user/login/pending"
  ) {
    state = undefined;
    storage.removeItem("persist:root");
    // clearLocalStorage();
    // console.log("All data clear!!");
  }
  return combinedReducer(state, action);
};

const persistConfig = {
  key: "root",
  storage: createIdbStorage({ name: "redcash", storeName: "redux" }),
  serialize: false, // Data serialization is not required and disabling it allows you to inspect storage value in DevTools; Available since redux-persist@5.4.0
  deserialize: false, // Required to bear same value as `serialize` since redux-persist@6.0
  // transforms: [
  //   encryptTransform({
  //     secretKey: process.env.REACT_APP_REDUX_PERSIST_STORE_KEY!,
  //     onError: function (error) {
  //       console.log(error);
  //     },
  //   }),
  // ],
};

// todo: seperate string and files to diff persistConfig storage

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
