import { beSetupAxiosInstance } from "../../axios/beSetupAxios";

const getVideoAttestation = async () => {
  const url = `/redCash/api/v1.0/file/videoAttestation`;
  const res = await beSetupAxiosInstance.get(url);
  return res.data;
};

const getVideoAttestationWatchStatus = async (requestData: any) => {
  const { attestationId } = requestData;

  const url = `/redCash/api/v1.0/attestation/${attestationId}/video/status`;
  const res = await beSetupAxiosInstance.get(url);
  return res.data;
};

const finishVideoAttestation = async (requestData: any) => {
  const url = `/redCash/api/v1.0/attestation/done-video-attestation`;
  const res = await beSetupAxiosInstance.post(url, requestData);
  return res.data;
};

const createAttestationSchedule = async (requestData: any) => {
  const url = `/redCash/api/v1.0/attestation/schedule/${requestData.language}`;
  const res = await beSetupAxiosInstance.post(url, requestData);
  return res.data;
};

const attestationService = {
  getVideoAttestation,
  getVideoAttestationWatchStatus,
  createAttestationSchedule,
  finishVideoAttestation,
};

export default attestationService;
