import "./MobileSmeAttestationOptionPage.less";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { MobileTopNavHeaderLayout } from "../../../layouts/mobile/MobileTopNavHeaderLayout/MobileTopNavHeaderLayout";
import { SmeAttestationOptionContent } from "../../../components/SmeAttestationOptionContent";

const MobileSmeAttestationOptionPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["common", "attestationOption"]);

  return (
    <MobileTopNavHeaderLayout
      title={t("attestationOption:pageTitle")}
      onBack={() => navigate(`/sme/home`)}
    >
      <SmeAttestationOptionContent />
    </MobileTopNavHeaderLayout>
  );
};

export default MobileSmeAttestationOptionPage;
