import { createAsyncThunk } from "@reduxjs/toolkit";

import { restructureErrorMessage } from "../../helpers/errorMessage";
import ekycService from "./ekycService";

export const checkEkycResultByUserIdThunk = createAsyncThunk(
  "ekyc/checkEkycResultByUserId",
  async (requestData: any, thunkAPI) => {
    try {
      return await ekycService.checkEkycResultByUserId(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
export const getEkycResultDetailsByUserIdThunk = createAsyncThunk(
  "ekyc/getEkycResultDetailsByUserId",
  async (requestData: any, thunkAPI) => {
    try {
      return await ekycService.getEkycResultDetailsByUserId(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const checkEkycResultByApplicationIdThunk = createAsyncThunk(
  "ekyc/checkEkycResultByApplicationId",
  async (requestData: any, thunkAPI) => {
    try {
      return await ekycService.checkEkycResultByApplicationId(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getEkycResultByApplicationIdThunk = createAsyncThunk(
  "ekyc/getEkycResultByApplicationId",
  async (requestData: any, thunkAPI) => {
    try {
      return await ekycService.getEkycResultByApplicationId(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
