import { generateQuery } from "../utils/generateQuery.util";

export const downloadLink = (response: any) => {
  //Build a URL from the file
  const fileURL = URL.createObjectURL(response);

  const link = document.createElement("a");
  link.href = fileURL;
  link.setAttribute("download", response.name);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
interface previewFile {
  fileId: string;
  name: string;
  defaultUrl: string;
}
export const previewNewPage = (response: previewFile) => {
  const obj = {
    token: localStorage.getItem("accessToken") || "",
    fileId: response.fileId,
  };

  const queryString = generateQuery(obj);
  const url = `${response.defaultUrl}/${response.name}${queryString}`;

  window.open(url, "_blank");
};
