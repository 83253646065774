import "./index.less";

import { Space } from "antd";
import { useTranslation } from "react-i18next";
import { createBreakpoint } from "react-use";

import featureBenefitIcon1 from "../../assets/icons/feature-benefit-1.png";
import featureBenefitIcon2 from "../../assets/icons/feature-benefit-2.png";
import featureBenefitIcon3 from "../../assets/icons/feature-benefit-3.png";
import { dangerTextRed } from "../../assets/style/variables";

const useBreakpoint = createBreakpoint();

export const FeaturesAndBenefits = () => {
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === "tablet";
  const { t } = useTranslation(["common", "featuresAndBenefits"]);

  return (
    <div className="features-section">
      <h3 className="section-title">
        {t("featuresAndBenefits:Features_Benefits")}
      </h3>
      <div className="flex justify-center">
        <div>
          <Space
            direction={isMobile ? "vertical" : "horizontal"}
            size={isMobile ? "small" : 180}
            align="center"
            className="w-full"
          >
            <div className="feature">
              <img src={featureBenefitIcon1} alt="online application icon" />
              <p>{t("featuresAndBenefits:featureDesc1")}</p>
            </div>
            <div className="feature">
              <img src={featureBenefitIcon2} alt="online application icon" />
              <p>{t("featuresAndBenefits:featureDesc2")}</p>
            </div>
            <div className="feature">
              <img src={featureBenefitIcon3} alt="online application icon" />
              <p>{t("featuresAndBenefits:featureDesc3")}</p>
            </div>
          </Space>
          <span
            className="flex justify-center mt-6"
            style={{ color: dangerTextRed }}
          >
            {t("featuresAndBenefits:featureTnC")}
          </span>
        </div>
      </div>
    </div>
  );
};
