import { Alert, Space } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { createBreakpoint } from "react-use";

import { numberWithCommas } from "../../../helpers/number";
import { RootState } from "../../../store/store";

const useBreakpoint = createBreakpoint();

const LoanPaymentDisbursedContent = () => {
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === "tablet";
  const { t } = useTranslation(["common", "loanStatus"]);

  const { applicationDetails } = useSelector(
    (state: RootState) => state.application,
  );

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <div
        className="flex-center-vertical"
        style={{ alignItems: isMobile ? "center" : "flex-start" }}
      >
        <h2 className="font-bold">
          Loan Agreement: {applicationDetails.data.agreement?.agreementNo}
        </h2>
        <span>{t("loanStatus:Your_Loan_Amount")}</span>
        <h1>
          RM{" "}
          {numberWithCommas(
            applicationDetails?.data?.financeAmount ??
              applicationDetails?.data?.loanApplied ??
              0,
          )}
        </h1>
      </div>
      <Alert
        className="alert-card"
        style={{ textAlign: isMobile ? "center" : "left" }}
        message={
          <h3 className="alert-title-success">
            {t("loanStatus:loan_payment_disbursed_title")}
          </h3>
        }
        description={
          <p style={{ margin: 0 }}>
            {t("loanStatus:loan_payment_disbursed_description")}
          </p>
        }
        type="success"
      />
    </Space>
  );
};

export default LoanPaymentDisbursedContent;
