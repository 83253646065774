import { PageHeader } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { VideoAttestationContent } from "../../../components/VideoAttestationContent";
import { WebLoanStatusLayout } from "../../../layouts/web/WebLoanStatusLayout";
import { WebSmeLoanStatusLayout } from "../../../layouts/web/WebSmeLoanStatusLayout";
import { SmeVideoAttestationContent } from "../../../components/SmeVideoAttestationContent";

const WebSmeVideoAttestationPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["common", "videoAttestation"]);

  return (
    <WebSmeLoanStatusLayout step={2}>
      <div className="max-w-xl w-full px-4 mx-auto">
        <PageHeader
          style={{ padding: 12 }}
          title={<strong>{t("videoAttestation:pageTitle")}</strong>}
          onBack={() => navigate(-1)}
        />
        <SmeVideoAttestationContent />
      </div>
    </WebSmeLoanStatusLayout>
  );
};

export default WebSmeVideoAttestationPage;
