import { Button, Col, Modal, Row } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { LoadingOutlined } from "@ant-design/icons";

import { primaryColor } from "../../../assets/style/variables";
import { setCancelApplication } from "../../../features/application/applicationSlice";
import { resetAllUserDataForReapplyFromCancelled } from "../../../features/user/userSlice";
import { cancelApplicationThunk } from "../../../services/applicationService/applicationThunk";
import { AppDispatch, RootState } from "../../../store/store";
import { createBreakpoint } from "react-use";

const useBreakpoint = createBreakpoint();

const ConfirmCancelApplication = () => {
  const dispatch = useDispatch<AppDispatch>();
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === "tablet";
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation(["common", "loanStatus"]);

  const { applicationDetails } = useSelector(
    (state: RootState) => state.application,
  );

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onDeleteApplication = () => {
    setLoading(true);

    dispatch(
      cancelApplicationThunk({
        applicationId: applicationDetails?.data?.id,
      }),
    )
      .unwrap()
      .then(async (res) => {
        dispatch(setCancelApplication());
        dispatch(resetAllUserDataForReapplyFromCancelled());
      })
      .finally(() => {
        setIsModalOpen(false);
        setLoading(false);
      });
  };

  return (
    <>
      <button
        className="secondary-button"
        disabled={loading}
        onClick={showModal}
        style={{ width: isMobile ? "100%" : "50%" }}
      >
        {t("common:Cancel")}
      </button>
      <Modal
        open={isModalOpen}
        onOk={onDeleteApplication}
        onCancel={handleCancel}
        footer={null}
        width={400}
        style={{ padding: 24, textAlign: "center" }}
        destroyOnClose
      >
        <h2 style={{ color: primaryColor }}>{t("common:Attention")}</h2>
        <p style={{ padding: 8 }}>{t("common:cancel_agreement_warning_msg")}</p>
        <Row gutter={[16, 16]} style={{ margin: "16px 0 8px" }}>
          <Col span={24}>
            <button
              className="primary-button"
              disabled={loading}
              onClick={() => onDeleteApplication()}
            >
              {loading && (
                <LoadingOutlined style={{ fontSize: 30, marginRight: 8 }} />
              )}
              {t("common:Yes")}
            </button>
          </Col>
          <Col span={24}>
            <button
              className="secondary-button"
              disabled={loading}
              onClick={() => handleCancel()}
            >
              {t("common:No")}
            </button>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default ConfirmCancelApplication;
