import { PageHeader } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { LoanAgreementContent } from "../../../components/LoanAgreementContent";
import { WebLoanStatusLayout } from "../../../layouts/web/WebLoanStatusLayout";
import { RootState } from "../../../store/store";
import { WebSmeLoanStatusLayout } from "../../../layouts/web/WebSmeLoanStatusLayout";
import { SmeLoanAgreementContent } from "../../../components/SmeLoanAgreementContent";

const WebSmeLoanAgreementPage = () => {
  const navigate = useNavigate();
  const { language } = useSelector((state: RootState) => state.config);
  const { t } = useTranslation(["common", "loanAgreement"]);

  const onBack = () => navigate(`/sme/home?language=${language}`);

  return (
    <WebSmeLoanStatusLayout step={4}>
      <div className="max-w-3xl w-full px-4 mx-auto">
        <PageHeader
          style={{ padding: 12 }}
          onBack={onBack}
          title={<strong>{t("loanAgreement:pageTitle")}</strong>}
        />
        <SmeLoanAgreementContent />
      </div>
    </WebSmeLoanStatusLayout>
  );
};

export default WebSmeLoanAgreementPage;
