import { beSetupAxiosInstance } from "../../axios/beSetupAxios";
import { CreateApplicationDraftPayload } from "../../features/application/applicationSlice";

const getSmeApplicationDetails = async (requestData: any) => {
  const { applicationId } = requestData;
  const url = `/redCash/api/v1.0/smeApplication/${applicationId}`;
  const res = await beSetupAxiosInstance.get(url);
  return res.data;
};

const getApplicationStepStatus = async (requestData: any) => {
  const { applicationId } = requestData;

  const url = `/redCash/api/v1.0/application/${applicationId}/status`;
  const res = await beSetupAxiosInstance.get(url);
  return res.data;
};

const testEligibility = async (requestData: any) => {
  const url = `/redCash/api/v1.0/redone/validateNric`;
  const res = await beSetupAxiosInstance.post(url, requestData);
  return res.data;
};

const createSmeApplicationDraft = async (requestData: any) => {
  const url = `/redCash/api/v1.0/smeApplication/draft`;

  const res = await beSetupAxiosInstance.post(url, requestData);
  return res.data;
};

const patchSmeApplicationToPending = async (requestData: any) => {
  const { applicationId } = requestData;
  const url = `/redCash/api/v1.0/smeApplication/${applicationId}/pending`;

  const res = await beSetupAxiosInstance.patch(url);
  return res.data;
};

const updateApplication = async (requestData: any) => {
  const { applicationId, payload, language } = requestData;
  const url = `/redCash/api/v1.0/application/${language}/${applicationId}`;

  const res = await beSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const deleteApplication = async (requestData: any) => {
  const { applicationId } = requestData;
  const url = `/redCash/api/v1.0/application/${applicationId}`;

  const res = await beSetupAxiosInstance.delete(url);
  return res.data;
};

const cancelApplication = async (requestData: any) => {
  const { applicationId, payload } = requestData;
  const url = `/redCash/admin/api/v1.0/application/${applicationId}/status/cancel`;

  const res = await beSetupAxiosInstance.patch(url, payload);
  return res.data;
};

const patchApplicationFormCompletedStep = async (requestData: any) => {
  const { applicationId } = requestData;
  const url = `/redCash/api/v1.0/application-status-step/${applicationId}/application-form-completed`;

  const res = await beSetupAxiosInstance.patch(url);
  return res.data;
};

const patchEkycCompletedStep = async (requestData: any) => {
  const { applicationId } = requestData;
  const url = `/redCash/api/v1.0/application-status-step/${applicationId}/ekyc-completed`;

  const res = await beSetupAxiosInstance.patch(url);
  return res.data;
};

const patchEmailVerificationCompletedStep = async (requestData: any) => {
  const { applicationId } = requestData;
  const url = `/redCash/api/v1.0/application-status-step/${applicationId}/email-varification-completed`;

  const res = await beSetupAxiosInstance.patch(url);
  return res.data;
};

const patchDocumentUploadCompletedStep = async (requestData: any) => {
  const { applicationId } = requestData;
  const url = `/redCash/api/v1.0/application-status-step/${applicationId}/document-upload-completed`;

  const res = await beSetupAxiosInstance.patch(url);
  return res.data;
};

const patchApplicationStepError = async (requestData: any) => {
  const { applicationId, stepName } = requestData;
  const url = `/redCash/api/v1.0/application-status-step/${applicationId}/${stepName}/error`;

  const res = await beSetupAxiosInstance.patch(url);
  return res.data;
};

const getLatestApplicationStatus = async (requestData: any) => {
  const { userId } = requestData;
  const url = `/redCash/api/v1.0/application/userId/${userId}`;
  const res = await beSetupAxiosInstance.get(url);

  return res.data;
};

const smeApplicationService = {
  patchApplicationFormCompletedStep,
  patchEkycCompletedStep,
  patchEmailVerificationCompletedStep,
  patchDocumentUploadCompletedStep,
  patchApplicationStepError,
  getSmeApplicationDetails,
  getApplicationStepStatus,
  createSmeApplicationDraft,
  updateApplication,
  deleteApplication,
  cancelApplication,
  testEligibility,
  getLatestApplicationStatus,
  patchSmeApplicationToPending,
};

export default smeApplicationService;
