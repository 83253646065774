import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { refreshAccessToken } from "../utils/auth.util";
import { message } from "antd";
import { restructureErrorMessage } from "../helpers/errorMessage";
import { generateFileName } from "../helpers/addFileNameHeader";

export const collectionSetupAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_COLLECTION_URL,
});

collectionSetupAxiosInstance.interceptors.request.use(function (
  config: AxiosRequestConfig,
) {
  const token = localStorage.getItem("accessToken");
  if (config.headers)
    if (token) config.headers["Authorization"] = `Bearer ${token}`;

  return config;
});

collectionSetupAxiosInstance.interceptors.response.use(
  function (res: AxiosResponse) {
    // process need to perform before return response
    generateFileName(res);
    return res;
  },
  async function (error: any) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      await refreshAccessToken();
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      return collectionSetupAxiosInstance(originalRequest);
    } else {
      if (
        restructureErrorMessage(error) !== "Request failed with status code 400"
      ) {
        message.error(restructureErrorMessage(error));
      }
    }
    return Promise.reject(error);
  },
);
