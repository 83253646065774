import { createAsyncThunk } from "@reduxjs/toolkit";

import { restructureErrorMessage } from "../../helpers/errorMessage";
import collectionService from "./collectionService";

export const getAccountDetailsThunk = createAsyncThunk(
  "collection/accountEnquiry",
  async (requestData: any, thunkAPI) => {
    try {
      return await collectionService.getAccountDetails(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getEnglishAccountStatementbyAgreementIdThunk = createAsyncThunk(
  "collection/getEnglishAccountStatementbyAgreementId",
  async (requestData: any, thunkAPI) => {
    try {
      return await collectionService.getEnglishAccountStatementbyAgreementId(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getMalayAccountStatementbyAgreementIdThunk = createAsyncThunk(
  "collection/getMalayAccountStatementbyAgreementId",
  async (requestData: any, thunkAPI) => {
    try {
      return await collectionService.getMalayAccountStatementbyAgreementId(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getAccountStatementThunk = createAsyncThunk(
  "collection/getAccountStatement",
  async (requestData: any, thunkAPI) => {
    try {
      return await collectionService.getAccountStatement(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getAccountTenureDetailsThunk = createAsyncThunk(
  "collection/getAccountTenureDetails",
  async (requestData: any, thunkAPI) => {
    try {
      return await collectionService.getAccountTenureDetails(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
