import "./MobileFooter.less";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { RootState } from "../../store/store";

export default function MobileFooter() {
  const { language } = useSelector((state: RootState) => state.config);
  const { t } = useTranslation(["common"]);

  return (
    <div className="footer-text-button-group">
      <a href={`/about-us?language=${language}`}>{t("common:About_Us")}</a>
      <a href={`/legal-terms?language=${language}`}>{t("common:Legal")}</a>
      <a href={`/faq?language=${language}`}>{t("common:FAQs")}</a>
      <a href={`/contact-us?language=${language}`}>{t("common:Contact_Us")}</a>
    </div>
  );
}
