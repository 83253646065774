import { Card } from "antd";
import { useTranslation } from "react-i18next";
import { createBreakpoint } from "react-use";

const useBreakpoint = createBreakpoint();

const RequirementDocumentTable = () => {
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === "tablet";

  const { t } = useTranslation(["common", "requirementPanel"]);

  return isMobile ? (
    <div className="flex flex-col gap-4">
      <Card bodyStyle={{ padding: 0 }}>
        <table className="red-table">
          <thead>
            <tr>
              <th></th>
              <th>{t("requirementPanel:For_Individual_Salaried_Employee")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>i.</td>
              <td>{t("requirementPanel:salariedEmployeeDocument1")}</td>
            </tr>
            <tr>
              <td>ii.</td>
              <td>{t("requirementPanel:salariedEmployeeDocument2")}</td>
            </tr>
            <tr>
              <td>iii.</td>
              <td>{t("requirementPanel:salariedEmployeeDocument3")}</td>
            </tr>
            <tr>
              <td>iv.</td>
              <td>{t("requirementPanel:salariedEmployeeDocument4")}</td>
            </tr>
          </tbody>
        </table>
      </Card>
      <Card bodyStyle={{ padding: 0 }}>
        <table className="red-table">
          <thead>
            <tr>
              <th></th>
              <th>{t("requirementPanel:For_Individual_Self_Employed")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>i.</td>
              <td>{t("requirementPanel:selfEmployedDocument1")}</td>
            </tr>
            <tr>
              <td>ii.</td>
              <td>{t("requirementPanel:selfEmployedDocument2")}</td>
            </tr>
            <tr>
              <td>iii.</td>
              <td>{t("requirementPanel:selfEmployedDocument3")}</td>
            </tr>
            <tr>
              <td>iv.</td>
              <td>{t("requirementPanel:selfEmployedDocument4")}</td>
            </tr>
            <tr>
              <td>v.</td>
              <td>{t("requirementPanel:selfEmployedDocument5")}</td>
            </tr>
            <tr>
              <td>vi.</td>
              <td>{t("requirementPanel:selfEmployedDocument6")}</td>
            </tr>
          </tbody>
        </table>
      </Card>
    </div>
  ) : (
    <Card bodyStyle={{ padding: 0 }}>
      <table className="red-table" style={{ tableLayout: "fixed" }}>
        <thead>
          <tr>
            <th className="w-8"></th>
            <th>{t("requirementPanel:For_Individual_Salaried_Employee")}</th>
            <th>{t("requirementPanel:For_Individual_Self_Employed")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>i.</td>
            <td>{t("requirementPanel:salariedEmployeeDocument1")}</td>
            <td>{t("requirementPanel:selfEmployedDocument1")}</td>
          </tr>
          <tr>
            <td>ii.</td>
            <td>{t("requirementPanel:salariedEmployeeDocument2")}</td>
            <td>{t("requirementPanel:selfEmployedDocument2")}</td>
          </tr>
          <tr>
            <td>iii.</td>
            <td>{t("requirementPanel:salariedEmployeeDocument3")}</td>
            <td>{t("requirementPanel:selfEmployedDocument3")}</td>
          </tr>
          <tr>
            <td>iv.</td>
            <td>{t("requirementPanel:salariedEmployeeDocument4")}</td>
            <td>{t("requirementPanel:selfEmployedDocument4")}</td>
          </tr>
          <tr>
            <td>v.</td>
            <td></td>
            <td>{t("requirementPanel:selfEmployedDocument5")}</td>
          </tr>
          <tr>
            <td>vi.</td>
            <td></td>
            <td>{t("requirementPanel:selfEmployedDocument6")}</td>
          </tr>
        </tbody>
      </table>
    </Card>
  );
};

export default RequirementDocumentTable;
