import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "./index.less";

import { message } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Document, Page, pdfjs } from "react-pdf";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RootState } from "../../store/store";

pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";
// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export const SmeLoanAgreementContent: React.FC = () => {
  const [pdfLoading, setPdfLoading] = useState(true);
  const navigate = useNavigate();

  const { language } = useSelector((state: RootState) => state.config);
  const { t } = useTranslation(["common", "loanAgreement"]);

  const [numPages, setNumPages] = useState(null);
  const [pdfContainerWidth, setPdfContainerWidth] = useState(0);

  const pdfContainerRef = useRef<any>(null);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
    setPdfLoading(false);
  }

  const { applicationDetails } = useSelector(
    (state: RootState) => state.application,
  );
  const { agreementDetails } = useSelector(
    (state: RootState) => state.agreement,
  );

  const agreementUrl = `${process.env.REACT_APP_BE_SETUP_URL}/redCash/api/v1.0/pdf/jadualPdf/preview/en/company/${process.env.REACT_APP_COMPANY_ID}/agreement/${agreementDetails?.data?.id}`;
  // const agreementUrl = `https://cdn.filestackcontent.com/wcrjf9qPTCKXV3hMXDwK`;

  const onSubmit = () => {
    navigate(`/loan-status/digital-signing`);
  };

  useEffect(() => {
    setPdfContainerWidth(pdfContainerRef?.current?.clientWidth);
  }, []);

  useEffect(() => {
    if (applicationDetails?.data?.id) {
      if (applicationDetails?.data?.applicantFirstName == null) {
        message.error(t("loanAgreement:applicantNameNullErrMsg"));
      }
    }
  }, [applicationDetails?.data, t]);

  return (
    <div className="p-4 w-full h-full">
      {/* {pdfLoading && <div style={{ textAlign: "center" }}>Loading PDF...</div>} */}
      {/* <iframe
        title="Loan Agreement"
        className="h-[70vh] w-full"
        // src={agreementUrl}
        // src={
        //   "https://drive.google.com/viewerng/viewer?embedded=true&url=" +
        //   encodeURIComponent(agreementUrl)
        // }
        onLoad={() => setPdfLoading(false)}
      /> */}
      <div
        className="h-[70vh] w-full overflow-scroll border border-solid border-slate-600"
        ref={pdfContainerRef}
      >
        <Document
          file={agreementUrl + "#toolbar=0"}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              width={pdfContainerWidth}
              pageNumber={index + 1}
            />
          ))}
        </Document>
      </div>
      <button
        className="text-button w-full mb-2"
        onClick={() => window.open(agreementUrl)}
      >
        {t("loanAgreement:downloadBtnLabel")}
      </button>
      <button
        type="button"
        className="primary-button"
        disabled={pdfLoading}
        onClick={onSubmit}
      >
        {t("loanAgreement:submitBtnLabel")}
      </button>
    </div>
  );
};
