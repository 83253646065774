export const dataUrlToFile = (
  url: string,
  filename: string,
  mimeType = "image/jpeg",
) => {
  return fetch("data:image/jpeg;base64," + url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
};

export const renameFile = (originalFile: any, newName?: any) => {
  const file: any = new File([originalFile], newName ?? originalFile.name, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });

  file["uid"] = originalFile?.uid;

  return file;
};
