import "./index.less";

import { List } from "antd";
import React from "react";

import { FileTextOutlined } from "@ant-design/icons";

export type ApplicationFileListProps = {
  dataSource?: any[];
  itemOnClick: (fileId: string) => void;
};

const ApplicationFileList: React.FC<ApplicationFileListProps> = (props) => {
  const { dataSource, itemOnClick } = props;
  return (
    <List
      className="file-list"
      itemLayout="horizontal"
      dataSource={dataSource}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            avatar={<FileTextOutlined />}
            title={
              <button
                className="file-link text-left"
                onClick={() => itemOnClick(item.id)}
              >
                {item.filename
                  ? item.filename.substring(item.filename.indexOf("-") + 1)
                  : "undefined"}
              </button>
            }
          />
        </List.Item>
      )}
    />
  );
};

ApplicationFileList.defaultProps = {
  dataSource: [],
};

export default ApplicationFileList;
