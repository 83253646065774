import "./MobileSmeLoanAgreementPage.less";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { LoanAgreementContent } from "../../../components/LoanAgreementContent";
import { MobileTopNavHeaderLayout } from "../../../layouts/mobile/MobileTopNavHeaderLayout/MobileTopNavHeaderLayout";
import { SmeLoanAgreementContent } from "../../../components/SmeLoanAgreementContent";

const MobileSmeLoanAgreementPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["common", "loanAgreement"]);

  const toHome = () => navigate(`/home`);

  return (
    <MobileTopNavHeaderLayout
      title={t("loanAgreement:pageTitle")}
      onBack={toHome}
    >
      <SmeLoanAgreementContent />
    </MobileTopNavHeaderLayout>
  );
};

export default MobileSmeLoanAgreementPage;
