import "./index.less";

import { NavBar } from "antd-mobile";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { AccountCreationForm } from "../../../components/AccountCreationForm";
import { styleBrandLabel } from "../../../utils/stringFormatter";
import { SmeAccountCreationForm } from "../../../components/SmeAccountCreationForm";

const MobileSmeAccountCreationPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["common", "registration"]);

  return (
    <>
      <NavBar onBack={() => navigate("/sme/home")}>
        <strong>
          {styleBrandLabel(t("registration:account_creation_form_title"))}
        </strong>
      </NavBar>
      <p className="px-5">{t("registration:account_creation_description")}</p>
      <div className="mx-4">
        <SmeAccountCreationForm />
      </div>
    </>
  );
};

export default MobileSmeAccountCreationPage;
