import { Alert, Col, Row, Space } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createBreakpoint } from "react-use";

import { LoadingOutlined } from "@ant-design/icons";

import { numberWithCommas } from "../../../helpers/number";
import { RootState } from "../../../store/store";
import ConfirmDeleteApplication from "../../modals/ConfirmDeleteApplication";

const useBreakpoint = createBreakpoint();

const ReadyToCreateStatusContent = () => {
  const navigate = useNavigate();
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === "tablet";
  const { t } = useTranslation(["common", "loanStatus"]);

  const [loading, setLoading] = useState(false);

  const {
    updateSmeApplicationDraftFormData,
    applicationDetails,
    partnerDetails,
  } = useSelector((state: RootState) => state.smeApplication);

  const proceedToApplication = () => {
    // setLoading(true);
    if (
      updateSmeApplicationDraftFormData.appliedLoanAmount &&
      updateSmeApplicationDraftFormData.appliedLoanTenure &&
      updateSmeApplicationDraftFormData.loanReason
    ) {
      console.log(partnerDetails);

      if (partnerDetails.organizationType === "INDIVIDUAL") {
        navigate(`/smeIndividual/application`);
      }
      if (partnerDetails.organizationType === "SOLE_PROP") {
        navigate(`/smeSoleProp/application`);
      }
      if (partnerDetails.organizationType === "COMPANY") {
        navigate(`/smeCompany/application`);
      }
      if (partnerDetails.organizationType === "PARTNERSHIP") {
        navigate(`/smePartnership/application`);
      }
    } else {
      navigate(`/sme/testing-eligibility`);
    }
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <div
        className="flex-center-vertical"
        style={{ alignItems: isMobile ? "center" : "flex-start" }}
      >
        <span>{t("loanStatus:Your_Requested_Loan_Amount")}</span>
        <h1>
          {`RM ${numberWithCommas(
            applicationDetails?.data?.appliedLoanAmount ?? 0,
          )}`}
        </h1>
      </div>
      <Alert
        className="alert-card"
        style={{ textAlign: isMobile ? "center" : "left" }}
        message={
          <h3 className="alert-title-success">
            {t("loanStatus:ready_to_create_title")}
          </h3>
        }
        description={
          <p style={{ margin: 0 }}>
            {t("loanStatus:ready_to_create_description")}
          </p>
        }
        type="success"
      />
      <Row gutter={[16, 16]} style={{ width: "100%", marginTop: 16 }}>
        <Col xs={24} sm={24} md={12}>
          <button
            className="primary-button"
            disabled={loading}
            onClick={() => proceedToApplication()}
          >
            {loading && (
              <LoadingOutlined style={{ fontSize: 30, marginRight: 8 }} />
            )}
            {t("loanStatus:Proceed_to_Application")}
          </button>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <ConfirmDeleteApplication inDraftApplicationPage={false} />
        </Col>
      </Row>
    </Space>
  );
};

export default ReadyToCreateStatusContent;
