import { createAsyncThunk } from "@reduxjs/toolkit";
import applicationService from "./applicationService";
import { restructureErrorMessage } from "../../helpers/errorMessage";
import { CreateApplicationDraftPayload } from "../../features/application/applicationSlice";

export const getApplicationDetailsThunk = createAsyncThunk(
  "application/getDetails",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.getApplicationDetails(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getApplicationStepStatusThunk = createAsyncThunk(
  "application/getStepStatus",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.getApplicationStepStatus(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const testEligibilityThunk = createAsyncThunk(
  "application/testEligibility",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.testEligibility(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createApplicationDraftThunk = createAsyncThunk(
  "application/createDraft",
  async (requestData: CreateApplicationDraftPayload, thunkAPI) => {
    try {
      return await applicationService.createApplicationDraft(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateApplicationThunk = createAsyncThunk(
  "application/update",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.updateApplication(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const deleteApplicationThunk = createAsyncThunk(
  "application/delete",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.deleteApplication(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const cancelApplicationThunk = createAsyncThunk(
  "application/cancel",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.cancelApplication(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const patchApplicationFormCompletedStepThunk = createAsyncThunk(
  "application/application-form-completed",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.patchApplicationFormCompletedStep(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const patchEkycCompletedStepThunk = createAsyncThunk(
  "application/ekyc-completed",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.patchEkycCompletedStep(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const patchEmailVerificationCompletedStepThunk = createAsyncThunk(
  "application/email-verification-completed",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.patchEmailVerificationCompletedStep(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const patchDocumentUploadCompletedStepThunk = createAsyncThunk(
  "application/document-upload-completed",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.patchDocumentUploadCompletedStep(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const patchApplicationStepErrorThunk = createAsyncThunk(
  "application/application-step-error",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.patchApplicationStepError(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getLatestApplicationStatusThunk = createAsyncThunk(
  "application/getLatestStatus",
  async (requestData: any, thunkAPI) => {
    try {
      return await applicationService.getLatestApplicationStatus(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
