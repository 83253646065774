import { VerificationCompleteContent } from "../../../components/VerificationCompleteContent";
import { WebApplyApplicationLayout } from "../../../layouts/web/WebApplyApplicationLayout";

import "./WebSmeVerificationCompletePage.less";
import { SmeVerificationCompleteContent } from "../../../components/SmeVerificationCompleteContent";

const WebSmeVerificationCompletePage = () => {
  return (
    <WebApplyApplicationLayout step={5}>
      <div className="max-w-xl w-full px-4 m-auto mt-4">
        <SmeVerificationCompleteContent />
      </div>
    </WebApplyApplicationLayout>
  );
};

export default WebSmeVerificationCompletePage;
