import "./index.less";

import { Steps } from "antd";
import { useTranslation } from "react-i18next";
import { createBreakpoint } from "react-use";

import appStepIcon1 from "../../assets/icons/app-step-1.png";
import appStepIcon2 from "../../assets/icons/app-step-2.png";
import appStepIcon3 from "../../assets/icons/app-step-3.png";
import appStepIcon4 from "../../assets/icons/app-step-4.png";

const useBreakpoint = createBreakpoint();

export const ApplicationSteps = () => {
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === "tablet";
  const { t } = useTranslation(["common", "applicationSteps"]);

  const stepItems = [
    {
      title: t("applicationSteps:step1Label"),
      description: t("applicationSteps:step1Desc"),
      image: appStepIcon1,
    },
    {
      title: t("applicationSteps:step2Label"),
      description: t("applicationSteps:step2Desc"),
      image: appStepIcon2,
    },
    {
      title: t("applicationSteps:step3Label"),
      description: t("applicationSteps:step3Desc"),
      image: appStepIcon3,
    },
    {
      title: t("applicationSteps:step4Label"),
      description: t("applicationSteps:step4Desc"),
      image: appStepIcon4,
    },
  ];

  return (
    <div
      className={
        isMobile
          ? "app-step-container-vertical"
          : "app-step-container-horizontal"
      }
    >
      <h3 className="section-title">{t("applicationSteps:sectionTitle")}</h3>
      <div className={isMobile ? "app-steps-vertical" : "app-steps-horizontal"}>
        <div
          className={
            isMobile
              ? "app-step-icon-group-vertical"
              : "app-step-icon-group-horizontal"
          }
        >
          {Array.isArray(stepItems) &&
            stepItems.map((item) => (
              <img
                key={item.title}
                src={item.image}
                className={
                  isMobile
                    ? "app-step-icon-vertical"
                    : "app-step-icon-horizontal"
                }
                alt="icon"
              />
            ))}
        </div>
        <Steps
          progressDot
          current={5} // go over the step to make the text color same
          direction={isMobile ? "vertical" : "horizontal"}
          items={stepItems}
        />
      </div>
    </div>
  );
};
