import { createSlice } from "@reduxjs/toolkit";
import {
  getProductByReferralCodeThunk,
  getProductsThunk,
} from "../../services/productService/productThunk";

export type Product = {
  id: string;
  adminId: string;
  name: string;
  code: string;
  minAgeChecking: number;
  maxAgeChecking: number;
  minGrossIncomeChecking: number;
  interestRate: number;
  minTenure: number;
  maxTenure: number;
  intervalTenure: number;
  minLoanAmount: number,
  maxLoanAmount: number,
  intervalLoanAmount: number,
  processingFeeToDiscount: number;
  stampingDutyPercentage: number;
  processingFee: number;
  referralCode: string;
  createdAt?: string;
  updatedAt?: string;
};

export type productState = {
  productState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: Product[];
    selectedProduct: Product | null;
  };
};

export type SetproductIdPayload = {
  productId: string;
};

const initialState: productState = {
  productState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
    selectedProduct: null,
  },
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProductsThunk.pending, (state) => {
        state.productState.isLoading = true;
      })
      .addCase(getProductsThunk.fulfilled, (state, action) => {
        state.productState.isLoading = false;
        state.productState.data = action.payload;
        state.productState.selectedProduct = action.payload;
      })
      .addCase(getProductsThunk.rejected, (state, action) => {
        state.productState.isLoading = false;
        state.productState.isError = true;
        state.productState.errorMessage = action.payload;
      });

    builder
      .addCase(getProductByReferralCodeThunk.pending, (state) => {
        state.productState.isLoading = true;
      })
      .addCase(getProductByReferralCodeThunk.fulfilled, (state, action) => {
        state.productState.isLoading = false;

        if (action.payload) {
          state.productState.data = action.payload;
          state.productState.selectedProduct = action.payload;
        }
      })
      .addCase(getProductByReferralCodeThunk.rejected, (state, action) => {
        state.productState.isLoading = false;
        state.productState.isError = true;
        state.productState.errorMessage = action.payload;
      });
  },
});

// export const {} = productSlice.actions;

export default productSlice.reducer;
