import { beSetupAxiosInstance } from "../../axios/beSetupAxios";
import { CreateApplicationDraftPayload } from "../../features/application/applicationSlice";

const getSmeAgreementById = async (requestData: any) => {
  const { id } = requestData;
  const url = `/redCash/api/v1.0/smeAgreement/${id}`;
  const res = await beSetupAxiosInstance.get(url);
  return res.data;
};

const smeAgreementService = {
  getSmeAgreementById,
};

export default smeAgreementService;
