import "./WebSmeHomePage.less";

import { Col, Row } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import landingPageImg2 from "../../../assets/images/landing-page-image-2.png";
import { ApplicationSteps } from "../../../components/ApplicationSteps";
import { LoanApplicationStatusCard } from "../../../components/LoanApplicationStatusCard";
import { LoanStatusCard } from "../../../components/LoanStatusCard";
import { RepaymentDetails } from "../../../components/RepaymentDetails";
import RequirementPanel from "../../../components/RequirementPanel/RequirementPanel";
import { ApplicationStatusEnum } from "../../../enums/applicationStepStatus";
import { agreementSignatureStateReset } from "../../../features/agreement/agreementSlice";
import {
  setCurrentUser,
  setHasLoggedUser,
} from "../../../features/user/userSlice";
import { useInit } from "../../../hooks/useInit";
import { WebTopNavLayout } from "../../../layouts/web/WebTopNavLayout";
import { getAgreementDetailsThunk } from "../../../services/agreementService/agreementThunk";
import { getApplicationDetailsThunk } from "../../../services/applicationService/applicationThunk";
import { AppDispatch, RootState } from "../../../store/store";
import { onCalculateTotalDueAmount } from "../../../utils/calculation";
import { getAccountDetailsThunk } from "../../../services/collectionService/collectionThunk";
import { application } from "express";
import { SmeLoanApplicationStatusCard } from "../../../components/SmeLoanApplicationStatusCard";
import { getSmeApplicationDetailsThunk } from "../../../services/smeApplicationService/smeApplicationThunk";
import { jwtDecode } from "jwt-decode";
import {
  setPartnerDetails,
  setSmeApplicationDetails,
} from "../../../features/smeApplication/smeApplicationSlice";
import { getSmeAgreementByIdThunk } from "../../../services/smeAgreementService/smeAgreementThunk";
import { setSmeAgreementDetails } from "../../../features/smeAgreement/smeAgreementSlice";

const WebSmeHomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { applicationDetails, cancelledApplication } = useSelector(
    (state: RootState) => state.smeApplication,
  );

  const { currentUser } = useSelector((state: RootState) => state.user);
  const { registrationFormData } = useSelector(
    (state: RootState) => state.user,
  );

  const { accountEnquiry } = useSelector(
    (state: RootState) => state.collection,
  );
  const [showRepaymentDetail, setShowRepaymentDetail] = useState<any>();

  const { t } = useTranslation(["common", "home"]);

  const fetchApplicationDetails = () => {
    if (applicationDetails?.data?.id) {
      dispatch(
        getSmeApplicationDetailsThunk({
          applicationId: applicationDetails.data.id,
        }),
      );
    }
  };

  const fetchAgreementDetails = () => {
    if (applicationDetails?.data?.smeAgreementId) {
      dispatch(
        getAgreementDetailsThunk({
          agreementId: applicationDetails?.data?.smeAgreementId,
        }),
      );
    }
  };

  const resetAgreementSignatureState = () => {
    dispatch(agreementSignatureStateReset());
  };

  // const navigateToTestingEligibility = () => navigate("/sme/testing-eligibility");

  // const navigateToRegistration = () => navigate("/registration");

  const initialCallbackState = {
    fetchApplicationDetails,
    fetchAgreementDetails,
    resetAgreementSignatureState,
  };
  const [callbackState] = useState(initialCallbackState);

  useInit(async () => {
    if (registrationFormData?.currentlyEditing) {
      // callbackState.navigateToRegistration();
    } else if (!cancelledApplication && applicationDetails?.data?.id == null) {
      // callbackState.navigateToTestingEligibility();
    } else {
      callbackState.fetchApplicationDetails();
      if (applicationDetails?.data?.smeAgreementId) {
        callbackState.fetchAgreementDetails();
      }
    }
  });

  // useEffect(() => {
  //   if (cancelledApplication) {
  //     return;
  //   }
  //
  //   if (registrationFormData?.currentlyEditing) {
  //     // callbackState.navigateToRegistration();
  //   } else if (applicationDetails?.data?.id == null && !cancelledApplication) {
  //     // callbackState.navigateToTestingEligibility();
  //   }
  //
  //   //  if the application applied by user is rejected before,
  //   //  the user can only apply after allowToApplyNewLoanAfterDateTime
  //   const allowToApplyNewLoanAfterDateTime =
  //     currentUser?.data?.allowToApplyNewLoanAfterDateTime;
  //
  //   const isAfterAllowToApplyNewLoanAfterDateTime =
  //     allowToApplyNewLoanAfterDateTime != null &&
  //     dayjs().isAfter(dayjs(allowToApplyNewLoanAfterDateTime));
  //
  //   if (
  //     applicationDetails?.data?.applicationStatus ===
  //       ApplicationStatusEnum.REJECTED &&
  //     isAfterAllowToApplyNewLoanAfterDateTime
  //   ) {
  //     // callbackState.navigateToTestingEligibility();
  //   }
  // }, [
  //   applicationDetails,
  //   applicationDetails?.data.agreementId,
  //   applicationDetails?.data?.id,
  //   callbackState,
  //   cancelledApplication,
  //   currentUser?.data?.allowToApplyNewLoanAfterDateTime,
  //   registrationFormData?.currentlyEditing,
  // ]);
  //
  // useEffect(() => {
  //   if (applicationDetails?.data?.id) {
  //     callbackState.fetchApplicationDetails();
  //   }
  //
  //   if (applicationDetails?.data?.agreementId) {
  //     callbackState.fetchAgreementDetails();
  //   } else {
  //     callbackState.resetAgreementSignatureState();
  //   }
  // }, [
  //   dispatch,
  //   applicationDetails?.data?.agreementId,
  //   applicationDetails?.data?.id,
  //   callbackState,
  // ]);

  // useEffect(() => {
  //   if (applicationDetails?.data?.user) {
  //     dispatch(setCurrentUser(applicationDetails?.data?.user));
  //   }
  // }, [applicationDetails?.data?.user, dispatch]);

  useEffect(() => {
    if (applicationDetails?.data?.smeAgreement?.agreementNo)
      dispatch(
        getAccountDetailsThunk({
          agreementNo: applicationDetails.data.smeAgreement?.agreementNo,
        }),
      );
  }, [applicationDetails.data.smeAgreement?.agreementNo, dispatch]);

  useEffect(() => {
    if (applicationDetails?.data?.smeAgreementId)
      dispatch(
        getSmeAgreementByIdThunk({
          id: applicationDetails.data.smeAgreementId,
        }),
      );
  }, [applicationDetails.data.smeAgreementId, dispatch]);

  useEffect(() => {
    const showRepaymentDetail =
      applicationDetails?.data?.smeAgreement?.disbursementPostedDate &&
      Math.abs(
        dayjs(
          applicationDetails?.data?.smeAgreement?.disbursementPostedDate,
        ).diff(dayjs(), "day"),
      ) >=
        Number(process.env.REACT_APP_REPAYMENT_DETAILS_AFTER_DISBURSED_DAY) &&
      accountEnquiry.data.accountDetails?.status !== "CLOSED";

    setShowRepaymentDetail(showRepaymentDetail);
  }, [applicationDetails?.data]);

  useEffect(() => {
    // get token from URL params
    const urlParams = new URLSearchParams(window.location.search);
    const accessToken = urlParams.get("accessToken");
    const refreshToken = urlParams.get("refreshToken");
    const ssoAccessToken = urlParams.get("ssoAccessToken");

    console.log("accessToken", accessToken, accessToken == null);

    if (
      accessToken !== null &&
      refreshToken !== null &&
      ssoAccessToken !== null
    ) {
      localStorage.setItem("accessToken", accessToken ?? "");
      localStorage.setItem("refreshToken", refreshToken ?? "");
      localStorage.setItem("ssoAccessToken", ssoAccessToken ?? "");

      // set application details to state
      const decoded: any = jwtDecode(accessToken);

      dispatch(setHasLoggedUser(true));

      dispatch(
        setPartnerDetails({
          organizationType: decoded.organizationType,
          username: decoded.username,
        }),
      );

      if (decoded?.latestApplicationDetails) {
        dispatch(
          setSmeApplicationDetails({
            ...decoded?.latestApplicationDetails,
            redOneUsername: decoded?.username,
          }),
        );

        if (
          decoded?.latestApplicationDetails?.status ===
          ApplicationStatusEnum.APPROVED
        ) {
          dispatch(
            setSmeAgreementDetails({
              ...decoded?.latestApplicationDetails?.smeAgreement,
            }),
          );
        }

        navigate("/sme/home");
      } else {
        dispatch(
          setSmeApplicationDetails({
            ...applicationDetails.data,
            redOneUsername: decoded?.username,
          }),
        );

        navigate("/sme/testing-eligibility");
      }
    }
  }, []);

  return (
    <WebTopNavLayout>
      <div className="web-home-top-section">
        <div className="web-home-top-section-bg" />
        <div className="web-home-top-section-content">
          <h1 className="web-welcome-message break-words">
            {`${t("home:Welcome_back")}, ${""}`}
          </h1>
          {showRepaymentDetail ||
          applicationDetails.data.status ===
            ApplicationStatusEnum.RESCHEDULED_SIGNED ? (
            <RepaymentDetails />
          ) : (
            <SmeLoanApplicationStatusCard />
          )}
        </div>
      </div>

      <div className="h-12" />

      {!applicationDetails?.isLoading &&
      applicationDetails?.data?.status === ApplicationStatusEnum.APPROVED ? (
        <LoanStatusCard />
      ) : (
        <>
          <ApplicationSteps />
          <div className="h-12" />
          <Row
            justify="center"
            align="middle"
            className="max-w-[2000px] m-auto px-8"
          >
            <Col span={8}>
              <div className="requirement-image">
                <img src={landingPageImg2} alt="logo" />
              </div>
            </Col>
            <Col span={16}>
              <RequirementPanel />
              <div className="h-20" />
            </Col>
          </Row>
        </>
      )}
    </WebTopNavLayout>
  );
};

export default WebSmeHomePage;
