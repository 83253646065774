import { createSlice } from "@reduxjs/toolkit";
import { getLoanHistoryThunk } from "../../services/loanHistoryService/loanHistoryThunk";
import { Agreement } from "../agreement/agreementSlice";

export type LoanHistory = {
  id: string;
  actualMonthlySalary?: number;
  agreement?: Agreement;
  agreementId?: string;
  applicationNo?: string;
  applicationStatus?: string;
  bucket?: string;
  companyId?: string;
  customerType?: string;
  dsr?: number;
  eligibleLoanAmmount?: number;
  emergencyContactName?: string;
  emergencyContactNo?: string;
  emergencyContactPersonEmail?: string;
  emergencyContactRelationshipId?: string;
  employerContactNo?: string;
  employerName?: string;
  employmentStartingFrom?: string;
  employmentTypeId?: string;
  jobTitle?: string;
  loanApplied?: number;
  loanInterestRate?: number;
  loanReasonId?: string;
  loanTenure?: number;
  loanType?: string;
  personalBankAccountNo?: string;
  personalBankId?: string;
  productCode?: string;
  productId?: string;
  productInterestRate?: number;
  productIntervalTenure?: number;
  productMaxAgeChecking?: number;
  productMaxTenure?: number;
  productMinAgeChecking?: number;
  productMinGrossIncomeChecking?: number;
  productMinTenure?: number;
  productName?: string;
  productProcessingFee?: number;
  productProcessingFeeToDiscount?: number;
  productReferralCode?: string;
  productStampingDutyPercentage?: number;
  productStampingFee?: number;
  remarks?: string;
  submissionDate?: string;
  userId?: string;
  signedAgreementPdfFileId?: string;
  estampingFileId?: string;
  createdAt?: string;
  updatedAt?: string;
};

export type loanHistoryState = {
  loanHistories: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: LoanHistory[];
  };
};

export type SetloanHistoryIdPayload = {
  loanHistoryId: string;
};

const initialState: loanHistoryState = {
  loanHistories: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
};

export const loanHistorySlice = createSlice({
  name: "loanHistory",
  initialState,
  reducers: {
    setLoanHistories: (state, action) => {
      state.loanHistories.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLoanHistoryThunk.pending, (state) => {
        state.loanHistories.isLoading = true;
      })
      .addCase(getLoanHistoryThunk.fulfilled, (state, action) => {
        state.loanHistories.isLoading = false;
        state.loanHistories.data = action.payload;
      })
      .addCase(getLoanHistoryThunk.rejected, (state, action) => {
        state.loanHistories.isLoading = false;
        state.loanHistories.isError = true;
        state.loanHistories.errorMessage = action.payload;
      });
  },
});

export const { setLoanHistories } = loanHistorySlice.actions;

export default loanHistorySlice.reducer;
