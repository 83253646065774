import "./index.less";

import { Button, message } from "antd";
import { QRCodeSVG } from "qrcode.react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { createBreakpoint } from "react-use";

import { LoadingOutlined } from "@ant-design/icons";

import idCardIcon from "../../assets/icons/id-card.png";
import { setCurrentUserFullName } from "../../features/user/userSlice";
import {
  checkEkycResultByApplicationIdThunk,
  getEkycResultByApplicationIdThunk,
} from "../../services/ekycService/ekycThunk";
import { AppDispatch, RootState } from "../../store/store";
import EkycCheckList from "../EkycCheckList";
import { ApplicationStatusEnum } from "../../enums/applicationStepStatus";
import {
  getSmeApplicationDetailsThunk,
  patchSmeApplicationToPendingThunk,
} from "../../services/smeApplicationService/smeApplicationThunk";

const useBreakpoint = createBreakpoint();

export const SmeIdentityVerificationContent: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === "tablet";
  const location = useLocation();
  const { t } = useTranslation(["common", "identityVerification"]);

  const { language } = useSelector((state: RootState) => state.config);
  const { currentUser } = useSelector((state: RootState) => state.user);
  const { applicationDetails } = useSelector(
    (state: RootState) => state.smeApplication,
  );

  const [loading, setLoading] = useState(false);

  const { origin } = window.location;

  // TODO: get username form token
  const username = "6785820";

  const docType = currentUser?.data?.icType?.toLowerCase() ?? "mykad";
  const applicationId = applicationDetails.data.id;
  const companyId = process.env.REACT_APP_COMPANY_ID;
  const url = `/ekyc-sme.html?username=${username}&companyId=${companyId}&ekycUrl=${process.env.REACT_APP_EKYC_SETUP_URL}&docType=${docType}&applicationId=${applicationId}`;
  const qrCodeUrl = `${origin}/ekyc-sme.html?username=${username}&companyId=${companyId}&fromDesktop=true&ekycUrl=${process.env.REACT_APP_EKYC_SETUP_URL}&docType=${docType}&applicationId=${applicationId}`;

  const onCheckEkycResult = async () => {
    dispatch(getEkycResultByApplicationIdThunk({ applicationId }))
      .unwrap()
      .then((responseData) => {
        if (responseData.statusCode === 400) {
          const baseUrl = "/sme/application/verification-complete";
          const searchParams = {
            status: "failed",
            desc: "error:APIerror",
          };
          const params = new URLSearchParams(searchParams).toString();
          return navigate(`${baseUrl}?${params}&language=${language}`, {
            replace: true,
          });
        }

        if (
          Array.isArray(responseData) &&
          responseData.length > 0 &&
          responseData[0].icMatchWithDatabase === true &&
          responseData[0].ekycSuccess === true &&
          responseData[0].mykadFrontIsValid === true &&
          responseData[0].mykadBackIsValid === true &&
          responseData[0].mykadVsFaceIsMatch === true &&
          responseData[0].faceIsMatch === true
        ) {
          const baseUrl = "/sme/application/verification-complete";
          const searchParams = {
            status: "success",
            desc: "completed",
          };
          const params = new URLSearchParams(searchParams).toString();

          // update full name in global state
          dispatch(setCurrentUserFullName(responseData[0].name));

          return navigate(`${baseUrl}?${params}&language=${language}`, {
            replace: true,
          });
        }

        if (responseData[0].icMatchWithDatabase === false) {
          const baseUrl = "/sme/application/verification-complete";
          const searchParams = {
            status: "failed",
            desc: "icMatchWithDatabase:false",
          };
          const params = new URLSearchParams(searchParams).toString();
          return navigate(`${baseUrl}?${params}&language=${language}`, {
            replace: true,
          });
        }

        if (responseData[0].ekycSuccess === false) {
          const baseUrl = "/sme/application/verification-complete";
          const searchParams = {
            status: "failed",
            desc: "ekycSuccess:false",
          };
          const params = new URLSearchParams(searchParams).toString();
          return navigate(`${baseUrl}?${params}&language=${language}`, {
            replace: true,
          });
        }

        if (responseData[0].mykadFrontIsValid === false) {
          const baseUrl = "/sme/application/verification-complete";
          const searchParams = {
            status: "failed",
            desc: "mykadFrontIsValid:false",
          };
          const params = new URLSearchParams(searchParams).toString();
          return navigate(`${baseUrl}?${params}&language=${language}`, {
            replace: true,
          });
        }

        if (responseData[0].mykadBackIsValid === false) {
          const baseUrl = "/sme/application/verification-complete";
          const searchParams = {
            status: "failed",
            desc: "mykadBackIsValid:false",
          };
          const params = new URLSearchParams(searchParams).toString();
          return navigate(`${baseUrl}?${params}&language=${language}`, {
            replace: true,
          });
        }

        if (responseData[0].mykadVsFaceIsMatch === false) {
          const baseUrl = "/sme/application/verification-complete";
          const searchParams = {
            status: "failed",
            desc: "mykadVsFaceIsMatch:false",
          };
          const params = new URLSearchParams(searchParams).toString();
          return navigate(`${baseUrl}?${params}`, { replace: true });
        }

        if (responseData[0].faceIsMatch === false) {
          const baseUrl = "/sme/application/verification-complete";
          const searchParams = {
            status: "failed",
            desc: "faceIsMatch:false",
          };
          const params = new URLSearchParams(searchParams).toString();
          return navigate(`${baseUrl}?${params}`, { replace: true });
        }

        const baseUrl = "/sme/application/verification-complete";
        const searchParams = {
          status: "failed",
          desc: "Others:false",
        };
        const params = new URLSearchParams(searchParams).toString();
        return navigate(`${baseUrl}?${params}`, { replace: true });
      })
      .finally(() => setLoading(false));
  };

  const checkResultByApplicationId = async () => {
    setLoading(true);
    await dispatch(checkEkycResultByApplicationIdThunk({ applicationId }))
      .unwrap()
      .then((res) => {
        if (res.resultExist === true) {
          onCheckEkycResult();
        } else {
          const baseUrl = "/sme/application/verification-complete";
          const searchParams = {
            status: "failed",
            desc: "error:ekyc-failed",
          };
          const params = new URLSearchParams(searchParams).toString();
          return navigate(`${baseUrl}?${params}`, { replace: true });
        }
      })
      .catch((error) => {
        message.error("Error: " + JSON.stringify(error));
      })
      .finally(() => setLoading(false));
  };

  // useEffect(() => {
  //   if (
  //     applicationDetails?.data?.applicationStatus !==
  //     ApplicationStatusEnum.DRAFT
  //   ) {
  //     return navigate(`/home`);
  //   }
  // }, [applicationDetails?.data?.applicationStatus]);

  // useEffect(() => {
  //   const initProcess = async () => {
  //     setLoading(true);
  //     await dispatch(
  //       checkEkycResultByApplicationIdThunk({
  //         applicationId,
  //       }),
  //     )
  //       .unwrap()
  //       .then((res) => {
  //         if (res.resultExist === true) {
  //           onCheckEkycResult();
  //         }
  //       })
  //       .catch((error) => {
  //         message.error("Error: " + JSON.stringify(error));
  //       })
  //       .finally(() => setLoading(false));
  //   };
  //   initProcess();
  // }, []);

  return (
    <div className="web-application-layout-content-body">
      {isMobile ? (
        <div className="flex flex-col justify-center">
          <div className="flex justify-center">
            <img
              src={idCardIcon}
              className="max-h-20 w-auto my-4"
              alt="ID Card"
            />
          </div>
          <h2 className="text-center font-bold">
            {t("identityVerification:Start_Verification")}
          </h2>
          <p className="my-4 text-center">
            {t("identityVerification:processDesc1")}{" "}
            {t("identityVerification:processDesc2")}
          </p>
          <br />
          <EkycCheckList key="mobile-ekyc-checklist" />
          <a href={url} className="mt-7">
            <button type="submit" className="primary-button">
              {t("identityVerification:Start_Now")}
            </button>
          </a>
          <Button
            onClick={() => navigate("/sme/application/verification-complete")}
          >
            Goto complete screen
          </Button>
        </div>
      ) : (
        <div className="mt-8">
          <p className="text-center mb-8">
            {`${t("identityVerification:scanQRInstr1")} ${t(
              "identityVerification:scanQRInstr2",
            )}`}
          </p>
          {/*<p className="font-bold text-center mt-4">{qrCodeUrl}</p>*/}
          <EkycCheckList key="desktop-ekyc-checklist" />

          <div className="flex justify-center mt-8 mb-14">
            <QRCodeSVG
              value={qrCodeUrl}
              size={200}
              className="border-2 border-solid border-red-600 p-2 rounded-lg"
            />
          </div>

          <button
            className="primary-button mt-8"
            onClick={checkResultByApplicationId}
          >
            {loading && (
              <LoadingOutlined style={{ fontSize: 28, marginRight: 8 }} />
            )}
            {t("identityVerification:completeButtonLabel")}
          </button>
          <Button
            onClick={() => {
              dispatch(
                patchSmeApplicationToPendingThunk({
                  applicationId: applicationDetails?.data?.id,
                }),
              );
              navigate("/sme/application/verification-complete");
            }}
          >
            Goto complete screen
          </Button>
        </div>
      )}
    </div>
  );
};
