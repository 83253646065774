import groupBy from "lodash.groupby";

import { createSlice } from "@reduxjs/toolkit";

import { ApplicationStepStatusEnum } from "../../enums/applicationStepStatus";
import {
  createApplicationDraftThunk,
  getApplicationStepStatusThunk,
  getApplicationDetailsThunk,
  updateApplicationThunk,
} from "../../services/applicationService/applicationThunk";
import { getFilesByEntityIdThunk } from "../../services/fileService/fileThunk";

import type { PayloadAction } from "@reduxjs/toolkit";
import { getSmeApplicationDetailsThunk } from "../../services/smeApplicationService/smeApplicationThunk";
import { ORGANIZATION_TYPE } from "../../enums/partnerType";
import { Attestation } from "../agreement/agreementSlice";
import { SmeAgreement } from "../smeAgreement/smeAgreementSlice";

export type PartnerDetailsType = {
  username: string;
  organizationType: ORGANIZATION_TYPE;
};

export type CreateSmeApplicationDraftPayload = {
  id?: string;
  applicationNo?: string;
  redOneUsername: string;
  status?: string;
  loanReason: string;
  appliedLoanInterestRate: number;
  appliedLoanTenure: number;
  appliedLoanAmount: number;
  partnerType: string;
  promoCode: string | null;
  smeIndividualApplicationId: string | null;
  smeIndividualApplication?: SmeIndividualApplication;
  createdAt: string;
};

export type SmeIndividualApplication = {
  personalBankName: string | null;
  personalBankAccountName: string | null;
  personalBankAccountNo: string | null;
  name: string | null;
  nric: string | null;
  icType: string | null;
  emailAddress: string | null;
  homeAddress1: string | null;
  homeAddress2: string | null;
  homeAddress3: string | null;
  homePostcode: string | null;
  homeCity: string | null;
  homeState: null;
  nricAddress1: string | null;
  nricAddress2: string | null;
  nricAddress3: string | null;
  nricPostcode: string | null;
  nricCity: string | null;
  nricState: string | null;
  applicantPhoneNo: string | null;
  applicantRace: string | null;
  applicantEmploymentType: string | null;
  emergencyContactPersonName: string | null;
  emergencyContactPersonPhoneNo: string | null;
  emergencyContactEmail: string | null;
  emergencyContactRelationship: string | null;
  createdAt: string;
  updatedAt: string;
};

export type SmeApplication = {
  id: string;
  createdAt: string;
  status: string;
  applicationNo: string;
  partnerType: string;
  redOneUsername: string;
  appliedLoanInterestRate: number;
  appliedLoanTenure: number;
  appliedLoanAmount: number;
  financeAmount: number;
  loanReason: string;
  caseFollowedUp: boolean;
  submissionDate?: string;
  processingFee: number;
  stampingFee: number;
  stampingDutyPercentage: number;
  smeIndividualApplicationId: string | null;
  smeIndividualApplication?: SmeIndividualApplication;
  smeAgreementId?: string;
  smeAgreement?: SmeAgreement;
};

export type UploadedFile = {
  id: string;
  entityId: string;
  filename: string;
  originalName: string;
  mimeType: string;
  fileTypeId: string;
  fileType: {
    id: string;
    name: string;
    description?: string;
    fileSizeLimit?: number;
    createdAt?: string;
    updatedAt?: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type ApplicationStepStatus = {
  id: string;
  name: ApplicationStepStatusEnum;
  status: boolean;
  applicationId: string;
  createdAt: string;
  updatedAt: string;
};

export type ApplicationState = {
  partnerDetails: PartnerDetailsType;
  userFilledReferralCode?: boolean;
  cancelledApplication?: boolean;
  applicationStepStatus: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: ApplicationStepStatus[];
  };
  // application page form data
  updateSmeApplicationDraftFormData: SmeApplication;
  applicationDetails: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: SmeApplication;
  };
  // uploaded document list in document upload page
  // & application summary page
  applicationFiles: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    unGroupData: UploadedFile[];
    data: {
      OTHERS?: UploadedFile[];
      PERSONAL_BANK_ACCOUNT?: UploadedFile[];
      HOUSE_UTILITY_BILL?: UploadedFile[];
      BANK_STATEMENT?: UploadedFile[];
      BUSINESS_UTILITY_BILL?: UploadedFile[];
      SSM?: UploadedFile[];
      PARTNERSHIP_AUTHORIZATION_LETTER?: UploadedFile[];
      COMPANY_BANK_ACCOUNT?: UploadedFile[];
      BOARD_RESOLUTION?: UploadedFile[];
      FORM_2_3_8_11_SARAWAK?: UploadedFile[];
      COMPANY_9_13_24_44_49_SECTION_14_15_51_58_78?: UploadedFile[];
      LATEST_AUDITED_FINANCIAL_REPORT?: UploadedFile[];
    };
  };
};

const initialState: ApplicationState = {
  partnerDetails: {} as PartnerDetailsType,
  userFilledReferralCode: false,
  cancelledApplication: false,
  updateSmeApplicationDraftFormData: {
    id: "",
    createdAt: "",
    redOneUsername: "",
    status: "",
    applicationNo: "",
    loanReason: "test",
    partnerType: "",
    appliedLoanInterestRate: 0,
    appliedLoanTenure: 0,
    financeAmount: 0,
    appliedLoanAmount: 0,
    submissionDate: "",
    smeIndividualApplicationId: null,
    processingFee: 0,
    stampingFee: 0,
    stampingDutyPercentage: 0,
    caseFollowedUp: false,
    smeIndividualApplication: {} as SmeIndividualApplication,
    smeAgreement: {} as SmeAgreement,
  },
  applicationDetails: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      id: "",
      createdAt: "",
      status: "",
      applicationNo: "",
      partnerType: "",
      redOneUsername: "",
      appliedLoanInterestRate: 0,
      appliedLoanTenure: 0,
      appliedLoanAmount: 0,
      financeAmount: 0,
      loanReason: "",
      caseFollowedUp: false,
      processingFee: 0,
      stampingFee: 0,
      stampingDutyPercentage: 0,
      smeIndividualApplicationId: null,
      smeIndividualApplication: {} as SmeIndividualApplication,
      smeAgreement: {} as SmeAgreement,
    },
  },
  applicationStepStatus: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
  applicationFiles: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    unGroupData: [],
    data: {
      OTHERS: [],
      PERSONAL_BANK_ACCOUNT: [],
      HOUSE_UTILITY_BILL: [],
      BANK_STATEMENT: [],
      BUSINESS_UTILITY_BILL: [],
      SSM: [],
      PARTNERSHIP_AUTHORIZATION_LETTER: [],
      COMPANY_BANK_ACCOUNT: [],
      BOARD_RESOLUTION: [],
      LATEST_AUDITED_FINANCIAL_REPORT: [],
    },
  },
};

export const smeApplicationSlice = createSlice({
  name: "sme/application",
  initialState,
  reducers: {
    setCancelApplication: () => ({
      ...initialState,
      cancelledApplication: true,
    }),
    setPartnerDetails: (state, action) => {
      state.partnerDetails = action.payload;
    },
    setSmeApplicationDraft: (state, action) => {
      state.updateSmeApplicationDraftFormData = action.payload;
    },
    setSmeApplicationDetails: (
      state,
      action: PayloadAction<SmeApplication>,
    ) => {
      state.applicationDetails.data = action.payload;
    },
    setUserFilledReferralCodeState: (state, action: PayloadAction<boolean>) => {
      state.userFilledReferralCode = action.payload;
    },
    smeApplicationStateReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSmeApplicationDetailsThunk.pending, (state) => {
        state.applicationDetails.isLoading = true;
      })
      .addCase(getSmeApplicationDetailsThunk.fulfilled, (state, action) => {
        state.applicationDetails.isLoading = false;
        state.applicationDetails.data = action.payload;
      })
      .addCase(getSmeApplicationDetailsThunk.rejected, (state, action) => {
        state.applicationDetails.isLoading = false;
        state.applicationDetails.isError = true;
        state.applicationDetails.errorMessage = action.payload;
      });

    builder
      .addCase(createApplicationDraftThunk.pending, (state) => {
        state.applicationDetails.isLoading = true;
      })
      .addCase(createApplicationDraftThunk.fulfilled, (state, action) => {
        state.applicationDetails.isLoading = false;
        state.applicationDetails.data = action.payload;
      })
      .addCase(createApplicationDraftThunk.rejected, (state, action) => {
        state.applicationDetails.isLoading = false;
        state.applicationDetails.isError = true;
        state.applicationDetails.errorMessage = action.payload;
      });

    builder
      .addCase(getApplicationStepStatusThunk.pending, (state) => {
        state.applicationStepStatus.isLoading = true;
      })
      .addCase(getApplicationStepStatusThunk.fulfilled, (state, action) => {
        state.applicationStepStatus.isLoading = false;
        state.applicationStepStatus.data = action.payload;
      })
      .addCase(getApplicationStepStatusThunk.rejected, (state, action) => {
        state.applicationStepStatus.isLoading = false;
        state.applicationStepStatus.isError = true;
        state.applicationStepStatus.errorMessage = action.payload;
      });

    builder
      .addCase(getFilesByEntityIdThunk.pending, (state) => {
        state.applicationFiles.isLoading = true;
      })
      .addCase(getFilesByEntityIdThunk.fulfilled, (state, action) => {
        state.applicationFiles.isLoading = false;
        state.applicationFiles.unGroupData = action.payload;
        state.applicationFiles.data = groupBy(
          action.payload,
          (file: UploadedFile) => file.fileType.name.toLowerCase(),
        );
      })
      .addCase(getFilesByEntityIdThunk.rejected, (state, action) => {
        state.applicationFiles.isLoading = false;
        state.applicationFiles.isError = true;
        state.applicationFiles.errorMessage = action.payload;
      });
  },
});

export const {
  setSmeApplicationDetails,
  setSmeApplicationDraft,
  setPartnerDetails,
} = smeApplicationSlice.actions;

export default smeApplicationSlice.reducer;
