import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { UploadFile } from "antd/es/upload/interface";

export type DocumentUploadState = {
  salarySlip: UploadFile[];
  bankStatement: UploadFile[];
  otherDocuments: UploadFile[];
};

const initialState: DocumentUploadState = {
  salarySlip: [],
  bankStatement: [],
  otherDocuments: [],
};

export const documentUploadSlice = createSlice({
  name: "documentUpload",
  initialState,
  reducers: {
    setSalarySlip: (state, action: PayloadAction<UploadFile[]>) => {
      state.salarySlip = action.payload;
    },
    setBankStatement: (state, action: PayloadAction<UploadFile[]>) => {
      state.bankStatement = action.payload;
    },
    setOtherDocuments: (state, action: PayloadAction<UploadFile[]>) => {
      state.otherDocuments = action.payload;
    },
  },
});

export const { setSalarySlip, setBankStatement, setOtherDocuments } =
  documentUploadSlice.actions;

export default documentUploadSlice.reducer;
