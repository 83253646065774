import "./index.less";

import { Button, Checkbox } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  LoadingOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";

import { AgreementStatusEnum } from "../../enums/agreementStepStatus";
import { LANGUAGE } from "../../features/config/configSlice";
import {
  getAgreementDetailsThunk,
  getAgreementStepStatusThunk,
} from "../../services/agreementService/agreementThunk";
import { finishVideoAttestationThunk } from "../../services/attestationService/attestationThunk";
import {
  getPreRecordedVideoBmThunk,
  getPreRecordedVideoFilesThunk,
  getPreRecordedVideoThunk,
} from "../../services/preRecordedVideoService/preRecordedVideoThunk";
import { AppDispatch, RootState } from "../../store/store";
import LoadingSpinner from "../LoadingSpinner";

export const SmeVideoAttestationContent = () => {
  const navigate = useNavigate();
  const videoRef = useRef<any>(null);
  const dispatch = useDispatch<AppDispatch>();

  const { t } = useTranslation(["common", "videoAttestation"]);

  const [videoLink, setVideoLink] = useState("");
  const [loadingFile, setLoadingFile] = useState(false);
  const [playingVideo, setPlayingVideo] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [checkedAcknowledge, setCheckedAcknowledge] = useState(false);

  const { language } = useSelector((state: RootState) => state.config);
  const { agreementDetails } = useSelector(
    (state: RootState) => state.agreement,
  );

  const onFinish = async () => {
    setUpdateLoading(true);
    dispatch(
      finishVideoAttestationThunk({
        agreementId: agreementDetails?.data?.id,
        companyId: process.env.REACT_APP_COMPANY_ID,
      }),
    )
      .unwrap()
      .then(() => {
        // update latest agreement details
        dispatch(
          getAgreementDetailsThunk({
            agreementId: agreementDetails?.data?.id,
          }),
        );

        // update latest agreement step status
        dispatch(
          getAgreementStepStatusThunk({
            agreementId: agreementDetails?.data?.id,
          }),
        );
        navigate(`/direct-debit`);
        // navigate(`/loan-status/loan-agreement`);
      })
      .finally(() => {
        setUpdateLoading(false);
      });
  };

  const playPause = () => {
    if (videoRef?.current?.paused) {
      setPlayingVideo(true);
      videoRef?.current?.play();
    } else {
      videoRef?.current?.pause();
      setPlayingVideo(false);
    }
  };

  useEffect(() => {
    setLoadingFile(true);

    if (language === LANGUAGE.MS) {
      // melayu
      dispatch(getPreRecordedVideoBmThunk())
        .unwrap()
        .then((res) => {
          if (res?.length > 0) {
            dispatch(getPreRecordedVideoFilesThunk({ entityId: res[0].id }))
              .unwrap()
              .then((resp) => {
                if (resp?.length > 0) {
                  setVideoLink(
                    `${process.env.REACT_APP_FILE_UPLOAD_URL}/redCash/api/v1.0/video/file/${resp[0].id}`,
                  );

                  setLoadingFile(false);
                  // dispatch(getMasterSetupVideoFileByIdThunk({ fileId: res[0].id }))
                  //   .unwrap()
                  //   .then((res) => setVideoFile(res))
                  //   .catch((err) => setVideoFile(null))
                  //   .finally(() => setLoadingFile(false));
                } else {
                  setVideoLink("");
                  setLoadingFile(false);
                }
              });
          } else {
            setVideoLink("");
            setLoadingFile(false);
          }
        })
        .catch((err) => {
          setVideoLink("");
          setLoadingFile(false);
        });
    } else {
      // english
      dispatch(getPreRecordedVideoThunk())
        .unwrap()
        .then((res) => {
          if (res?.length > 0) {
            dispatch(getPreRecordedVideoFilesThunk({ entityId: res[0].id }))
              .unwrap()
              .then((resp) => {
                if (resp?.length > 0) {
                  setVideoLink(
                    `${process.env.REACT_APP_FILE_UPLOAD_URL}/redCash/api/v1.0/video/file/${resp[0].id}`,
                  );

                  setLoadingFile(false);
                  // dispatch(getMasterSetupVideoFileByIdThunk({ fileId: res[0].id }))
                  //   .unwrap()
                  //   .then((res) => setVideoFile(res))
                  //   .catch((err) => setVideoFile(null))
                  //   .finally(() => setLoadingFile(false));
                } else {
                  setVideoLink("");
                  setLoadingFile(false);
                }
              });
          } else {
            setVideoLink("");
            setLoadingFile(false);
          }
        })
        .catch((err) => {
          setVideoLink("");
          setLoadingFile(false);
        });
    }
  }, [dispatch, language]);

  // useEffect(() => {
  //   if (
  //     agreementDetails?.data?.agreementStatus !== AgreementStatusEnum.PENDING &&
  //     agreementDetails?.data?.agreementStatus !==
  //       AgreementStatusEnum.ATTESTATIONOPTION
  //   ) {
  //     return navigate(`/home`);
  //   }
  // }, [agreementDetails?.data?.agreementStatus, navigate]);

  return (
    <div className="text-center">
      <p>{t("videoAttestation:instruction")}</p>
      <div className="vimeo-video-viewport flex-center">
        {loadingFile ? (
          <LoadingSpinner />
        ) : videoLink !== "" ? (
          <video
            id="preview-vid"
            preload="auto"
            ref={videoRef}
            width="100%"
            height="100%"
            controls={false}
            playsInline
            disablePictureInPicture
            onEnded={() => {
              setPlayingVideo(false);
              setDisableButton(false);
            }}
          >
            <source src={videoLink} type="video/mp4" />
          </video>
        ) : (
          <div className="py-24 flex justify-center">
            <strong className="text-white">
              {t("videoAttestation:videoNotFoundErrMsg")}
            </strong>
          </div>
        )}
      </div>
      <br />
      <Button
        type="default"
        className="w-32 mb-8"
        shape="round"
        icon={!playingVideo ? <PlayCircleOutlined /> : <PauseCircleOutlined />}
        size="large"
        onClick={playPause}
        danger
      >
        {!playingVideo
          ? t("videoAttestation:play")
          : t("videoAttestation:pause")}
      </Button>
      <p>{t("videoAttestation:changeToLiveText")}</p>
      <button
        className="text-button redirect-to-live-button"
        onClick={() => navigate(`/loan-status/attestation-option/live-call`)}
      >
        {t("videoAttestation:Live_Attestation")}
      </button>
      <div className="h-16" />
      <Checkbox
        checked={checkedAcknowledge}
        onChange={(e) => setCheckedAcknowledge(!checkedAcknowledge)}
      >
        {t("videoAttestation:checkedAcknowledgeText")}
      </Checkbox>

      <button
        type="submit"
        className="primary-button video-attestation-complete-button"
        onClick={onFinish}
        disabled={disableButton || !checkedAcknowledge || updateLoading}
      >
        {updateLoading && (
          <LoadingOutlined style={{ fontSize: 28, marginRight: 8 }} />
        )}
        {t("videoAttestation:submitBtnLabel")}
      </button>
    </div>
  );
};
