import { PageHeader } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { AttestationOptionContent } from "../../../components/AttestationOptionContent";
import { WebLoanStatusLayout } from "../../../layouts/web/WebLoanStatusLayout";
import { WebSmeLoanStatusLayout } from "../../../layouts/web/WebSmeLoanStatusLayout";
import { SmeAttestationOptionContent } from "../../../components/SmeAttestationOptionContent";

const WebSmeAttestationOptionPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["common", "attestationOption"]);

  return (
    <WebSmeLoanStatusLayout step={2}>
      <div className="max-w-xl w-full px-4 mx-auto">
        <PageHeader
          style={{ padding: 12 }}
          title={<strong>{t("attestationOption:pageTitle")}</strong>}
          onBack={() => navigate(-1)}
        />
        <SmeAttestationOptionContent />
      </div>
    </WebSmeLoanStatusLayout>
  );
};

export default WebSmeAttestationOptionPage;
