import "./MobileSmeHomePage.less";

import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ApplicationSteps } from "../../../components/ApplicationSteps";
import { FeaturesAndBenefits } from "../../../components/FeaturesAndBenefits";
import { LoanApplicationStatusCard } from "../../../components/LoanApplicationStatusCard";
import { LoanStatusCard } from "../../../components/LoanStatusCard";
import MobileFooter from "../../../components/MobileFooter/MobileFooter";
import { RepaymentDetails } from "../../../components/RepaymentDetails";
import RequirementPanel from "../../../components/RequirementPanel/RequirementPanel";
import { ApplicationStatusEnum } from "../../../enums/applicationStepStatus";
import { agreementSignatureStateReset } from "../../../features/agreement/agreementSlice";
import {
  setCurrentUser,
  setHasLoggedUser,
} from "../../../features/user/userSlice";
import { useInit } from "../../../hooks/useInit";
import { MobileLayoutWithMenu } from "../../../layouts/mobile/MobileLayoutWithMenu";
import { getAgreementDetailsThunk } from "../../../services/agreementService/agreementThunk";
import { getApplicationDetailsThunk } from "../../../services/applicationService/applicationThunk";
import { AppDispatch, RootState } from "../../../store/store";
import { onCalculateTotalDueAmount } from "../../../utils/calculation";
import { SmeLoanApplicationStatusCard } from "../../../components/SmeLoanApplicationStatusCard";
import { jwtDecode } from "jwt-decode";
import {
  setPartnerDetails,
  setSmeApplicationDetails,
} from "../../../features/smeApplication/smeApplicationSlice";

const MobileSmeHomePage = () => {
  const [rerenderAgreementStepComponent, setRerenderAgreementStepComponent] =
    useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { applicationDetails } = useSelector(
    (state: RootState) => state.smeApplication,
  );

  const { currentUser } = useSelector((state: RootState) => state.user);
  const { registrationFormData } = useSelector(
    (state: RootState) => state.user,
  );

  const { accountEnquiry } = useSelector(
    (state: RootState) => state.collection,
  );
  const { accountDetails } = accountEnquiry.data;
  const totalDueAmount = onCalculateTotalDueAmount(accountDetails);

  const [showRepaymentDetail, setShowRepaymentDetail] = useState<any>();

  const { t } = useTranslation(["common", "emailVerification"]);

  const fetchApplicationDetails = () => {
    dispatch(
      getApplicationDetailsThunk({
        applicationId: applicationDetails?.data?.id,
      }),
    );
  };

  const fetchAgreementDetails = () => {
    dispatch(
      getAgreementDetailsThunk({
        agreementId: applicationDetails?.data?.smeAgreementId,
      }),
    );
  };

  const resetAgreementSignatureState = () => {
    dispatch(agreementSignatureStateReset());
  };

  const navigateToTestingEligibility = () => navigate("/testing-eligibility");

  const navigateToRegistration = () => navigate("/registration");

  // const initialCallbackState = {
  //   fetchApplicationDetails,
  //   fetchAgreementDetails,
  //   resetAgreementSignatureState,
  //   navigateToTestingEligibility,
  //   navigateToRegistration,
  // };
  // const [callbackState] = useState(initialCallbackState);

  // useInit(async () => {
  //   if (registrationFormData?.currentlyEditing) {
  //     callbackState.navigateToRegistration();
  //   } else if (!cancelledApplication && applicationDetails?.data?.id == null) {
  //     callbackState.navigateToTestingEligibility();
  //   } else {
  //     callbackState.fetchApplicationDetails();
  //     if (applicationDetails?.data?.agreementId) {
  //       callbackState.fetchAgreementDetails();
  //     }
  //   }
  // });

  // useEffect(() => {
  //   if (cancelledApplication) {
  //     return;
  //   }
  //
  //   if (registrationFormData?.currentlyEditing) {
  //     callbackState.navigateToRegistration();
  //   } else if (applicationDetails?.data?.id == null && !cancelledApplication) {
  //     callbackState.navigateToTestingEligibility();
  //   }
  //
  //   //  if the application applied by user is rejected before,
  //   //  the user can only apply after allowToApplyNewLoanAfterDateTime
  //   const allowToApplyNewLoanAfterDateTime =
  //     currentUser?.data?.allowToApplyNewLoanAfterDateTime;
  //
  //   const isAfterAllowToApplyNewLoanAfterDateTime =
  //     allowToApplyNewLoanAfterDateTime != null &&
  //     dayjs().isAfter(dayjs(allowToApplyNewLoanAfterDateTime));
  //
  //   if (
  //     applicationDetails?.data?.applicationStatus ===
  //       ApplicationStatusEnum.REJECTED &&
  //     isAfterAllowToApplyNewLoanAfterDateTime
  //   ) {
  //     callbackState.navigateToTestingEligibility();
  //   }
  // }, [
  //   applicationDetails,
  //   applicationDetails?.data?.agreementId,
  //   applicationDetails?.data?.id,
  //   callbackState,
  //   cancelledApplication,
  //   currentUser?.data?.allowToApplyNewLoanAfterDateTime,
  // ]);
  //
  // useEffect(() => {
  //   if (applicationDetails?.data?.id) {
  //     callbackState.fetchApplicationDetails();
  //   }
  //
  //   if (applicationDetails?.data?.agreementId) {
  //     callbackState.fetchAgreementDetails();
  //   } else {
  //     callbackState.resetAgreementSignatureState();
  //   }
  // }, [
  //   applicationDetails?.data?.agreementId,
  //   applicationDetails?.data?.id,
  //   callbackState,
  // ]);
  //
  // useEffect(() => {
  //   if (applicationDetails?.data?.user) {
  //     dispatch(setCurrentUser(applicationDetails?.data?.user));
  //   }
  // }, [applicationDetails?.data?.user]);
  //
  // useEffect(() => {
  //   setRerenderAgreementStepComponent(!rerenderAgreementStepComponent);
  // }, []);

  // useEffect(() => {
  //   const showRepaymentDetail =
  //     applicationDetails?.data?.agreement?.disbursementPostedDate &&
  //     Math.abs(
  //       dayjs(applicationDetails?.data?.agreement?.disbursementPostedDate).diff(
  //         dayjs(),
  //         "day",
  //       ),
  //     ) >=
  //       Number(process.env.REACT_APP_REPAYMENT_DETAILS_AFTER_DISBURSED_DAY) &&
  //     accountEnquiry.data.accountDetails?.status !== "CLOSED";
  //
  //   setShowRepaymentDetail(showRepaymentDetail);
  // }, [applicationDetails?.data]);

  useEffect(() => {
    // get token from URL params
    const urlParams = new URLSearchParams(window.location.search);
    const accessToken = urlParams.get("accessToken");
    const refreshToken = urlParams.get("refreshToken");
    const ssoAccessToken = urlParams.get("ssoAccessToken");

    if (accessToken && refreshToken && ssoAccessToken) {
      localStorage.setItem("accessToken", accessToken ?? "");
      localStorage.setItem("refreshToken", refreshToken ?? "");
      localStorage.setItem("ssoAccessToken", ssoAccessToken ?? "");

      // set application details to state
      const decoded: any = jwtDecode(accessToken);

      dispatch(setHasLoggedUser(true));

      dispatch(
        setPartnerDetails({
          partnerType: decoded?.partnerType,
          username: decoded?.username,
        }),
      );

      if (decoded?.latestApplicationDetails) {
        dispatch(
          setSmeApplicationDetails({
            ...decoded?.latestApplicationDetails,
            redOneUsername: decoded?.username,
          }),
        );

        navigate("/sme/home");
      } else {
        dispatch(
          setSmeApplicationDetails({
            ...applicationDetails.data,
            redOneUsername: decoded?.username,
          }),
        );

        navigate("/sme/testing-eligibility");
      }
    }
  }, []);

  return (
    <MobileLayoutWithMenu>
      <div className="mobile-home-top-section">
        <div className="mobile-home-top-section-bg" />
        <div className="mobile-home-top-section-content">
          <h1 className="mobile-welcome-message break-words">
            {`${t("home:Welcome_back")}, ${currentUser?.data?.firstName ?? ""}`}
          </h1>
          {showRepaymentDetail ||
          applicationDetails.data.status ===
            ApplicationStatusEnum.RESCHEDULED_SIGNED ? (
            <RepaymentDetails />
          ) : (
            <SmeLoanApplicationStatusCard />
          )}
        </div>
      </div>
      <div className="mobile-home-content">
        {!applicationDetails?.isLoading &&
        applicationDetails?.data?.status === ApplicationStatusEnum.APPROVED ? (
          <>
            {(rerenderAgreementStepComponent ||
              !rerenderAgreementStepComponent) && <LoanStatusCard />}
          </>
        ) : (
          <>
            <ApplicationSteps />
            <FeaturesAndBenefits />
            <RequirementPanel />
          </>
        )}
      </div>
      <MobileFooter />
    </MobileLayoutWithMenu>
  );
};

export default MobileSmeHomePage;
