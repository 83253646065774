import { NavBar } from "antd-mobile";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { UpdateProfileForm } from "../../../components/UpdateProfileForm";
import { UpdateEInvoiceTinForm } from "../../../components/UpdateEInvoiceTinForm";

const MobileUpdateProfilePage = () => {
  const navigate = useNavigate();

  const { t } = useTranslation(["common", "updateEInvoiceTin"]);

  return (
    <>
      <NavBar onBack={() => navigate(-1)}>
        <strong>{t("updateEInvoiceTin:pageTitle")}</strong>
      </NavBar>
      <UpdateEInvoiceTinForm />
    </>
  );
};

export default MobileUpdateProfilePage;
