import { createAsyncThunk } from "@reduxjs/toolkit";
import { restructureErrorMessage } from "../../helpers/errorMessage";
import smeAgreementService from "./smeAgreementService";

export const getSmeAgreementByIdThunk = createAsyncThunk(
  "sme/agreement",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeAgreementService.getSmeAgreementById(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
