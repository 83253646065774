import { PageHeader } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { LiveCallAttestationContent } from "../../../components/LiveCallAttestationContent";
import { WebLoanStatusLayout } from "../../../layouts/web/WebLoanStatusLayout";
import { SmeLiveCallAttestationContent } from "../../../components/SmeLiveCallAttestationContent";
import { WebSmeLoanStatusLayout } from "../../../layouts/web/WebSmeLoanStatusLayout";

const WebSmeLiveCallAttestationPage = () => {
  const navigate = useNavigate();

  const { t } = useTranslation(["common", "liveCallAttestation"]);

  return (
    <WebSmeLoanStatusLayout step={2}>
      <div className="max-w-xl w-full mx-16 h-full px-4">
        <PageHeader
          style={{ padding: 12 }}
          onBack={() => navigate(-1)}
          title={<strong>{t("liveCallAttestation:pageTitle")}</strong>}
        />
        <SmeLiveCallAttestationContent />
      </div>
    </WebSmeLoanStatusLayout>
  );
};

export default WebSmeLiveCallAttestationPage;
