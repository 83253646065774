import "./index.less";

import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  List,
  Row,
  Space,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createBreakpoint } from "react-use";

import { CheckCircleTwoTone, UploadOutlined } from "@ant-design/icons";

import { successTextGreen } from "../../assets/style/variables";
import { setApplicationDetails } from "../../features/application/applicationSlice";
import { previewNewPage } from "../../helpers/downloadLink";
import { numberWithCommas } from "../../helpers/number";
import { getApplicationDetailsThunk } from "../../services/applicationService/applicationThunk";
import {
  getFileByIdThunk,
  getFilesByEntityIdThunk,
} from "../../services/fileService/fileThunk";
import { AppDispatch, RootState } from "../../store/store";
import {
  onCalculateEstimatedMonthlyAmount,
  onCalculateTotalPremium,
} from "../../utils/calculation";
import { monthYearFormat } from "../../utils/datetime.util";
import { makeWordBold, styleBrandLabel } from "../../utils/stringFormatter";
import ApplicationFileList from "../ApplicationFileList";
import LoadingSpinner from "../LoadingSpinner";

import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { LANGUAGE } from "../../features/config/configSlice";
import { set } from "js-cookie";
import { getLoanInsuranceForUserThunk } from "../../services/loanInsuranceService/loanInsuranceThunk";
import { setSmeApplicationDraft } from "../../features/smeApplication/smeApplicationSlice";

const useBreakpoint = createBreakpoint();
const cardHeaderStyle = { padding: "0px 16px", fontWeight: 500 };
const cardBodyStyle = { padding: "8px 16px" };

export const SmeApplicationSummaryContent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === "tablet";

  const [acknowledged, setAcknowledged] = useState(false);

  const { language } = useSelector((state: RootState) => state.config);
  const { userLoanInsuranceDetails } = useSelector(
    (state: RootState) => state.user,
  );

  const { applicationDetails, applicationFiles } = useSelector(
    (state: RootState) => state.smeApplication,
  );

  const { relationshipState } = useSelector(
    (state: RootState) => state.dropdownParameter,
  );

  const { t } = useTranslation(["common", "applicationSummary"]);

  const onAcknowledgedCheck = (e: CheckboxChangeEvent) => {
    setAcknowledged(e.target.checked);
  };

  const onCalculateGrossPremium = (loanTenure: number) => {
    switch (true) {
      case loanTenure <= 24 && loanTenure > 18:
        return numberWithCommas(24.0);
      case loanTenure <= 18 && loanTenure > 12:
        return numberWithCommas(18.0);
      case loanTenure <= 12 && loanTenure > 6:
        return numberWithCommas(12.0);
      case loanTenure <= 6:
        return numberWithCommas(6.0);
      default:
        return 0;
    }
  };

  const onCalculateLoanServiceTax = (loanTenure: number) => {
    switch (true) {
      case loanTenure <= 24 && loanTenure > 18:
        return numberWithCommas(24.0 * 0.08);
      case loanTenure <= 18 && loanTenure > 12:
        return numberWithCommas(18.0 * 0.08);
      case loanTenure <= 12 && loanTenure > 6:
        return numberWithCommas(12.0 * 0.08);
      case loanTenure <= 6:
        return numberWithCommas(6.0 * 0.08);
      default:
        return 0;
    }
  };

  const onFileListItemClick = (fileId: string) =>
    dispatch(getFileByIdThunk({ fileId: fileId }))
      .unwrap()
      .then((res) =>
        previewNewPage({
          ...res,
          fileId,
          defaultUrl:
            process.env.REACT_APP_BE_SETUP_URL +
            "/redCash/api/v1.0/previewFile",
        }),
      );

  const onFinish = async () => {
    // TODO: set checkbox
    dispatch(
      setSmeApplicationDraft({
        ...applicationDetails?.data,
      }),
    );

    navigate(`/sme/application/identity-verification`);
  };

  // useEffect(() => {
  //   dispatch(
  //     getApplicationDetailsThunk({
  //       applicationId: applicationDetails?.data?.id,
  //     }),
  //   );
  //   dispatch(
  //     getFilesByEntityIdThunk({
  //       entityId: applicationDetails?.data?.id,
  //     }),
  //   );
  //   dispatch(getLoanInsuranceForUserThunk(applicationDetails.data.loanTenure))
  //     .unwrap()
  //     .then((res) => {
  //       console.log(res);
  //     });
  // }, [applicationDetails?.data?.id, dispatch]);

  return applicationDetails?.data?.id ? (
    <>
      {/*TODO: fill all the missing fields in summary*/}
      <div className="web-application-layout-content-body">
        {applicationDetails.isLoading ? (
          <div className="h-[90vh] flex justify-center items-center">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <Card
              className="mobile-summary-card"
              title={styleBrandLabel(
                t("applicationSummary:personalLoanCardTitle"),
              )}
              headStyle={cardHeaderStyle}
              bodyStyle={cardBodyStyle}
            >
              <List>
                <List.Item>
                  <span>{t("applicationSummary:Total_Applied_Loan")}</span>
                  <span>
                    RM{" "}
                    {numberWithCommas(
                      applicationDetails?.data?.appliedLoanAmount,
                    )}
                  </span>
                </List.Item>
                <List.Item>
                  <span>{t("applicationSummary:Required_Loan_Tenure")}</span>
                  <span>
                    {`${applicationDetails?.data?.appliedLoanTenure} ${t(
                      "applicationSummary:months",
                    )}`}
                  </span>
                </List.Item>
                <List.Item>
                  <span>{t("applicationSummary:Loan_Interest_Rate")}</span>
                  <span>{`${
                    applicationDetails?.data?.appliedLoanInterestRate
                  }% ${t("applicationSummary:per_annum")}`}</span>
                </List.Item>
                <List.Item>
                  <strong>
                    {t("applicationSummary:Estimated_Monthly_Instalment")}
                  </strong>
                  <div className="est-monthly-info">
                    RM{" "}
                    {numberWithCommas(
                      onCalculateEstimatedMonthlyAmount(
                        applicationDetails.data.appliedLoanInterestRate,
                        applicationDetails.data.appliedLoanInterestRate ?? 0,
                        applicationDetails.data.appliedLoanTenure ?? 0,
                      ),
                    )}
                  </div>
                </List.Item>
              </List>
            </Card>

            <Card
              className="mobile-summary-card"
              title={t("applicationSummary:applicationDetailsCardTitle")}
              headStyle={cardHeaderStyle}
              bodyStyle={cardBodyStyle}
            >
              {/*<List>*/}
              {/*  <List.Item>*/}
              {/*    <span>{t("applicationSummary:Full_Name")}</span>*/}
              {/*    <span>{applicationDetails?.data?.name}</span>*/}
              {/*  </List.Item>*/}
              {/*  <List.Item>*/}
              {/*    <span>{t("applicationSummary:Identity_Card")}</span>*/}
              {/*    <span>{applicationDetails?.data?.nric}</span>*/}
              {/*  </List.Item>*/}
              {/*  <List.Item>*/}
              {/*    <span>{t("applicationSummary:Reason_for_Loan")}</span>*/}
              {/*    /!*TODO: map loan reason here, en and bm*!/*/}
              {/*    <span>*/}
              {/*      {applicationDetails?.data?.loanReason}*/}
              {/*    </span>*/}
              {/*  </List.Item>*/}
              {/*  <List.Item>*/}
              {/*    <span>{t("applicationSummary:Email_Address")}</span>*/}
              {/*    <span>*/}
              {/*      {applicationDetails?.data?.emailAddress}*/}
              {/*    </span>*/}
              {/*  </List.Item>*/}
              {/*  <List.Item>*/}
              {/*    <span>{t("applicationSummary:Bank_Name")}</span>*/}
              {/*    <span>*/}
              {/*      {*/}
              {/*        applicationDetails?.data*/}
              {/*          ?.personalBankAccountName*/}
              {/*      }*/}
              {/*    </span>*/}
              {/*  </List.Item>*/}
              {/*  <List.Item>*/}
              {/*    <span>*/}
              {/*      {t("applicationSummary:Personal_Bank_Account_No")}*/}
              {/*    </span>*/}
              {/*    <span>*/}
              {/*      {*/}
              {/*        applicationDetails?.data*/}
              {/*          ?.personalBankAccountNo*/}
              {/*      }*/}
              {/*    </span>*/}
              {/*  </List.Item>*/}
              {/*  <List.Item>*/}
              {/*    <span>*/}
              {/*      {t("applicationSummary:Emergency_Contact_Person_Name")}*/}
              {/*    </span>*/}
              {/*    <span>*/}
              {/*      {*/}
              {/*        applicationDetails?.data*/}
              {/*          ?.emergencyContactPersonName*/}
              {/*      }*/}
              {/*    </span>*/}
              {/*  </List.Item>*/}
              {/*  <List.Item>*/}
              {/*    <span>*/}
              {/*      {t("applicationSummary:Emergency_Contact_Number")}*/}
              {/*    </span>*/}
              {/*    <span>*/}
              {/*      {*/}
              {/*        smeIndividualApplicationDetails?.data*/}
              {/*          ?.emergencyContactPersonPhoneNo*/}
              {/*      }*/}
              {/*    </span>*/}
              {/*  </List.Item>*/}
              {/*  <List.Item>*/}
              {/*    <span>{t("applicationSummary:Relationship")}</span>*/}
              {/*    /!*map relationship, with en and bm*!/*/}
              {/*    <span>*/}
              {/*      {smeIndividualApplicationDetails.data.emergencyContactRelationship}*/}
              {/*    </span>*/}
              {/*    /!*<span>*!/*/}
              {/*    /!*  {language === LANGUAGE.MS*!/*/}
              {/*    /!*    ? applicationDetails?.data?.emergencyContactRelationship*!/*/}
              {/*    /!*        ?.malayName*!/*/}
              {/*    /!*    : applicationDetails?.data?.emergencyContactRelationship*!/*/}
              {/*    /!*        ?.name}*!/*/}
              {/*    /!*</span>*!/*/}
              {/*  </List.Item>*/}
              {/*</List>*/}
            </Card>

            <Card
              className="mobile-summary-card"
              title={t("applicationSummary:Document_Upload")}
              headStyle={cardHeaderStyle}
              bodyStyle={cardBodyStyle}
            >
              {/*TODO: display file list based on partner type*/}
              <div className="uploaded-doc-section">
                <Space align="start" className="field-title">
                  <CheckCircleTwoTone twoToneColor={successTextGreen} />
                  <p>
                    {makeWordBold(
                      t("applicationSummary:salarySlipFileMsg"),
                      t("applicationSummary:three_months"),
                    )}
                  </p>
                </Space>
                <ApplicationFileList
                  dataSource={applicationFiles.data.SSM}
                  itemOnClick={onFileListItemClick}
                />
              </div>
              <Divider />
              <div className="uploaded-doc-section">
                <Space align="start" className="field-title">
                  <CheckCircleTwoTone twoToneColor={successTextGreen} />
                  <p>
                    {makeWordBold(
                      t("applicationSummary:bankStatementFileMsg"),
                      t("applicationSummary:three_months"),
                    )}
                  </p>
                </Space>
                <ApplicationFileList
                  dataSource={applicationFiles.data.BANK_STATEMENT}
                  itemOnClick={onFileListItemClick}
                />
              </div>
              <Divider />
              <div className="uploaded-doc-section">
                <Space align="start" className="field-title">
                  <CheckCircleTwoTone twoToneColor={successTextGreen} />
                  <p>{t("applicationSummary:otherDocMsg")}</p>
                </Space>
                <ApplicationFileList
                  dataSource={
                    applicationFiles.data
                      .COMPANY_9_13_24_44_49_SECTION_14_15_51_58_78
                  }
                  itemOnClick={onFileListItemClick}
                />
              </div>
              <button
                className="secondary-button file-upload-button"
                // TODO: redirect to upload page based on partner type
                onClick={() => navigate(`/application/document-upload`)}
              >
                <UploadOutlined /> {t("applicationSummary:Reupload_File")}
              </button>
            </Card>

            <div className={isMobile ? "mx-4 " : ""}>
              <div className="my-8">
                <Checkbox onChange={onAcknowledgedCheck}>
                  {t("applicationSummary:acknowledgedCheckConetnt")}
                </Checkbox>
              </div>
              <div className="my-4">
                <button
                  type="submit"
                  className="primary-button"
                  onClick={onFinish}
                  disabled={!acknowledged}
                >
                  {t("applicationSummary:submitBtnLabel")}
                </button>
              </div>
            </div>
            <br />
          </>
        )}
      </div>
    </>
  ) : (
    <Button onClick={() => navigate("/sme/application/identity-verification")}>
      goto ekyc verification page
    </Button>
    // <Alert
    //   message={
    //     <h2 className="m-0 text-center text-red-600">
    //       {t("applicationSummary:applicationNotFoundMsg")}
    //     </h2>
    //   }
    //   type="error"
    // />
  );
};
