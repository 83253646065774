import { VerificationCompleteContent } from "../../../components/VerificationCompleteContent";

import "./MobileSmeVerificationCompletePage.less";
import { SmeVerificationCompleteContent } from "../../../components/SmeVerificationCompleteContent";

const MobileSmeVerificationCompletePage = () => {
  return (
    <div className="">
      <SmeVerificationCompleteContent />
    </div>
  );
};

export default MobileSmeVerificationCompletePage;
