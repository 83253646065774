export enum ApplicationStepStatusEnum {
  APPLICATIONFORMCOMPLETED = "APPLICATIONFORMCOMPLETED",
  EMAILVERIFICATIONCOMPLETED = "EMAILVERIFICATIONCOMPLETED",
  DOCUMENTUPLOADCOMPLETED = "DOCUMENTUPLOADCOMPLETED",
  EKYCCOMPLETED = "EKYCCOMPLETED",
}

export enum ApplicationStatusEnum {
  DRAFT = "DRAFT",
  PENDING = "PENDING",
  SUBMITTED_FOR_APPROVAL = "SUBMITTED_FOR_APPROVAL",
  SUBMITTED_FOR_APPROVAL_FROM_PENDING_DISBURSEMENT = "SUBMITTED_FOR_APPROVAL_FROM_PENDING_DISBURSEMENT",
  REFERBACK = "REFERBACK",
  REFERBACK_FROM_PENDING_DISBURSEMENT = "REFERBACK_FROM_PENDING_DISBURSEMENT",
  FOLLOWED_UP = "FOLLOWED_UP",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  CANCELED = "CANCELED",
  RESCHEDULED_APPROVED = "RESCHEDULED_APPROVED",
  RESCHEDULED_SIGNED = "RESCHEDULED_SIGNED",
}
