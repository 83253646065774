export const phoneNumberValidator = (value: string) => {
  if (value) {
    if (value.indexOf("-") > -1) {
      return Promise.reject(new Error("Do not enter dashes"));
    }
    if (value.indexOf("6") === 0) {
      return Promise.reject(new Error("Do not include country code"));
    }
    if (value.indexOf("1") !== 0) {
      return Promise.reject(new Error("Should start from '1'"));
    }
  }
  return Promise.resolve();
};

export const ageValidator = (value: number, language?: string) => {
  if (value < 18) {
    return Promise.reject(
      new Error(
        language != null && (language === "MS" || language === "ms")
          ? "Pemohon harus berusia sekurang-kurangnya 18 tahun"
          : "Applicant must be at least 18 years old",
      ),
    );
  }
  if (value > 60) {
    return Promise.reject(
      new Error(
        language != null && (language === "MS" || language === "ms")
          ? "Pemohon tidak boleh melebihi 60 tahun."
          : "Applicant cannot be over 60 years old.",
      ),
    );
  }
  return Promise.resolve();
};

export const nricFirst6NumRegexPattern =
  /(([[0-9]{2})(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01]))/;

export const nricRegexPattern =
  /(([[0-9]{2})(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01]))-([0-9]{2})-([0-9]{4})/;

export const phoneRegexPattern = /^\+?[1-9][0-9]{7,14}$/;

export const numberRegexPattern = /^\d+$/;
