import { AxiosResponse } from "axios";

export const generateFileName = (response: AxiosResponse) => {
  if (response.headers["content-disposition"]) {
    const fileName = response.headers["content-disposition"]
      .split("filename=")[1]
      .split(";")[0]
      .replace(/"/g, "");
    response.data.name = decodeURIComponent(fileName);
  }
};
