import "./index.less";

import { Alert, Button, Divider, message, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { LoadingOutlined } from "@ant-design/icons";

import { ApplicationStatusEnum } from "../../enums/applicationStepStatus";
import { FileTypeEnum } from "../../enums/fileType";
import {
  patchApplicationStepErrorThunk,
  patchDocumentUploadCompletedStepThunk,
} from "../../services/applicationService/applicationThunk";
import { getFilesByEntityIdThunk } from "../../services/fileService/fileThunk";
import { AppDispatch, RootState } from "../../store/store";
import { makeWordBold } from "../../utils/stringFormatter";
import { SmeFileUploadField } from "./FileUploadField";
import { ApplicationStepStatusEnum } from "../../enums/applicationStepStatus";
import { ORGANIZATION_TYPE } from "../../enums/partnerType";
import { getRequiredFileByPartnerTypeThunk } from "../../services/smeFileService/smeFileThunk";

export const SmeDocumentUploadForm = () => {
  const [requiredFileList, setRequiredFileList] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [uploading, setUploading] = useState(false);
  const [salarySlipErrorMessage, setSalarySlipErrorMessage] = useState("");
  const [bankStatementErrorMessage, setBankStatementErrorMessage] =
    useState("");

  const { applicationDetails, applicationFiles, partnerDetails } = useSelector(
    (state: RootState) => state.smeApplication,
  );

  const { organizationType } = partnerDetails;

  const { t } = useTranslation(["common", "documentUpload"]);

  const refreshFileList = async () => {
    setBankStatementErrorMessage("");
    setSalarySlipErrorMessage("");
    dispatch(
      getFilesByEntityIdThunk({
        entityId: applicationDetails?.data?.id,
      }),
    );
  };

  const onFinish = async () => {
    setUploading(true);
    setSalarySlipErrorMessage("");
    setBankStatementErrorMessage("");
    await dispatch(
      getFilesByEntityIdThunk({
        entityId: applicationDetails?.data?.id,
      }),
    );

    // TODO: add file empty validation
    // if (
    //   applicationFiles.data.salaryslip == null ||
    //   applicationFiles.data.salaryslip.length < 1
    // ) {
    //   setSalarySlipErrorMessage(t("documentUpload:salarySlipRequiredErrorMsg"));
    // }
    //
    // if (
    //   applicationFiles.data.bankstatement == null ||
    //   applicationFiles.data.bankstatement.length < 1
    // ) {
    //   setBankStatementErrorMessage(
    //     t("documentUpload:bankStatementRequiredErrorMsg"),
    //   );
    // }
    //
    // if (
    //   (applicationFiles.data.salaryslip == null ||
    //     applicationFiles.data.salaryslip.length < 1) &&
    //   (applicationFiles.data.bankstatement == null ||
    //     applicationFiles.data.bankstatement.length < 1)
    // ) {
    //   setUploading(false);
    //   setSalarySlipErrorMessage(t("documentUpload:salarySlipRequiredErrorMsg"));
    //   setBankStatementErrorMessage(
    //     t("documentUpload:bankStatementRequiredErrorMsg"),
    //   );
    //   return message.error(
    //     t("documentUpload:salarySlipNBankStatementRequiredErrorMsg"),
    //   );
    // }
    //
    // if (
    //   applicationFiles.data.bankstatement == null ||
    //   applicationFiles.data.bankstatement?.length < 1
    // ) {
    //   setUploading(false);
    //   setBankStatementErrorMessage(
    //     t("documentUpload:bankStatementRequiredOnly1ErrorMsg"),
    //   );
    //   return message.error(
    //     t("documentUpload:bankStatementRequiredOnly1ErrorMsg"),
    //   );
    // }
    //
    // if (
    //   applicationFiles.data.salaryslip == null ||
    //   applicationFiles.data.salaryslip.length < 1
    // ) {
    //   setUploading(false);
    //   setSalarySlipErrorMessage(t("documentUpload:salarySlipRequiredErrorMsg"));
    //   return message.error(t("documentUpload:salarySlipRequiredErrorMsg"));
    // }

    // dispatch(
    //   patchDocumentUploadCompletedStepThunk({
    //     applicationId: applicationDetails?.data?.id,
    //   }),
    // );

    // TODO: route based on partner type
    navigate(`/sme/application/summary`);
  };

  const onGetRequiredFileList = () => {
    dispatch(
      getRequiredFileByPartnerTypeThunk({
        partnerType: organizationType,
      }),
    )
      .unwrap()
      .then((res) => {
        setRequiredFileList(res);
      });
  };

  useEffect(() => {
    onGetRequiredFileList();
  }, []);

  // useEffect(() => {
  //   if (salarySlipErrorMessage !== "" || bankStatementErrorMessage !== "") {
  //     dispatch(
  //       patchApplicationStepErrorThunk({
  //         applicationId: applicationDetails?.data?.id,
  //         stepName: ApplicationStepStatusEnum.DOCUMENTUPLOADCOMPLETED,
  //       }),
  //     );
  //   }
  // }, [salarySlipErrorMessage, bankStatementErrorMessage]);
  //
  // useEffect(() => {
  //   setBankStatementErrorMessage("");
  //   setSalarySlipErrorMessage("");
  //   dispatch(
  //     getFilesByEntityIdThunk({
  //       entityId: applicationDetails?.data?.id,
  //     }),
  //   );
  // }, [applicationDetails?.data?.id, dispatch]);

  // useEffect(() => {
  //   if (
  //     applicationDetails?.data?.applicationStatus !==
  //     ApplicationStatusEnum.DRAFT
  //   ) {
  //     return navigate(`/home`);
  //   }
  // }, [applicationDetails?.data?.applicationStatus]);

  return applicationDetails?.data?.id ? (
    <div className="document-upload-form">
      <p>{t("documentUpload:instruction")}</p>
      <br />

      {requiredFileList.map((item: { code: string; value: string }) => (
        <>
          {applicationFiles.isLoading ? (
            <Skeleton />
          ) : (
            <SmeFileUploadField
              entityId={applicationDetails?.data?.id ?? ""}
              fileType={item.code}
              setUploading={setUploading}
              callback={refreshFileList}
              defaultFileList={
                applicationFiles?.data[
                  item.code as keyof typeof applicationFiles.data
                ]
              }
              title={
                // TODO: add translation
                <p>{makeWordBold(item.value, "")}</p>
              }
              // errorMsg={salarySlipErrorMessage}
            />
          )}
          <Divider />
        </>
      ))}

      <br />
      <button
        type="submit"
        className="primary-button"
        disabled={uploading || applicationFiles.isLoading}
        onClick={onFinish}
      >
        {uploading && (
          <LoadingOutlined style={{ fontSize: 28, marginRight: 8 }} />
        )}
        {t("documentUpload:submitBtnLabel")}
      </button>
      <Button onClick={() => {}}>Goto summary</Button>
      <br />
    </div>
  ) : (
    <Button onClick={() => navigate("/sme/application/summary")}>
      Goto application summary
    </Button>
    // <Alert
    //   message={
    //     <h2 className="m-0 text-center text-red-600">
    //       {t("documentUpload:applicationNotFoundMsg")}
    //     </h2>
    //   }
    //   type="error"
    // />
  );
};
