import { createAsyncThunk } from "@reduxjs/toolkit";
import agreementService from "./agreementService";
import { restructureErrorMessage } from "../../helpers/errorMessage";

export const getAgreementDetailsThunk = createAsyncThunk(
  "agreement/getDetails",
  async (requestData: any, thunkAPI) => {
    try {
      return await agreementService.getAgreementDetails(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getAgreementStepStatusThunk = createAsyncThunk(
  "agreement/getStepStatus",
  async (requestData: any, thunkAPI) => {
    try {
      return await agreementService.getAgreementStepStatus(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const signAgreementThunk = createAsyncThunk(
  "agreement/signAgreement",
  async (requestData: any, thunkAPI) => {
    try {
      return await agreementService.signAgreement(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getRepaymentScheduleListThunk = createAsyncThunk(
  "agreement/getRepaymentScheduleList",
  async (requestData: any, thunkAPI) => {
    console.log(requestData?.agreementNo);
    try {
      return await agreementService.getRepaymentScheduleList(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
