import moment from "moment";

export const getNricInfo = (ic: string) => {
  const resultText =
    /^(\d{2})(\d{2})(\d{2})?(\d{2})?(\d{3})(\d)$/.exec(ic) || [];

  let year = +resultText[1];
  const month = +resultText[2];
  const day = +resultText[3];
  // let gender = +resultText[6];

  if (year >= 0 && year <= 35) {
    year = +`20${year.toString().padStart(2, "0")}`;
  }

  const birthdate = new Date(year, month - 1, day, 0, 0, 0, 0);

  let age = new Date().getFullYear() - birthdate.getFullYear();

  if (
    birthdate.getMonth() > new Date().getMonth() ||
    (new Date().getMonth() === birthdate.getMonth() &&
      birthdate.getDate() > new Date().getDate())
  ) {
    age--;
  }

  return {
    age,
    birthDate: moment(birthdate),
  };
};
