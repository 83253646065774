import { Alert, Row, Space } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createBreakpoint } from "react-use";

import { numberWithCommas } from "../../../helpers/number";
import { RootState } from "../../../store/store";
import ConfirmCancelApplication from "../../modals/ConfirmCancelApplication";

const useBreakpoint = createBreakpoint();

const AgreementESigningStatusContent = () => {
  const navigate = useNavigate();
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === "tablet";
  const { t } = useTranslation(["common", "loanStatus"]);

  const [loading, setLoading] = useState(false);

  const { applicationDetails } = useSelector(
    (state: RootState) => state.application,
  );

  const proceedToAgreementSigning = () => {
    setLoading(true);
    // navigate(`/loan-status/loan-agreement`);
    navigate(`/loan-status/account-creation`);
    setLoading(false);
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <div
        className="flex-center-vertical"
        style={{ alignItems: isMobile ? "center" : "flex-start" }}
      >
        <span>{t("loanStatus:Your_Approved_Loan_Amount")}</span>
        <h1>
          RM{" "}
          {numberWithCommas(
            applicationDetails?.data?.financeAmount ??
              applicationDetails?.data?.loanApplied ??
              0,
          )}
        </h1>
      </div>
      <Alert
        className="alert-card"
        style={{ textAlign: isMobile ? "center" : "left" }}
        message={
          <h3 className="alert-title-success">
            {t("loanStatus:attestation_completed_title")}
          </h3>
        }
        description={
          <p style={{ margin: 0 }}>
            {t("loanStatus:attestation_completed_description")}
          </p>
        }
        type="success"
      />
      <Row>
        <button
          className="primary-button"
          disabled={loading}
          onClick={proceedToAgreementSigning}
          style={{
            width:
              isMobile ||
              process.env.REACT_APP_AGREEMENT_CANCELED_ENABLED === "NO"
                ? "100%"
                : "50%",
          }}
        >
          {t("loanStatus:Proceed_to_signing")}
        </button>
        {process.env.REACT_APP_AGREEMENT_CANCELED_ENABLED !== "NO" && (
          <ConfirmCancelApplication key={applicationDetails?.data?.id} />
        )}
      </Row>
    </Space>
  );
};

export default AgreementESigningStatusContent;
