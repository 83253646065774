import "./index.less";

import { Col, Row, Space } from "antd";
import { Radio } from "antd-mobile";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import videoCallIcon from "../../assets/icons/attestation-option-video-call.png";
import watchVideoIcon from "../../assets/icons/attestation-option-watch-video.png";
import { AgreementStatusEnum } from "../../enums/agreementStepStatus";
import { RootState } from "../../store/store";

export const SmeAttestationOptionContent = () => {
  const navigate = useNavigate();

  const [needLiveAttestation, setNeedLiveAttestation] = useState(false);

  const { t } = useTranslation(["common", "attestationOption"]);

  const { agreementDetails } = useSelector(
    (state: RootState) => state.agreement,
  );
  const onFinish = async () => {
    if (needLiveAttestation) {
      navigate(`/sme/loan-status/attestation-option/live-call`);
    } else {
      navigate(`/sme/loan-status/attestation-option/video`);
    }
  };

  const onChange = (value: number | string) => {
    if (value === "live") {
      setNeedLiveAttestation(true);
    } else {
      setNeedLiveAttestation(false);
    }
  };

  // useEffect(() => {
  //   if (
  //     agreementDetails?.data?.agreementStatus !== AgreementStatusEnum.PENDING &&
  //     agreementDetails?.data?.agreementStatus !==
  //       AgreementStatusEnum.ATTESTATIONOPTION
  //   ) {
  //     return navigate(`/home`);
  //   }
  // }, [agreementDetails?.data?.agreementStatus, navigate]);

  return (
    <div className="text-center max-w-lg">
      <p className="my-8">{t("attestationOption:instruction")}</p>
      <Radio.Group defaultValue="video" onChange={(value) => onChange(value)}>
        <Space size="large" direction="vertical" className="w-full mt-12 mb-12">
          <Radio value="video" className="attestation-option-radio-button">
            <Row align="middle" wrap={false}>
              <Col>
                <img src={watchVideoIcon} alt="icon" />
              </Col>
              <Col>
                <span className="text-base">
                  {t("attestationOption:watchVideo")}
                </span>
              </Col>
            </Row>
          </Radio>
          <strong>{t("attestationOption:or")}</strong>
          <Radio value="live" className="attestation-option-radio-button">
            <Row align="middle" wrap={false}>
              <Col>
                <img src={videoCallIcon} alt="icon" />
              </Col>
              <Col>
                <span className="text-base">
                  {t("attestationOption:scheduleCall")}
                </span>
              </Col>
            </Row>
          </Radio>
        </Space>
      </Radio.Group>
      <div className="attestation-option-content-submit-button">
        <button type="submit" className="primary-button" onClick={onFinish}>
          {t("attestationOption:submitBtnLabel")}
        </button>
      </div>
    </div>
  );
};
