import "./index.less";
import "dayjs/locale/ms";

import { Card, message, Modal, TimePicker } from "antd";
import { Picker } from "antd-mobile";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createBreakpoint } from "react-use";

import { CheckCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { ThemeProvider } from "@mui/material";
import TextField from "@mui/material/TextField";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import videoCallIcon from "../../assets/icons/attestation-option-video-call.png";
import { AgreementStatusEnum } from "../../enums/agreementStepStatus";
import {
  getAgreementDetailsThunk,
  getAgreementStepStatusThunk,
} from "../../services/agreementService/agreementThunk";
import { createAttestationScheduleThunk } from "../../services/attestationService/attestationThunk";
import { AppDispatch, RootState } from "../../store/store";
import { dateFormat, isToday } from "../../utils/datetime.util";
import { muiTheme } from "../../utils/muiTheme";

import type { Moment } from "moment";
import type { DisabledTimes } from "rc-picker/lib/interface";

const useBreakpoint = createBreakpoint();
const cardBodyStyle = { padding: 8 };

export const SmeLiveCallAttestationContent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { t } = useTranslation(["common", "liveCallAttestation"]);
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === "tablet";

  const [loading, setLoading] = useState(false);
  const [requestedDate, setRequestedDate] = useState<Moment | null>(null);
  const [selectedHour, setSelectedHour] = useState<number | null>(null);
  const [requestedTime, setRequestedTime] = useState<Moment | null>();
  const [timePicketVisible, setTimePicketVisible] = useState(false);
  const { agreementDetails } = useSelector(
    (state: RootState) => state.agreement,
  );
  const { language } = useSelector((state: RootState) => state.config);

  const handleMobileDateChange = (newValue: Moment | null) => {
    if (newValue) {
      setRequestedDate(newValue);
      setRequestedTime(null);
      setSelectedHour(null);
    }
  };

  const onDesktopTimeSelect = (time: any) => {
    const hour = parseInt(time.format("HH"), 10);
    const minute = parseInt(time.format("m"), 10);

    const today = new Date();
    const currentHour: number = today.getHours();
    const currentMinutes: number = today.getMinutes();

    const disabledHours = Array.from(Array(24).keys()).filter((h) => {
      if (requestedDate != null && isToday(requestedDate)) {
        return currentMinutes < 45
          ? h < 9 || h > 16 || h < currentHour
          : h < 9 || h > 16 || h <= currentHour;
      }
      return h < 9 || h > 16;
    });

    const disabledMinutes = Array.from(Array(60).keys()).filter((m) => {
      if (
        requestedDate != null &&
        isToday(requestedDate) &&
        hour != null &&
        currentHour === hour
      ) {
        return m <= currentMinutes;
      }

      return null;
    });

    setSelectedHour(hour);

    if (!disabledHours.includes(hour) && !disabledMinutes.includes(minute)) {
      setRequestedTime(time);
    } else {
      setRequestedTime(null);
    }
  };

  const onMobileTimeChange = (value: any) => {
    if (value) {
      const date = moment(requestedDate).format("DD-MM-YYYY");
      const dateTime = moment(
        `${date} ${value[0]}:${value[1]} ${value[2]}`,
        "DD-MM-YYYY hh:mm A",
      );
      setRequestedTime(dateTime);
    }
  };

  const disabledDate = (current: any) => {
    const today = new Date();
    const currentHour: number = today.getHours();
    const currentMinutes: number = today.getMinutes();

    const passWorkingHourToday =
      currentHour >= 17 || (currentHour === 16 && currentMinutes > 45);

    // check if today pass working hour
    const noPast = current.isBefore(
      moment().subtract(passWorkingHourToday ? 0 : 1, "day"),
    );

    // disable today
    // const noPast = current.isBefore(moment());

    // 0 for Sunday, 6 for Saturday
    const noWeekends = current.day() === 0 || current.day() === 6;

    return noPast || noWeekends;
  };

  const disabledTime = (now: any) => {
    const today = new Date();
    const currentHour: number = today.getHours();
    const currentMinutes: number = today.getMinutes();

    const disabledHours = Array.from(Array(24).keys()).filter((h) => {
      if (requestedDate != null && isToday(requestedDate)) {
        // if more that 45 means it will not have available time slot for this hour
        return currentMinutes < 45
          ? h < 9 || h > 16 || h < currentHour
          : h < 9 || h > 16 || h <= currentHour;
      }
      return h < 9 || h > 16;
    });

    const disabledMinutes = Array.from(Array(60).keys()).filter((m) => {
      if (
        requestedDate != null &&
        isToday(requestedDate) &&
        currentHour === 16
      ) {
        return m <= currentMinutes;
      }
      if (
        requestedDate != null &&
        isToday(requestedDate) &&
        selectedHour != null &&
        selectedHour === currentHour
      ) {
        return m <= currentMinutes;
      }

      return null;
    });

    // desktop time picker
    const disabledTimes: DisabledTimes = {
      disabledHours: () => disabledHours,
      disabledMinutes: () => disabledMinutes,
    };
    return disabledTimes;
  };

  // mobile time picker
  const mobileTimePickerColumns = [
    // hour
    Array.from(Array(24).keys())
      .filter((h) => h >= 9 && h < 17)
      // check if hour pass office hour
      .filter((h) => {
        if (requestedDate != null && isToday(requestedDate)) {
          const today = new Date();
          const currentHour: number = today.getHours();
          const currentMinutes: number = today.getMinutes();

          // if more that 45 means it will not have available time slot for this hour
          return currentMinutes > 45 ? h > currentHour : h >= currentHour;
        }
        return h;
      })
      .map((i) => ({
        label: i < 10 ? `0${i}` : `${i}`,
        value: i < 10 ? `0${i}` : `${i}`,
      })),

    // minutes
    Array.from(Array(60).keys())
      .filter((num, index) => index % 15 === 0)
      // check if minutes pass office hour
      .filter((m) => {
        const today = new Date();
        const currentHour: number = today.getHours();
        if (selectedHour != null && selectedHour === currentHour) {
          const currentMinutes: number = today.getMinutes();
          return m > currentMinutes;
        }
        return `${m}`;
      })
      .map((i) => ({
        label: i < 10 ? `0${i}` : `${i}`,
        value: i < 10 ? `0${i}` : `${i}`,
      })),
    // [
    //   { label: "AM", value: "AM" },
    //   { label: "PM", value: "PM" },
    // ],
  ];

  const onFinish = async () => {
    if (requestedDate == null)
      return message.error(t("liveCallAttestation:emptyDateErrorMsg"));
    if (requestedTime == null)
      return message.error(t("liveCallAttestation:emptyTimeErrorMsg"));

    const payload = {
      agreementId: agreementDetails?.data?.id,
      requestedLiveAttestationDate: requestedDate?.toISOString(),
      requestedLiveAttestationTime: requestedTime?.toISOString(),
      companyId: process.env.REACT_APP_COMPANY_ID,
      liveAttestationRequest: true,
      language,
    };

    setLoading(true);
    dispatch(createAttestationScheduleThunk(payload))
      .unwrap()
      .then((res) => {
        // update latest agreement details
        dispatch(
          getAgreementDetailsThunk({
            agreementId: agreementDetails?.data?.id,
          }),
        );

        // update latest agreement step status
        dispatch(
          getAgreementStepStatusThunk({
            agreementId: agreementDetails?.data?.id,
          }),
        );

        Modal.success({
          icon: null,
          content: (
            <div className="flex-center-vertical ">
              <CheckCircleOutlined className="check-circle-outlined-icon" />
              <h1>{t("liveCallAttestation:confirmedModalTitle")}</h1>
              <p style={{ textAlign: "center" }}>
                {t("liveCallAttestation:confirmedModalMsg")}
              </p>
            </div>
          ),
          okButtonProps: {
            className: "primary-button schedule-confirm-modal-ok-button",
          },
          okText: t("liveCallAttestation:Continue"),
          onOk() {
            navigate(`/sme/home`);
          },
          closable: true,
          afterClose() {
            navigate(`/sme/home`);
          },
        });
      })
      .finally(() => setLoading(false));
  };

  // useEffect(() => {
  //   if (
  //     agreementDetails?.data?.agreementStatus !== AgreementStatusEnum.PENDING &&
  //     agreementDetails?.data?.agreementStatus !==
  //       AgreementStatusEnum.ATTESTATIONOPTION
  //   ) {
  //     return navigate(`/home`);
  //   }
  // }, [agreementDetails?.data?.agreementStatus, navigate]);

  return (
    <div className="live-attestation-content">
      <img src={videoCallIcon} alt="ID Card" />
      <h2>{t("liveCallAttestation:title")}</h2>
      <p>{t("liveCallAttestation:subtitle")}</p>

      <div>
        <span>{t("liveCallAttestation:Business_hour")}:</span>
        <br />
        <span>9.00AM - 5.00PM</span>
        <br />
        <span>{t("liveCallAttestation:Monday_to_Friday")}</span>
        <br />
        <br />
      </div>

      <Card className="live-attestation-input-card" bodyStyle={cardBodyStyle}>
        <div className="flex-space-between">
          <strong>{t("liveCallAttestation:Date")}</strong>
          <div className="w-[120px]">
            <ThemeProvider theme={muiTheme}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={language.toLowerCase() ?? "en"}
                localeText={{
                  cancelButtonLabel: t("liveCallAttestation:Cancel"),
                  okButtonLabel: "OK",
                }}
              >
                <MobileDatePicker
                  inputFormat={dateFormat}
                  value={requestedDate}
                  onChange={handleMobileDateChange}
                  shouldDisableDate={disabledDate}
                  showToolbar={false}
                  onOpen={() => {
                    // if (requestedDate == null)
                    //   setRequestedDate(moment().add(1, "day"));
                  }}
                  disablePast
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label=""
                        variant="standard"
                        placeholder={t(
                          "liveCallAttestation:dateInputPlaceholder",
                        )}
                        InputProps={{ disableUnderline: true }}
                        sx={{
                          input: {
                            textAlign: "center",
                            fontSize: 14,
                            padding: "16px 0",
                            color: "#000000a6",
                            cursor: "pointer",
                            "::placeholder": {
                              color: "#000000 !important",
                            },
                          },
                        }}
                      />
                    );
                  }}
                />
              </LocalizationProvider>
            </ThemeProvider>
          </div>
        </div>
      </Card>
      {requestedDate != null && (
        <Card className="live-attestation-input-card" bodyStyle={cardBodyStyle}>
          <div className="flex-space-between">
            <strong>{t("liveCallAttestation:Time")}</strong>
            {isMobile ? (
              <div className="w-[120px]">
                <button
                  className="p-0 border-0 bg-inherit"
                  onClick={() => {
                    setTimePicketVisible(true);
                  }}
                >
                  {requestedTime?.format("HH:mm A") ?? (
                    <span style={{ color: "rgb(0 0 0 / 50%)" }}>
                      {t("liveCallAttestation:timeInputTitle")}
                    </span>
                  )}
                </button>
                <Picker
                  columns={mobileTimePickerColumns}
                  title={t("liveCallAttestation:timeInputTitle")}
                  confirmText={t("liveCallAttestation:Done")}
                  cancelText={t("liveCallAttestation:Cancel")}
                  visible={timePicketVisible}
                  onSelect={(timeArr: any) => {
                    // convert to number type
                    setSelectedHour(timeArr?.length > 0 ? +timeArr[0] : null);
                  }}
                  onClose={() => {
                    setTimePicketVisible(false);
                  }}
                  onConfirm={onMobileTimeChange}
                />
              </div>
            ) : (
              <TimePicker
                className="live-attestation-input"
                showNow={false}
                onSelect={onDesktopTimeSelect}
                onChange={onDesktopTimeSelect}
                value={requestedTime}
                minuteStep={15}
                format="HH:mm A"
                disabledTime={disabledTime}
                hideDisabledOptions
                bordered={false}
                use12Hours={false}
                clearIcon={false}
                suffixIcon={false}
                placeholder={t("liveCallAttestation:timeInputTitle")}
              />
            )}
          </div>
        </Card>
      )}
      <button
        type="submit"
        className="primary-button"
        onClick={onFinish}
        disabled={loading}
      >
        {loading ? (
          <LoadingOutlined style={{ fontSize: 28 }} />
        ) : (
          t("liveCallAttestation:submitBtnLabel")
        )}
      </button>
    </div>
  );
};
