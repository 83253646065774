import "./index.less";

import { Layout, Menu } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import redCashLogo from "../../assets/logo/redCASH-logo-based-red-transp.png";
import { defaultTextColor, primaryColor } from "../../assets/style/variables";
import { RootState } from "../../store/store";
import LoginModal from "../modals/LoginModal";
import MenuModal from "../modals/MenuModal";

import type { MenuProps } from "antd/es/menu";
import { LANGUAGE } from "../../features/config/configSlice";

const { Header } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

export const WebHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { hasLoggedUser } = useSelector((state: RootState) => state.user);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const showLoginSearchParam = new URLSearchParams(location.search).get(
    "showLogin",
  );
  const { t } = useTranslation(["common"]);

  const [menuItems, setMenuItems] = useState([getItem("", "/")]);

  const { language } = useSelector((state: RootState) => state.config);

  const onMenuItemClick: MenuProps["onClick"] = (e) => {
    if (e.key === "/sso-login") {
      window.open("https://uat-cep-redcash.arkmind-dev.com/auth", "_blank");
    }
    navigate(e.key);
  };

  useEffect(() => {
    showLoginSearchParam === "true" && setIsLoginModalOpen(true);
  }, [showLoginSearchParam]);

  useEffect(() => {
    let noAuthMenuItems: MenuItem[];
    if (process.env.REACT_APP_SME_ENABLED === "YES") {
      noAuthMenuItems = [
        // TODO: add translations

        getItem("SSO Login", "/sso-login"),
        getItem(t("common:About_Us"), "/about-us"),
        getItem(t("common:FAQs"), "/faq"),
        getItem(t("common:Legal_Terms"), "/legal-terms"),
        getItem("EN | BM", "lang", null, [
          getItem(
            <span
              style={
                language === LANGUAGE.EN
                  ? { color: primaryColor, fontWeight: 600 }
                  : { color: defaultTextColor }
              }
            >
              English
            </span>,
            `${location.pathname}?language=${LANGUAGE.EN}`,
          ),
          getItem(
            <span
              style={
                language === LANGUAGE.MS
                  ? { color: primaryColor, fontWeight: 600 }
                  : { color: defaultTextColor }
              }
            >
              Bahasa Melayu
            </span>,
            `${location.pathname}?language=${LANGUAGE.MS}`,
          ),
        ]),
        getItem(t("common:Login"), "/?showLogin=true"),
      ];
    } else {
      noAuthMenuItems = [
        // TODO: add translations

        getItem(t("common:About_Us"), "/about-us"),
        getItem(t("common:FAQs"), "/faq"),
        getItem(t("common:Legal_Terms"), "/legal-terms"),
        getItem("EN | BM", "lang", null, [
          getItem(
            <span
              style={
                language === LANGUAGE.EN
                  ? { color: primaryColor, fontWeight: 600 }
                  : { color: defaultTextColor }
              }
            >
              English
            </span>,
            `${location.pathname}?language=${LANGUAGE.EN}`,
          ),
          getItem(
            <span
              style={
                language === LANGUAGE.MS
                  ? { color: primaryColor, fontWeight: 600 }
                  : { color: defaultTextColor }
              }
            >
              Bahasa Melayu
            </span>,
            `${location.pathname}?language=${LANGUAGE.MS}`,
          ),
        ]),
        getItem(t("common:Login"), "/?showLogin=true"),
      ];
    }

    const authMenuItems: MenuItem[] = [
      getItem(t("common:About_Us"), "/about-us"),
      getItem(t("common:FAQs"), "/faq"),
      getItem(t("common:Legal_Terms"), "/legal-terms"),
      getItem("EN | BM", "lang", null, [
        getItem(
          <span
            style={
              language === LANGUAGE.EN
                ? { color: primaryColor, fontWeight: 600 }
                : { color: defaultTextColor }
            }
          >
            English
          </span>,
          `${location.pathname}?language=${LANGUAGE.EN}`,
        ),
        getItem(
          <span
            style={
              language === LANGUAGE.MS
                ? { color: primaryColor, fontWeight: 600 }
                : { color: defaultTextColor }
            }
          >
            Bahasa Melayu
          </span>,
          `${location.pathname}?language=${LANGUAGE.MS}`,
        ),
      ]),
    ];

    setMenuItems(hasLoggedUser ? authMenuItems : noAuthMenuItems);
  }, [language, hasLoggedUser, location.pathname, t]);

  return (
    <>
      <Header className="desktop-layout-header">
        <div
          style={{ cursor: "pointer" }}
          onClick={() => navigate(hasLoggedUser ? "/home/" : "/")}
        >
          <img className="header-logo" src={redCashLogo} alt="redCASH logo" />
        </div>
        <div className="header-nav-menu-cointainer">
          <Menu
            className="header-menu"
            mode="horizontal"
            selectedKeys={[location.pathname]}
            items={menuItems}
            onClick={onMenuItemClick}
          />
          <MenuModal />
        </div>
      </Header>
      {isLoginModalOpen && (
        <LoginModal
          hideButton={true}
          defaultShowModal={isLoginModalOpen}
          callback={() => setIsLoginModalOpen(false)}
        />
      )}
    </>
  );
};
