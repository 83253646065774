import parse from "html-react-parser";
import { primaryColor } from "../assets/style/variables";

export const formatterNumber = (val: string) => {
  if (!val) return 0;
  return `${val}`
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    .replace(/\.(?=\d{0,2}$)/g, ",");
};

export const parserNumber = (val: string) => {
  if (!val) return 0;
  return Number.parseFloat(
    val.replace(/\$\s?|(\.*)/g, "").replace(/(,{1})/g, "."),
  ).toFixed(2);
};

export const formatNRIC = (nric: string | undefined) => {
  const cleaned = ("" + nric).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{6})(\d{2})(\d{4})$/);
  if (match) {
    return "" + match[1] + "-" + match[2] + "-" + match[3];
  }
  return `${cleaned}`;
};

export const makeRedWordBlack = (inputString: string) => {
  // Word to extract and style
  const wordToStyle = "red";

  // Regular expression to match the word followed by CASH or ONE
  const regex = /\bred(?=(CASH|ONE)\b)/gi;

  // Apply the style to the word using a <span> element
  const styledString = inputString.replace(
    regex,
    `<span style="color: #000; font-weight: normal">${wordToStyle}</span>`,
  );
  return parse(`<span>${styledString}</span>`);
};

export const styleBrandLabel = (inputString: string) => {
  const regex = /red(CASH|ONE)/gi;

  // Apply the style to the word using a <span> element
  const styledString = inputString.replace(
    regex,
    (match, p1) => `red<span style="color:${primaryColor}">${p1}</span>`,
  );

  return parse(`<span>${styledString}</span>`);
};

export const makeWordBold = (inputString: string, wordToBold: string) => {
  const regex = new RegExp(wordToBold, "gi");
  const styledString = inputString.replace(regex, "<strong>$&</strong>");

  return parse(`<span>${styledString}</span>`);
};

export const addLinkToWords = (
  inputString: string,
  wordToLink: string | string[],
  link: string | string[],
) => {
  if (typeof wordToLink === "string") {
    const regex = new RegExp(wordToLink, "gi");
    const styledString = inputString.replace(
      regex,
      `<a href=${link} style="color:${primaryColor}">$&</a>`,
    );

    return parse(`<span>${styledString}</span>`);
  }

  if (Array.isArray(wordToLink)) {
    let styledString = inputString;
    wordToLink.forEach((word, index) => {
      const regex = new RegExp(word, "gi");
      styledString = styledString.replace(
        regex,
        `<a href=${link[index]} style="color:${primaryColor}">$&</a>`,
      );
    });
    return parse(`<span>${styledString}</span>`);
  }

  return inputString;
};

export const splitParagraphIntoSentence = (inputString: string) => {
  // Regular expression to split on sentence-ending punctuation followed by one or more whitespace characters
  const regex = /(?<=[.!?;:…—,"’\])})]\s+)/;
  const sentences = inputString.split(regex);

  return sentences;
};

export function startCase(inputString: string): string {
  const words = inputString.split(" ");
  const output = words?.map((word) => {
    if (word === "redONE") {
      return word;
    } else {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
  });
  return output?.join(" ") ?? inputString;
}

export const removeBreakpointAndTrim = (str?: string) => {
  // Remove line breaks
  const withoutBreaks = str?.replace(/(\r\n|\n|\r)/gm, "");

  // Trim leading/trailing spaces
  return withoutBreaks?.trim();
};
