import "./index.less";

import { PageHeader } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { AccountCreationForm } from "../../../components/AccountCreationForm";
import { WebRegistrationLayout } from "../../../layouts/web/WebRegistrationLayout";
import { styleBrandLabel } from "../../../utils/stringFormatter";
import { SmeAccountCreationForm } from "../../../components/SmeAccountCreationForm";

const WebSmeAccountCreationPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["common", "registration"]);

  return (
    <WebRegistrationLayout>
      <div className="max-w-lg px-2">
        <PageHeader
          onBack={() => navigate("/sme/home")}
          title={
            <strong>
              {styleBrandLabel(t("registration:account_creation_form_title"))}
            </strong>
          }
        />
        <p className="px-2">{t("registration:account_creation_description")}</p>
        <SmeAccountCreationForm />
      </div>
    </WebRegistrationLayout>
  );
};

export default WebSmeAccountCreationPage;
