import "./WebSmeIdentityVerificationPage.less";

import { PageHeader } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { IdentityVerificationContent } from "../../../components/IdentityVerificationContent";
import { WebApplyApplicationLayout } from "../../../layouts/web/WebApplyApplicationLayout";
import { SmeIdentityVerificationContent } from "../../../components/SmeIdentityVerificationContent";

const WebSmeIdentityVerificationPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["common", "identityVerification"]);

  return (
    <WebApplyApplicationLayout step={4}>
      <div className="web-apply-application-frame">
        <PageHeader
          style={{ padding: 12 }}
          onBack={() => navigate(-1)}
          title={<strong>{t("identityVerification:pageTitle")}</strong>}
        />
        <SmeIdentityVerificationContent />
      </div>
    </WebApplyApplicationLayout>
  );
};

export default WebSmeIdentityVerificationPage;
