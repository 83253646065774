import "./index.less";

import { Button, Col, Layout, Row, Space } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { FacebookFilled, InstagramFilled } from "@ant-design/icons";

import { RootState } from "../../store/store";

const { Footer } = Layout;

export const WebFooter = () => {
  const { language } = useSelector((state: RootState) => state.config);
  const { t } = useTranslation(["common"]);

  return (
    <>
      <Footer className="desktop-footer">
        <div className="flex flex-nowrap">
          <div className="w-2/5">
            <div className="w-full">
              <div
                style={{
                  fontFamily: "Poppins",
                  fontSize: 32,
                }}
              >
                <span className="font-[300]">red</span>
                <span className="font-[600] text-[#e40308]">CASH</span>
              </div>
            </div>
            <div className="flex">
              <h4 className="text-[14px] font-bold mr-0.5">
                RED ONE NETWORK SDN. BHD.
              </h4>
              <span className="mt-0.5"> 200301016674 (619094-D)</span>
            </div>
            <div className="w-full pr-24">
              <p>{t("common:officeAddress")}</p>
              <p className="mb-1">License Number: WL7134/10/01-3/230725</p>
              <p className="mb-1">License Validity: 24/07/2023 - 23/07/2025</p>
            </div>
          </div>
          <div className="w-3/5">
            <Row align="top" justify="space-around">
              <Col className="flex-center-vertical">
                <h3>{t("common:About_Us")}</h3>
                <a
                  className="desktop-footer-link"
                  href={`/about-us?language=${language}`}
                >
                  redCASH
                </a>
                <Space className="mt-4">
                  <Button
                    type="text"
                    target="_blank"
                    className="social-icon-button"
                    icon={<FacebookFilled />}
                    href="https://www.facebook.com/redONEMobile"
                  />
                  <Button
                    type="text"
                    target="_blank"
                    className="social-icon-button"
                    icon={<InstagramFilled />}
                    href="https://www.instagram.com/redonemobile.my"
                  />
                </Space>
              </Col>
              <Col className="flex-center-vertical">
                <h3>{t("common:Legal")}</h3>
                <a
                  className="desktop-footer-link"
                  href={`/legal-terms?language=${language}`}
                >
                  {t("common:Legal_Terms")}
                </a>
              </Col>
              <Col className="flex-center-vertical">
                <h3>{t("common:Support")}</h3>
                <a
                  className="desktop-footer-link"
                  href={`/faq?language=${language}`}
                >
                  {t("common:FAQs")}
                </a>
                <a
                  className="desktop-footer-link"
                  href={`/contact-us?language=${language}`}
                >
                  {t("common:Contact_Us")}
                </a>
              </Col>
            </Row>
          </div>
        </div>
        <br />
      </Footer>
      <div className="w-full bg-black text-center text-white py-4">
        © 2023 RED ONE NETWORK SDN BHD (619094-D)
      </div>
    </>
  );
};
