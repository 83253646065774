import "./RequirementPanel.less";

import { Collapse } from "antd";
import { useTranslation } from "react-i18next";

import RequirementDocumentTable from "./RequirementDocumentTable/RequirementDocumentTable";

const { Panel } = Collapse;

export default function RequirementPanel() {
  const { t } = useTranslation(["common", "requirementPanel"]);

  return (
    <Collapse
      bordered={false}
      expandIconPosition="end"
      defaultActiveKey={["1", "2"]}
    >
      <Panel
        header={
          <h2 className="panel-title">
            {t("requirementPanel:Eligibility_Requirements")}
          </h2>
        }
        key="1"
      >
        <ul className="list-disc list-outside">
          <li>{t("requirementPanel:requirements1")}</li>
          <li>{t("requirementPanel:requirements2")}</li>
          <li>{t("requirementPanel:requirements3")}</li>
          <li>{t("requirementPanel:requirements4")}</li>
        </ul>
      </Panel>
      <Panel
        header={
          <h2 className="panel-title">
            {t("requirementPanel:Documents_Required")}
          </h2>
        }
        key="2"
      >
        <RequirementDocumentTable />
      </Panel>
    </Collapse>
  );
}
