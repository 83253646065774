import { PageHeader } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ApprovedLoanSummaryContent } from "../../../components/ApprovedLoanSummaryContent";
import { WebLoanStatusLayout } from "../../../layouts/web/WebLoanStatusLayout";
import { SmeApprovedLoanSummaryContent } from "../../../components/SmeApprovedLoanSummaryContent";
import { WebSmeLoanStatusLayout } from "../../../layouts/web/WebSmeLoanStatusLayout";

const WebSmeApprovedLoanSummaryPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["common", "approvedLoanSummary"]);

  return (
    <WebSmeLoanStatusLayout step={1}>
      <div className="max-w-xl w-full px-4 mx-auto">
        <PageHeader
          style={{ padding: 12 }}
          title={<strong>{t("approvedLoanSummary:pageTitle")}</strong>}
          onBack={() => navigate(-1)}
        />
        <SmeApprovedLoanSummaryContent showLoanSummary={false} />
      </div>
    </WebSmeLoanStatusLayout>
  );
};

export default WebSmeApprovedLoanSummaryPage;
