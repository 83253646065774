import { beSetupAxiosInstance } from "../../axios/beSetupAxios";
import { collectionSetupAxiosInstance } from "../../axios/collectionSetupAxios";

const getAgreementDetails = async (requestData: any) => {
  const { agreementId } = requestData;
  const url = `/redCash/api/v1.0/agreement/${agreementId}/summary`;
  const res = await beSetupAxiosInstance.get(url);
  return res.data;
};

const getAgreementStepStatus = async (requestData: any) => {
  const { agreementId } = requestData;

  const url = `/redCash/api/v1.0/agreement/${agreementId}/status`;
  const res = await beSetupAxiosInstance.get(url);
  return res.data;
};

const signAgreement = async (requestData: any) => {
  const { agreementId, payload, language } = requestData;

  const url = `/redCash/api/v1.0/pdf/sign/${language}/company/${process.env.REACT_APP_COMPANY_ID}/agreement/${agreementId}`;
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const res = await beSetupAxiosInstance.post(url, payload, config);
  return res.data;
};

const getRepaymentScheduleList = async (requestData: any) => {
  const url = `/redCash/api/v1.0/accountTenureDetails/agreementNo/${requestData.agreementNo}`;
  const res = await collectionSetupAxiosInstance.get(url);
  return res.data;
};

const agreementService = {
  getAgreementDetails,
  getAgreementStepStatus,
  signAgreement,
  getRepaymentScheduleList,
};

export default agreementService;
