import React from "react";
import { NavBar } from "antd-mobile";
import { useNavigate } from "react-router-dom";

import "./MobileTopNavHeaderLayout.less";

export type MobileTopNavHeaderLayoutProps = {
  title: string;
  onBack?: () => void;
} & {
  children: React.ReactNode;
};

export const MobileTopNavHeaderLayout: React.FC<
  MobileTopNavHeaderLayoutProps
> = (props) => {
  const { title, onBack, children } = props;
  const navigate = useNavigate();
  const toPreviousPage = () => navigate(-1);

  return (
    <div className="h-screen">
      <NavBar
        onBack={onBack ?? toPreviousPage}
        className="fixed w-full bg-white"
      >
        <strong>{title}</strong>
      </NavBar>
      <div className="mobile-top-nav-layout-content">{children}</div>
    </div>
  );
};
