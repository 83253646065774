import "./MobileSmeLiveCallAttestationPage.less";

import { LiveCallAttestationContent } from "../../../components/LiveCallAttestationContent";
import { MobileTopNavHeaderLayout } from "../../../layouts/mobile/MobileTopNavHeaderLayout/MobileTopNavHeaderLayout";
import { useTranslation } from "react-i18next";
import { SmeLiveCallAttestationContent } from "../../../components/SmeLiveCallAttestationContent";

const MobileSmeLiveCallAttestationPage = () => {
  const { t } = useTranslation(["common", "liveCallAttestation"]);

  return (
    <MobileTopNavHeaderLayout title={t("liveCallAttestation:pageTitle")}>
      <SmeLiveCallAttestationContent />
    </MobileTopNavHeaderLayout>
  );
};

export default MobileSmeLiveCallAttestationPage;
