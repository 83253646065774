import { numberWithCommas } from "../helpers/number";
import { roundUpToNearestThousands } from "./number";

export const onCalculateRepaymentInstalmentAmount = (
  loanAmount: number,
  tenure: number,
) => {
  return (loanAmount + ((loanAmount * 18) / 100) * (tenure / 12)) / tenure;
};

export const onCalculateStampingDutyFee = (
  loanAmount: number,
  stampingDutyPercentage: number,
) => {
  const amt = roundUpToNearestThousands(loanAmount);
  return (amt * stampingDutyPercentage) / 100;
};

export const onCalculateTotalInterest = (
  loanAmount: number,
  interestRate: number,
  tenure: number,
) => {
  return (((loanAmount * (interestRate / 100)) / 12) * tenure).toFixed(2);
};

export const roundUp = (number: number) => Math.ceil(number);

export const onCalculateEstimatedMonthlyAmount = (
  loanAmount: number,
  interestRate: number,
  tenure: number,
) => {
  const totalInterestAmt = +onCalculateTotalInterest(
    loanAmount,
    interestRate,
    tenure,
  );
  return roundUp((loanAmount + totalInterestAmt) / tenure);
};

export const onCalculateLoanPayableAmount = (
  loanAmount: number,
  processingFee: number,
  stampingFee: number,
) => {
  return loanAmount - processingFee - stampingFee;
};

export const onCalculateNetDisbursementAmount = (
  loanAmount: number,
  processingFee: number,
  stampingDutyPercentage: number,
  productStampingFeeToDeduct: number,
  loanTenure: number,
) => {
  const stampingFee = onCalculateStampingDutyFee(
    loanAmount,
    stampingDutyPercentage,
  );

  const totalGrossPremium = onCalculateTotalPremium(loanTenure);

  return (
    loanAmount -
    processingFee -
    stampingFee +
    productStampingFeeToDeduct -
    Number(totalGrossPremium)
  );
};

export const onCalculateLoanAmountYouWillReceive = (
  loanAmount: number,
  processingFee: number,
  stampingDuty: number,
  productStampingFeetToDeduct: number,
) => {
  return (
    loanAmount - processingFee - stampingDuty + productStampingFeetToDeduct
  );
};

export const onCalculateLoanAmountIncludedInterest = (
  loanAmount: number,
  interestRate: number,
  tenure: number,
) => {
  const totalInterestAmt = +onCalculateTotalInterest(
    loanAmount,
    interestRate,
    tenure,
  );
  return loanAmount + totalInterestAmt;
};

export const onCalculateLastInstalmentAmount = (
  loanAmount: number,
  interestRate: number,
  tenure: number,
) => {
  return (
    onCalculateLoanAmountIncludedInterest(loanAmount, interestRate, tenure) -
    onCalculateEstimatedMonthlyAmount(loanAmount, interestRate, tenure) *
      (tenure - 1)
  );
};

export const onCalculateTotalDueAmount = (accountDetails: any) => {
  const accountCharges = accountDetails?.accountCharges;

  const totalOtherCharges =
    +(accountCharges?.legalCharge ?? 0) +
    +(accountCharges?.bankCharge ?? 0) +
    +(accountCharges?.ecaCharge ?? 0) +
    +(accountCharges?.lodCharge ?? 0) +
    +(accountCharges?.noticesCharge ?? 0) +
    +(accountCharges?.earlySettlementCharge ?? 0) +
    +(accountCharges?.miscCharge ?? 0) +
    +(accountCharges?.refundCharge ?? 0) +
    +(accountCharges?.adminCharge ?? 0) +
    +(accountCharges?.latePaymentCharge ?? 0) +
    +(accountCharges?.fieldCharge ?? 0) +
    +(accountCharges?.storageCharge ?? 0) +
    +(accountCharges?.repoCharge ?? 0) +
    +(accountCharges?.adjustmentCharge ?? 0) +
    +(accountCharges?.reminderLetter1Charge ?? 0) +
    +(accountCharges?.reminderLetter2Charge ?? 0) +
    +(accountCharges?.reminderLetter3Charge ?? 0) +
    +(accountCharges?.finalNoticeCharge ?? 0) +
    +(accountCharges?.thirdPartyCollectCharge ?? 0) +
    +(accountCharges?.towingCharge ?? 0) +
    +(accountCharges?.fourthScheduleCharge ?? 0) +
    +(accountCharges?.fifthScheduleCharge ?? 0) +
    +(accountCharges?.blacklistUpliftCharge ?? 0);

  const overdueInstalment = Math.abs(
    +(accountDetails?.totalDueInstalment ?? 0),
  );
  const currentInstalment = +(accountDetails?.currentMonthInsBalance ?? 0);
  const totalDueAmount =
    overdueInstalment + currentInstalment + totalOtherCharges;

  return totalDueAmount;
};

export const onCalculateTotalPremium = (loanTenure: number) => {
  switch (true) {
    case loanTenure <= 24 && loanTenure > 18:
      return numberWithCommas(24.0 + 24.0 * 0.08);
    case loanTenure <= 18 && loanTenure > 12:
      return numberWithCommas(18.0 + 18.0 * 0.08);
    case loanTenure <= 12 && loanTenure > 6:
      return numberWithCommas(12.0 + 12.0 * 0.08);
    case loanTenure <= 6:
      return numberWithCommas(6.0 + 6.0 * 0.08);
    default:
      return 0;
  }
};
