import { createSlice } from "@reduxjs/toolkit";
import {
  getPreRecordedVideoBmThunk,
  getPreRecordedVideoThunk,
} from "../../services/preRecordedVideoService/preRecordedVideoThunk";

export type PreRecordedVideo = {
  id: string;
  adminId: string;
  title: string;
  createdAt?: string;
  updatedAt?: string;
};

export type PreRecordedVideoState = {
  preRecordedVideoEn: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: PreRecordedVideo[];
  };
  preRecordedVideoBm: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: PreRecordedVideo[];
  };
};

const initialState: PreRecordedVideoState = {
  preRecordedVideoEn: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
  preRecordedVideoBm: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
};

export const preRecordedVideoSlice = createSlice({
  name: "preRecordedVideo",
  initialState,
  reducers: {
    preRecordedVideoStateReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPreRecordedVideoThunk.pending, (state) => {
        state.preRecordedVideoEn.isLoading = true;
      })
      .addCase(getPreRecordedVideoThunk.fulfilled, (state, action) => {
        state.preRecordedVideoEn.isLoading = false;
        state.preRecordedVideoEn.data = action.payload;
        // let newArr = [];
        // if (action.payload.length > 0 && action.payload[0] !== null) {
        //   newArr.push(action.payload.shift());
        // }
        // state.preRecordedVideoEn.data = newArr;
      })
      .addCase(getPreRecordedVideoThunk.rejected, (state, action) => {
        state.preRecordedVideoEn.isLoading = false;
        state.preRecordedVideoEn.isError = true;
        state.preRecordedVideoEn.errorMessage = action.payload;
      });

    builder
      .addCase(getPreRecordedVideoBmThunk.pending, (state) => {
        state.preRecordedVideoBm.isLoading = true;
      })
      .addCase(getPreRecordedVideoBmThunk.fulfilled, (state, action) => {
        state.preRecordedVideoBm.isLoading = false;
        state.preRecordedVideoBm.data = action.payload;
        // let newArr = [];
        // if (action.payload.length > 0 && action.payload[0] !== null) {
        //   newArr.push(action.payload.shift());
        // }
        // state.preRecordedVideoBm.data = newArr;
      })
      .addCase(getPreRecordedVideoBmThunk.rejected, (state, action) => {
        state.preRecordedVideoBm.isLoading = false;
        state.preRecordedVideoBm.isError = true;
        state.preRecordedVideoBm.errorMessage = action.payload;
      });
  },
});

export const { preRecordedVideoStateReset } = preRecordedVideoSlice.actions;

export default preRecordedVideoSlice.reducer;
