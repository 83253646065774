import { masterSetupAxiosInstance } from "../../axios/masterSetupAxios";

const getLoanInsuranceForUser = async (requestData: any) => {
  const url = `/redCash/master/api/v1.0/loanInsurance/tenure/${requestData}`;
  const res = await masterSetupAxiosInstance.get(url);
  return res.data;
};

const loanInsuranceService = {
  getLoanInsuranceForUser,
};

export default loanInsuranceService;
