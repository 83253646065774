import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  ChangePasswordPayload,
  LoginPayload,
  RedCashLoginPayload,
  ResetPasswordPayload,
  User,
} from "../../features/user/userSlice";
import { restructureErrorMessage } from "../../helpers/errorMessage";
import userService from "./userService";

export const checkValidPhoneNoThunk = createAsyncThunk(
  "user/checkValidPhoneNo",
  async (requestData: any, thunkAPI) => {
    try {
      return await userService.checkValidPhoneNo(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const loginThunk = createAsyncThunk(
  "user/login",
  async (requestData: LoginPayload, thunkAPI) => {
    try {
      return await userService.login(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const redCashLoginThunk = createAsyncThunk(
  "user/redCashLogin",
  async (requestData: RedCashLoginPayload, thunkAPI) => {
    try {
      return await userService.redCashLogin(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const logoutThunk = createAsyncThunk(
  "user/logout",
  async (requestData, thunkAPI) => {},
);

export const getCurrentUserThunk = createAsyncThunk(
  "user/getCurrent",
  async (requestData: any, thunkAPI) => {
    try {
      return await userService.getCurrentUser(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getLatestUserDetailsThunk = createAsyncThunk(
  "user/getUserDetailsByAccessToken",
  async (requestData, thunkAPI) => {
    try {
      return await userService.getUserDetailsByAccessToken();
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createUserThunk = createAsyncThunk(
  "user/create",
  async (requestData: User, thunkAPI) => {
    try {
      return await userService.createUser(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createRedCashAccountThunk = createAsyncThunk(
  "user/createRedCashAccount",
  async (requestData: User, thunkAPI) => {
    try {
      return await userService.createRedCashAccount(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateUserThunk = createAsyncThunk(
  "user/update",
  async (requestData: any, thunkAPI) => {
    try {
      return await userService.updateUser(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateUserAuthOnlyThunk = createAsyncThunk(
  "user/update/authOnly",
  async (requestData: any, thunkAPI) => {
    try {
      return await userService.updateUserAuthOnly(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateUserEmailThunk = createAsyncThunk(
  "user/update/email",
  async (requestData: User, thunkAPI) => {
    try {
      return await userService.updateUserEmail(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const resetPasswordThunk = createAsyncThunk(
  "user/resetPassword",
  async (requestData: ResetPasswordPayload, thunkAPI) => {
    try {
      return await userService.resetPassword(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const changePasswordThunk = createAsyncThunk(
  "user/changePassword",
  async (requestData: ChangePasswordPayload, thunkAPI) => {
    try {
      return await userService.changePassword(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getTrustGateUserCertInfoThunk = createAsyncThunk(
  "user/getTrustGateUserCertInfo",
  async (requestData: any, thunkAPI) => {
    try {
      return await userService.getTrustGateUserCertInfo(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const enrolTrustGateUserCertInfoThunk = createAsyncThunk(
  "user/enrolTrustGateUserCertInfo",
  async (requestData: any, thunkAPI) => {
    try {
      return await userService.enrolTrustGateUserCertInfo(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const deleteUserThunk = createAsyncThunk(
  "user/delete",
  async (requestData: any, thunkAPI) => {
    try {
      return await userService.deleteUser(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const patchUserApplicationCreatedThunk = createAsyncThunk(
  "user/application-created",
  async (requestData: any, thunkAPI) => {
    try {
      return await userService.patchUserApplicationCreated(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const checkUserTinNoThunk = createAsyncThunk(
  "user/check-tin",
  async (requestData: any, thunkAPI) => {
    try {
      return await userService.checkUserTinNo(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const checkIsUserEmailExistedThunk = createAsyncThunk(
  "user/checkUserEmail",
  async (requestData: any, thunkAPI) => {
    try {
      return await userService.checkIsUserEmailExisted(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
