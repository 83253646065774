import "./index.less";

import { Button, Col, Row } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { createBreakpoint } from "react-use";

import homePageImage from "../../assets/images/Homepage/home-page-image-1.png";
import { AgreementStatusEnum } from "../../enums/agreementStepStatus";
import { ApplicationStatusEnum } from "../../enums/applicationStepStatus";
import { ACCOUNT_STATUS } from "../../features/collection/collectionSlice";
import { getAgreementDetailsThunk } from "../../services/agreementService/agreementThunk";
import { AppDispatch, RootState } from "../../store/store";
import LoadingSpinner from "../LoadingSpinner";
import AgreementESigningStatusContent from "./AgreementESigningStatusContent";
import IncompletedApplicationContent from "./IncompletedApplicationStatusContent";
import ApprovedStatusContent from "./LoanApplicationApprovedStatusContent";
import LoanCancelledStatusContent from "./LoanCancelledStatusContent";
import LoanCompletedStatusContent from "./LoanCompletedStatusContent";
import LoanPaymentDisbursedContent from "./LoanPaymentDisbursed";
import PendingAgreementStatusContent from "./PendingAgreementStatusContent";
import PendingApprovalStatusContent from "./PendingApprovalStatusContent";
import PendingDisbursementContent from "./PendingDisbursementContent";
import PendingLiveAttestationStatusContent from "./PendingLiveAttestationStatusContent";
import ReadyToCreateStatusContent from "./ReadyToCreateStatusContent";
import TemporaryBannedStatusContent from "./TemporaryBannedStatusContent";
import DirectDebitStatusContent from "../DirectDebitStatusContent";
import { useNavigate } from "react-router-dom";

const useBreakpoint = createBreakpoint();

export const SmeLoanApplicationStatusCard = () => {
  const navigate = useNavigate();

  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === "tablet";

  const dispatch = useDispatch<AppDispatch>();

  const { applicationDetails, cancelledApplication } = useSelector(
    (state: RootState) => state.smeApplication,
  );

  const { agreementDetails } = useSelector(
    (state: RootState) => state.smeAgreement,
  );

  const { accountEnquiry } = useSelector(
    (state: RootState) => state.collection,
  );

  const [currentStatus, setCurrentStatus] = useState(
    applicationDetails?.data?.status,
  );

  const newApplicationAvailable =
    applicationDetails?.data?.createdAt != null &&
    accountEnquiry?.data?.accountDetails?.createdAt != null &&
    dayjs(applicationDetails?.data?.createdAt).isAfter(
      dayjs(accountEnquiry?.data?.accountDetails?.createdAt),
    ) &&
    process.env.REACT_APP_SECOND_LOAN_ENABLED === "YES";

  const agreementStatusView = (status: string | undefined | null) => {
    if (
      status === AgreementStatusEnum.PENDING ||
      status === AgreementStatusEnum.PENDING_ATTESTATION
    ) {
      return <ApprovedStatusContent />;
    }

    if (status === AgreementStatusEnum.REQUESTED_FOR_LIVE_ATTESTATION) {
      return <PendingLiveAttestationStatusContent />;
    }

    // Initial flow, after attestation is e-sign, but now need add the direct debit enrollment
    if (
      status === AgreementStatusEnum.DONE_VIDEO_ATTESTATION ||
      agreementDetails.data?.status ===
        AgreementStatusEnum.DONE_LIVE_ATTESTATION ||
      agreementDetails.data?.status === AgreementStatusEnum.PENDING_SIGNING ||
      agreementDetails.data?.status === AgreementStatusEnum.RnRApproved
    ) {
      return <AgreementESigningStatusContent />;
    }

    // new flow, direct debit enrollment
    if (
      status === AgreementStatusEnum.DONE_VIDEO_ATTESTATION ||
      agreementDetails.data?.status ===
        AgreementStatusEnum.DONE_LIVE_ATTESTATION ||
      agreementDetails.data?.status === AgreementStatusEnum.RnRApproved ||
      // Will enable this flow for dev mode first
      agreementDetails.data?.status ===
        AgreementStatusEnum.DIRECT_DEBIT_ENROLLMENT
    ) {
      return <DirectDebitStatusContent />;
    }

    if (status === AgreementStatusEnum.PENDING_DISBURSEMENT) {
      return <PendingDisbursementContent />;
    }

    if (status === AgreementStatusEnum.DISBURSED) {
      return <LoanPaymentDisbursedContent />;
    }

    // For second loan application
    // TODO: handle second loan
    // if (
    //   applicationDetails?.data?.user !== null &&
    //   accountEnquiry.data.accountDetails?.status === ACCOUNT_STATUS.CLOSED
    // ) {
    //   return <AgreementESigningStatusContent />;
    // }
  };

  const currentStatusView = (status?: string) => {
    switch (status) {
      // Application Stage
      case ApplicationStatusEnum.DRAFT:
        return <ReadyToCreateStatusContent />;
      case ApplicationStatusEnum.PENDING:
        return <PendingApprovalStatusContent />;
      case ApplicationStatusEnum.REFERBACK:
      case ApplicationStatusEnum.FOLLOWED_UP:
        return <PendingApprovalStatusContent />;
      case ApplicationStatusEnum.SUBMITTED_FOR_APPROVAL:
        return <PendingApprovalStatusContent />;
      case ApplicationStatusEnum.SUBMITTED_FOR_APPROVAL_FROM_PENDING_DISBURSEMENT:
        return <PendingDisbursementContent />;

      case ApplicationStatusEnum.APPROVED:
        if (applicationDetails?.data.smeAgreementId == null) {
          return <PendingAgreementStatusContent />;
        } else {
          if (
            applicationDetails?.data?.smeAgreement === undefined ||
            applicationDetails?.data?.smeAgreement === null
          ) {
            dispatch(
              getAgreementDetailsThunk({
                agreementId: applicationDetails?.data?.smeAgreementId,
              }),
            )
              .unwrap()
              .then((res) => {
                return agreementStatusView(res.status);
              });
          } else {
            return agreementStatusView(agreementDetails?.data?.status);
          }
        }

        return <IncompletedApplicationContent />;

      case ApplicationStatusEnum.REFERBACK_FROM_PENDING_DISBURSEMENT:
        return <PendingDisbursementContent />;

      case ApplicationStatusEnum.REJECTED:
        return <TemporaryBannedStatusContent />;

      case ApplicationStatusEnum.RESCHEDULED_APPROVED:
        if (agreementDetails?.data?.status === undefined) {
          // TODO: get agreement status
          // dispatch(
          //   getAgreementDetailsThunk({
          //     agreementId: applicationDetails?.data?.agreementId,
          //   }),
          // )
          //   .unwrap()
          //   .then((res) => {
          //     return agreementStatusView(res.agreementStatus);
          //   });
        } else {
          return agreementStatusView(agreementDetails?.data?.status);
        }

        return <IncompletedApplicationContent />;
      default:
        return <LoanCancelledStatusContent />;
    }
  };

  useEffect(() => {
    if (applicationDetails?.data?.status != null) {
      setCurrentStatus(applicationDetails.data.status);
      if (agreementDetails?.data?.status) {
        setCurrentStatus(agreementDetails.data.status);
      }
    }
  }, [applicationDetails?.data, agreementDetails?.data]);

  return (
    <div
      className={
        isMobile ? "mobile-loan-summary-card" : "web-loan-summary-card"
      }
    >
      {applicationDetails?.isLoading || agreementDetails.isLoading ? (
        <Row>
          <Col span={24} className="w-full">
            <div className="p-20">
              <LoadingSpinner />
            </div>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col
            span={isMobile ? 24 : 14}
            className={
              isMobile
                ? "flex-center-vertical"
                : "web-loan-summary-card-body-left"
            }
          >
            {cancelledApplication ? (
              <LoanCancelledStatusContent />
            ) : accountEnquiry?.data?.accountDetails?.status ===
                ACCOUNT_STATUS.CLOSED && !newApplicationAvailable ? (
              <LoanCompletedStatusContent />
            ) : (
              <>
                {agreementDetails?.data?.status
                  ? agreementStatusView(currentStatus)
                  : currentStatusView(currentStatus)}
              </>
            )}
          </Col>
          {!isMobile && (
            <Col span={10} className="flex-center-vertical">
              <div className="home-page-image-spacer">
                <img
                  className="home-page-image-1"
                  src={homePageImage}
                  alt="personal finance rafiki"
                />
              </div>
            </Col>
          )}
        </Row>
      )}
    </div>
  );
};
