import { masterSetupAxiosInstance } from "../../axios/masterSetupAxios";

const getProducts = async (requestData: any) => {
  const url = `/redCash/master/api/v1.0/product/productCode`;
  const res = await masterSetupAxiosInstance.post(url, {
    productCode: requestData.productCode,
  });
  return res.data;
};

const getProductByReferralCode = async (requestData: any) => {
  const url = `/redCash/master/api/v1.0/product/company/${process.env.REACT_APP_COMPANY_ID}/referralCode`;
  const res = await masterSetupAxiosInstance.post(url, requestData);
  return res.data;
};

const applicationService = {
  getProducts,
  getProductByReferralCode,
};

export default applicationService;
