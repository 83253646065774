import { AxiosRequestConfig } from "axios";
import { beSetupAxiosInstance } from "../../axios/beSetupAxios";
import { masterSetupAxiosInstance } from "../../axios/masterSetupAxios";

const blobConfig: AxiosRequestConfig = { responseType: "blob" };
const getFileById = async (requestData: any) => {
  const { fileId } = requestData;
  const url = `/redCash/api/v1.0/file/${fileId}`;
  const res = await beSetupAxiosInstance.get(url, blobConfig);
  return res.data;
};

const getPaymentReceiptById = async (requestData: any) => {
  const { fileId } = requestData;
  const url = `/redCash/api/v1.0/payment-receipt/${fileId}`;
  const res = await beSetupAxiosInstance.get(url, blobConfig);
  return res.data;
};

const getMasterSetupFileById = async (requestData: any) => {
  const { fileId } = requestData;
  const url = `/redCash/admin/master/api/v1.0/file/${fileId}`;
  const res = await masterSetupAxiosInstance.get(url, blobConfig);
  return res.data;
};

const getMasterSetupVideoFileById = async (requestData: any) => {
  const { fileId } = requestData;
  const url = `/redCash/admin/master/api/v1.0/video/file/${fileId}`;
  const response = await masterSetupAxiosInstance.get(url, {
    headers: {
      Range: "bytes=0-",
    },
    responseType: "blob",
  });

  return response.request.responseURL;
};

const getFilesByEntityId = async (requestData: any) => {
  const { entityId } = requestData;
  const url = `/redCash/api/v1.0/file/entity/${entityId}`;
  const res = await beSetupAxiosInstance.get(url);
  return res.data;
};

const getMasterSetupFilesByEntityId = async (requestData: any) => {
  const { entityId } = requestData;

  const config = {
    onDownloadProgress: function (progressEvent: any) {
      console.log(progressEvent);
      // const percentCompleted =
      //   (progressEvent.loaded / progressEvent.total) * 100;
    },
  };

  const url = `/redCash/admin/master/api/v1.0/file/entity/${entityId}`;
  const res = await masterSetupAxiosInstance.get(url, config);
  return res.data;
};

const fileUpload = async (requestData: any) => {
  const url = `/redCash/api/v1.0/file`;
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const res = await beSetupAxiosInstance.post(url, requestData, config);
  return res.data;
};

const masterSetupFileUpload = async (requestData: any) => {
  const url = `/redCash/admin/master/api/v1.0/file`;
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const res = await masterSetupAxiosInstance.post(url, requestData, config);
  return res.data;
};

const fileDelete = async (requestData: any) => {
  const { fileId } = requestData;
  const url = `/redCash/api/v1.0/file/${fileId}`;
  const res = await beSetupAxiosInstance.delete(url);
  return res.data;
};

const fileService = {
  getFileById,
  getFilesByEntityId,
  fileUpload,
  fileDelete,
  masterSetupFileUpload,
  getMasterSetupFilesByEntityId,
  getMasterSetupFileById,
  getMasterSetupVideoFileById,
  getPaymentReceiptById,
};

export default fileService;
