import "./index.less";

import { Col, Row, Steps } from "antd";
import { useNavigate } from "react-router-dom";

import loanStatusImage from "../../../assets/images/loan-status-image.png";
import redCashLogo from "../../../assets/logo/redCASH-logo-red-transp.png";
import { FooterAbout } from "../../../components/WebFooter/FooterAbout";
import { useTranslation } from "react-i18next";

export type WebLoanStatusLayoutProps = {
  step: number;
} & {
  children: React.ReactNode;
};

export const WebSmeLoanStatusLayout: React.FC<WebLoanStatusLayoutProps> = (
  props,
) => {
  const { step, children } = props;
  const { t } = useTranslation(["common", "applicationSteps"]);
  const navigate = useNavigate();

  return (
    <Row className="web-loan-status-layout">
      <Col span={8} className="web-loan-status-left-info-panel">
        <img
          className="web-loan-status-left-info-panel-logo"
          src={redCashLogo}
          alt="redCASH logo"
          onClick={() => navigate("/")}
          style={{ cursor: "pointer" }}
        />
        <div className="web-loan-status-left-info-panel-content">
          <h4>{t("applicationSteps:Loan_Status")}</h4>
          <Steps
            current={step}
            direction="vertical"
            progressDot
            items={[
              { title: t("applicationSteps:processAgreementStep1") },
              { title: t("applicationSteps:processAgreementStep2") },
              { title: t("applicationSteps:processAgreementStep3") },
              { title: t("applicationSteps:processAgreementStep4") },
              { title: t("applicationSteps:processAgreementStep5") },
              { title: t("applicationSteps:processAgreementStep6") },
            ]}
          />
          <img src={loanStatusImage} alt="Loan Status" />
        </div>
      </Col>
      <Col span={16}>
        <div className="web-loan-status-right-panel-content">
          {children}
          <div className="m-8" />
          <FooterAbout />
        </div>
      </Col>
    </Row>
  );
};
