import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import {
  getAccountDetailsThunk,
  getAccountTenureDetailsThunk,
} from "../../services/collectionService/collectionThunk";
import { Application } from "../application/applicationSlice";

export interface AccountEnquiryResponse {
  accountDetails?: AccountDetails;
  agreementDetails?: AgreementDetails;
  tenureDetails?: TenureDetails[];
}

export interface CollectionListingResponse {
  total?: number;
  currentPage: number;
  pageSize: number;
  data?: AccountDetails[];
}

export enum ACCOUNT_STATUS {
  ACTIVE = "ACTIVE",
  CLOSED = "CLOSED",
  WRITEOFF = "WRITEOFF",
}

export interface AccountDetails {
  id: string;
  agreementId?: string;
  agreementNo?: string;
  accountCharges?: AccountCharges;
  mia?: number;
  dpd?: number;
  iia?: number;
  status?: ACCOUNT_STATUS;
  agingType?: string;
  nplFlag?: boolean;
  paidInstalmentMonth?: number;
  totalInstalmentMonth?: number;
  lastPaymentDate?: string;
  lastPaymentAmount?: number;
  firstDueDate?: string;
  maturedDate?: string;
  totalPaidInstalment?: number;
  instalmentBalance?: number;
  nextInstalmentDate?: string;
  refundAmount?: number;
  extraPaymentAmount?: number;
  monthlyInstalmentAmount?: number;
  totalDueInstalment?: number;
  billingOutstanding?: number;
  totalLoanOutstanding?: number;
  totalFinanceAmount?: number;
  disbursementAmount?: number;
  totalInterestAmount?: number;
  lastInstalmentAmount?: number;
  currentMonthInsBalance?: number;
  stampingDutyFee?: number;
  accountChargesId?: string;
  currentTenureNo?: number;
  totalPayableAmount?: number;
  createdAt?: string;
  updatedAt?: string;
  specialTaggingManual?: string;
  specialTaggingAuto?: string;
  applicantFirstName?: string;
  applicantNric?: string;
  applicantPhoneNo?: string;
  CollectionActivities?: CollectionActivity;
  user?: {
    iScore?: string;
  };
  lastPaymentChannel?: string;
}

export interface AccountCharges {
  id: string;
  accountDetailsId?: string;
  legalCharge?: number;
  bankCharge?: number;
  ecaCharge?: number;
  lodCharge?: number;
  noticesCharge?: number;
  earlySettlementCharge?: number;
  miscCharge?: number;
  refundCharge?: number;
  adminCharge?: number;
  latePaymentCharge?: number;
  fieldCharge?: number;
  storageCharge?: number;
  repoCharge?: number;
  adjustmentCharge?: number;
  reminderLetter1Charge?: number;
  reminderLetter2Charge?: number;
  reminderLetter3Charge?: number;
  finalNoticeCharge?: number;
  thirdPartyCollectCharge?: number;
  towingCharge?: number;
  fourthScheduleCharge?: number;
  fifthScheduleCharge?: number;
  blacklistUpliftCharge?: number;
  createdAt: string;
  updatedAt: string;
}

export interface CollectionActivity {
  id: string;
  actionCodeId: string;
  accountDetailsId: string;
  contactNo: string;
  contactMode: string;
  ptpDate?: string;
  ptpAmount?: string;
  nextCallDate?: string;
  nextCallTime?: string;
  status: string;
  remark: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface AgreementDetails {
  id: string;
  companyId?: string;
  agreementNo?: string;
  agreementStatus?: string;
  loanTenure?: number;
  loanInterestRate?: number;
  firstInstalmentDate?: string;
  lastInstalmentDate?: string;
  monthlyInstalmentDay?: number;
  monthlyInstalmentAmount?: number;
  lastInstalmentAmount?: number;
  loanPayableAmount?: number;
  signDate?: string;
  repaymentOption?: string;
  zipFilePassword?: string;
  applicationId?: string;
  signedAgreementFileId?: null;
  protectedSignedAgreementFileId?: null;
  eStampingCertificateFileId?: null;
  disbursementPostedDate?: null;
  disbrusementStatus?: null;
  disbursementBatchId?: null;
  stampingDate?: null;
  createdAt?: string;
  updatedAt?: string;
  application?: Application;
  policyNumber?: string;
  policyStatus?: string;
}

export interface TenureDetails {
  instalmentNo?: number;
  outstandingAmount?: number;
}

export interface CollectionState {
  accountEnquiry: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: AccountEnquiryResponse;
  };
}

const initialState: CollectionState = {
  accountEnquiry: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      accountDetails: undefined,
      agreementDetails: undefined,
      tenureDetails: undefined,
    },
  },
};

export const collectionSlice = createSlice({
  name: "collection",
  initialState,
  reducers: {
    collectionStateReset: () => initialState,
    setAccountDetails: (state, action: PayloadAction<AccountDetails>) => {
      state.accountEnquiry.data.accountDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAccountDetailsThunk.pending, (state) => {
        state.accountEnquiry.isLoading = true;
      })
      .addCase(getAccountDetailsThunk.fulfilled, (state, action) => {
        state.accountEnquiry.isLoading = false;
        state.accountEnquiry.data = action.payload;
      })
      .addCase(getAccountDetailsThunk.rejected, (state, action) => {
        state.accountEnquiry.isLoading = false;
        state.accountEnquiry.isError = true;
        state.accountEnquiry.errorMessage = action.payload;
      });

    builder
      .addCase(getAccountTenureDetailsThunk.pending, (state) => {
        state.accountEnquiry.isLoading = true;
      })
      .addCase(getAccountTenureDetailsThunk.fulfilled, (state, action) => {
        state.accountEnquiry.isLoading = false;
        state.accountEnquiry.data.tenureDetails = action.payload?.tenureDetails;
      })
      .addCase(getAccountTenureDetailsThunk.rejected, (state, action) => {
        state.accountEnquiry.isLoading = false;
        state.accountEnquiry.isError = true;
        state.accountEnquiry.errorMessage = action.payload;
      });
  },
});

export const { collectionStateReset, setAccountDetails } =
  collectionSlice.actions;

export default collectionSlice.reducer;
