import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Skeleton,
  Space,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AgreementStatusEnum } from "../../enums/agreementStepStatus";
import { ApplicationStatusEnum } from "../../enums/applicationStepStatus";
import { useDebounce } from "../../hooks/useDebounceHook";
import { getApplicationDetailsThunk } from "../../services/applicationService/applicationThunk";
import { getCityStateByPostcodeThunk } from "../../services/locationService/locationThunk";
import {
  checkUserTinNoThunk,
  getCurrentUserThunk,
  updateUserEmailThunk,
  updateUserThunk,
} from "../../services/userService/userThunk";
import { AppDispatch, RootState } from "../../store/store";
import { IS_DEVELOPMENT_MODE } from "../../utils/environment";
import { styleBrandLabel } from "../../utils/stringFormatter";
import { phoneNumberValidator } from "../../utils/validation";
import OTPModal from "../modals/OTPModal";
import { useTranslation } from "react-i18next";

export const UpdateEInvoiceTinForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [postcode, setPostcode] = useState<string>("");
  const [validPostcode, setValidPostcode] = useState<boolean>(true);
  const debouncedPostcode = useDebounce<string>(postcode, 500);

  const { currentUser } = useSelector((state: RootState) => state.user);
  const { applicationDetails } = useSelector(
    (state: RootState) => state.application,
  );
  const { agreementDetails } = useSelector(
    (state: RootState) => state.agreement,
  );

  const [checkingTinNumber, setCheckingTinNumber] = useState<boolean>(false);
  const [disableTinField, setDisableTinField] = useState<boolean>(
    currentUser?.data?.tinNumber !== null &&
      currentUser?.data?.tinNumber !== "",
  );

  const onValidateTin = () => {
    setCheckingTinNumber(true);
    dispatch(
      checkUserTinNoThunk({
        tinNumber: form.getFieldValue("tinNumber"),
        userId: currentUser?.data?.id,
      }),
    )
      .unwrap()
      .then((res) => {
        if (res) {
          setDisableTinField(true);
          message.success("TIN is valid");
        } else {
          message.error("TIN is invalid");
        }
      })
      .catch(() => {
        message.error("TIN is invalid");
      })
      .finally(() => {
        setCheckingTinNumber(false);
      });
  };

  const { t } = useTranslation(["common", "updateTin"]);

  const fetchCurrentUser = () => {
    dispatch(getCurrentUserThunk({ userId: currentUser?.data?.id }));
  };

  const fetchApplicationDetails = () => {
    dispatch(
      getApplicationDetailsThunk({
        applicationId: applicationDetails.data?.id,
      }),
    );
  };

  const initialCallbackState = {
    fetchCurrentUser,
    fetchApplicationDetails,
  };
  const [callbackState] = useState(initialCallbackState);

  useEffect(() => {
    callbackState.fetchCurrentUser();
    callbackState.fetchApplicationDetails();
  }, [callbackState]);

  return (
    <div className="mx-4 mb-4">
      {currentUser.isLoading || loading ? (
        <Skeleton />
      ) : (
        <Form
          form={form}
          name="tinNumber"
          size="large"
          requiredMark={false}
          layout="vertical"
          scrollToFirstError
        >
          <Form.Item label={"TIN"}>
            <Row>
              <Col span={24}>
                <div style={{ height: "70px" }}>
                  <Input
                    disabled={disableTinField}
                    placeholder={"TIN"}
                    defaultValue={currentUser?.data?.tinNumber}
                    onChange={(e) =>
                      form.setFieldsValue({ tinNumber: e.target.value })
                    }
                  />
                </div>
              </Col>
            </Row>
            {!disableTinField && (
              <Row justify="center" align="middle">
                <Col>
                  <Button
                    loading={checkingTinNumber}
                    disabled={disableTinField}
                    onClick={() => onValidateTin()}
                  >
                    Validate
                  </Button>
                </Col>
              </Row>
            )}
          </Form.Item>
        </Form>
      )}
    </div>
  );
};
