import "./index.less";

import { Button, Form, Input, InputRef, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { LoadingOutlined } from "@ant-design/icons";

import {
  CreateRedCashAccountRequest,
  setCurrentUser,
  setHasLoggedUser,
  setRegistrationFormData,
} from "../../features/user/userSlice";
import { createRedCashAccountThunk } from "../../services/userService/userThunk";
import { AppDispatch, RootState } from "../../store/store";

export const SmeAccountCreationForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [passwordMatch, setPasswordMatch] = useState(false);

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const { t } = useTranslation(["common", "registration"]);

  const { registrationFormData } = useSelector(
    (state: RootState) => state.user,
  );
  const { currentUser } = useSelector((state: RootState) => state.user);

  const onValuesChange = (changedValues: any, allValues: any) => {
    // update form values in redux
    dispatch(setRegistrationFormData(allValues));
  };

  const onFinish = async () => {
    await form
      .validateFields()
      .then((values) => {
        setLoading(true);
        const payload: CreateRedCashAccountRequest = {
          password: values.password,
          id: currentUser?.data?.id,
        };

        dispatch(createRedCashAccountThunk(payload))
          .unwrap()
          .then(async (res) => {
            message.success(t("registration:submitSuccessfulMsg"));
            await dispatch(setHasLoggedUser(true));
            await dispatch(setCurrentUser(res));
            // navigate(`/loan-status/digital-signing`);
            navigate(`/loan-status/loan-agreement`);
          })
          .finally(() => setLoading(false));
      })
      .catch(() => {
        message.error(t("registration:filedsRequiredMsg"));
        setLoading(false);
      });
  };

  const validateConfirmPassword = ({ getFieldValue }: any) => ({
    validator(_: any, value: string) {
      if (!value || getFieldValue("password") === value) {
        setPasswordMatch(true);
        return Promise.resolve();
      }
      setPasswordMatch(false);
      return Promise.reject(new Error(t("registration:passwordNotMatchMsg")));
    },
  });

  useEffect(() => {
    // skip account creation if user already has redcash account
    if (
      currentUser?.data?.redCashPasswordHash &&
      currentUser?.data?.redCashPasswordSalt
    ) {
      navigate(`/loan-status/loan-agreement`);
    }

    form.setFieldsValue({
      password: null,
      confirmPassword: null,
    });
    setPasswordMatch(false);
  }, []);

  return (
    <div className="registration-form">
      <Form
        form={form}
        size="large"
        requiredMark={false}
        layout="vertical"
        onValuesChange={onValuesChange}
        onFinish={onFinish}
        initialValues={{
          ...registrationFormData,
          ...currentUser?.data,
          prefix: "+60",
        }}
        scrollToFirstError
      >
        <Form.Item
          label={t("registration:emailLabel")}
          name={"email"}
          rules={[
            {
              required: true,
              message: t("registration:emailRequiredMsg"),
            },
          ]}
        >
          <Input
            disabled={true}
            placeholder={t("registration:emailPlaceholder")}
            onInput={(e) =>
              ((e.target as HTMLInputElement).value = (
                e.target as HTMLInputElement
              ).value.toLowerCase())
            }
          />
        </Form.Item>

        <Form.Item
          label={t("registration:passwordLabel")}
          name="password"
          rules={[
            {
              required: true,
              message: t("registration:passwordRequiredMsg"),
            },
            {
              min: 8,
              message: t("registration:passwordInvalidMsg"),
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label={t("registration:confirmPasswordLabel")}
          name="confirmPassword"
          dependencies={["password"]}
          rules={[
            {
              required: true,
              message: t("registration:confirmPasswordRequiredMsg"),
            },
            validateConfirmPassword,
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item style={{ padding: "8px 0 32px" }}>
          <button
            type="submit"
            className="primary-button"
            disabled={loading || !passwordMatch}
            onClick={onFinish}
          >
            {loading && (
              <LoadingOutlined style={{ fontSize: 28, marginRight: 8 }} />
            )}
            {t("registration:Next")}
          </button>
        </Form.Item>
      </Form>
      <Button onClick={() => navigate("/sme/loan-status/loan-agreement")}>
        Proceed next
      </Button>
    </div>
  );
};
