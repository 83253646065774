import "./index.less";

import { Result } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

import { dangerIconRed, successIconGreen } from "../../assets/style/variables";
import {
  resetCurrentUser,
  setHasLoggedUser,
} from "../../features/user/userSlice";
import { deleteUserThunk } from "../../services/userService/userThunk";
import { AppDispatch, RootState } from "../../store/store";
import { startCase } from "../../utils/stringFormatter";

const SmeEligibilityResultContent = () => {
  const { result } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation(["common", "eligibilityResult"]);

  const { currentUser } = useSelector((state: RootState) => state.user);

  const deleteUserById = () => {
    dispatch(deleteUserThunk({ userId: currentUser?.data?.id }));
  };

  const backToRegistrationPage = async () => {
    await dispatch(setHasLoggedUser(false));
    navigate(`/registration`);
  };

  const initialCallbackState = { deleteUserById };
  const [callbackState] = useState(initialCallbackState);

  useEffect(() => {
    if (currentUser?.data?.id) {
      if (result !== "success") {
        callbackState.deleteUserById();
        dispatch(resetCurrentUser());
      }
    }
  }, [callbackState, currentUser?.data?.id, result]);

  return (
    <div className="eligibility-result">
      <Result
        icon={
          result === "success" ? (
            <CheckCircleOutlined style={{ color: successIconGreen }} />
          ) : (
            <CloseCircleOutlined style={{ color: dangerIconRed }} />
          )
        }
        subTitle={
          result === "success" ? (
            <h3>
              {t("eligibilityResult:title1")}
              <br />
              {t("eligibilityResult:title2")}
            </h3>
          ) : (
            <>
              <br />
              <h4 className="text-lg text-left">{t("eligibilityResult:p1")}</h4>
              <br />
              <div className="flex justify-center">
                <ul className="text-lg font-semibold text-black text-left">
                  <li>{t("eligibilityResult:reason1")}</li>
                  <li>{t("eligibilityResult:reason2")}</li>
                  <li>{t("eligibilityResult:reason3")}</li>
                  <li>{t("eligibilityResult:reason4")}</li>
                  <li>{t("eligibilityResult:reason5")}</li>
                </ul>
              </div>
              <br />
              <h4 className="text-lg">
                {t("eligibilityResult:contactUs")}
                <a href="mailto: careline@redone.com.my">
                  careline@redone.com.my
                </a>
              </h4>
            </>
          )
        }
        extra={
          <button
            className="primary-button"
            onClick={() => {
              result === "success"
                ? navigate(`/sme/home`, { replace: true })
                : backToRegistrationPage();
            }}
          >
            {result === "success"
              ? startCase(t("eligibilityResult:continue"))
              : startCase(t("eligibilityResult:ok"))}
          </button>
        }
      />
    </div>
  );
};

export default SmeEligibilityResultContent;
