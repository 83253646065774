import { PageHeader } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ApplicationSummaryContent } from "../../../components/ApplicationSummaryContent";
import { WebApplyApplicationLayout } from "../../../layouts/web/WebApplyApplicationLayout";
import { ORGANIZATION_TYPE } from "../../../enums/partnerType";
import { SmeApplicationSummaryContent } from "../../../components/SmeApplicationSummaryContent";

const WebSmeApplicationSummaryPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["common", "applicationSummary"]);

  return (
    <WebApplyApplicationLayout step={3}>
      <div className="max-w-xl w-full px-4 mx-auto">
        <PageHeader
          style={{ padding: 12 }}
          onBack={() => navigate(-1)}
          title={<strong>{t("applicationSummary:pageTitle")}</strong>}
        />
        <SmeApplicationSummaryContent />
      </div>
    </WebApplyApplicationLayout>
  );
};

export default WebSmeApplicationSummaryPage;
