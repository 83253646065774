import "./index.less";

import { Card, Skeleton, Steps } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createBreakpoint } from "react-use";

import loanProgressIcon1 from "../../assets/icons/loan-progress-1.png";
import loanProgressIcon2 from "../../assets/icons/loan-progress-2.png";
import loanProgressIcon3 from "../../assets/icons/loan-progress-3.png";
import loanProgressIcon4 from "../../assets/icons/loan-progress-4.png";
import { AgreementStatusEnum } from "../../enums/agreementStepStatus";
import { getAgreementStepStatusThunk } from "../../services/agreementService/agreementThunk";
import { AppDispatch, RootState } from "../../store/store";
import { startCase } from "../../utils/stringFormatter";

const useBreakpoint = createBreakpoint();

export const LoanStatusCard = () => {
  const dispatch = useDispatch<AppDispatch>();
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === "tablet";

  const { t } = useTranslation(["common", "loanStatus"]);

  const [currentStep, setCurrentStep] = useState(0);

  const { agreementDetails, agreementStepStatus } = useSelector(
    (state: RootState) => state.agreement,
  );

  const currentStepDesc = (step: number) => {
    if (currentStep > step) return startCase(t("loanStatus:Completed"));
    if (currentStep === step)
      return startCase(t("loanStatus:Current_Progress"));
    if (currentStep < step) return startCase(t("loanStatus:Waiting"));
  };

  const stepItems = [
    {
      title: t("loanStatus:step1Title"),
      description: currentStepDesc(0),
      image: loanProgressIcon1,
    },
    {
      title: t("loanStatus:step2Title"),
      description: currentStepDesc(1),
      image: loanProgressIcon2,
    },
    {
      title: t("loanStatus:step3Title"),
      description: currentStepDesc(2),
      image: loanProgressIcon3,
    },
    {
      title: t("loanStatus:step4Title"),
      description: currentStepDesc(3),
      image: loanProgressIcon4,
    },
  ];

  const checkCurrentStep = () => {
    if (agreementDetails.data != null) {
      if (
        agreementStepStatus.data.find(
          (step) => step.code === AgreementStatusEnum.LOANAPPLICATIONAPPROVED,
        )?.status === false
      ) {
        setCurrentStep(0);
      }
      if (
        agreementStepStatus.data.find(
          (step) => step.code === AgreementStatusEnum.ATTESTATIONOPTION,
        )?.status === false
      ) {
        setCurrentStep(1);
      }
      if (
        agreementStepStatus.data.find(
          (step) => step.code === AgreementStatusEnum.ATTESTATIONCOMPLETED,
        )?.status === true &&
        agreementStepStatus.data.find(
          (step) => step.code === AgreementStatusEnum.AGREEMENTSIGNING,
        )?.status === false
      ) {
        setCurrentStep(3);
      }
      if (
        agreementStepStatus.data.find(
          (step) => step.code === AgreementStatusEnum.AGREEMENTSIGNING,
        )?.status === true &&
        agreementStepStatus.data.find(
          (step) => step.code === AgreementStatusEnum.DISBURSED,
        )?.status === false
      ) {
        setCurrentStep(4);
      }
    }
  };

  useEffect(() => {
    if (agreementDetails?.data?.id != null)
      dispatch(
        getAgreementStepStatusThunk({
          agreementId: agreementDetails?.data?.id,
        }),
      );
  }, [
    agreementDetails?.data?.id,
    agreementDetails?.data?.agreementStatus,
    dispatch,
  ]);

  useEffect(() => {
    checkCurrentStep();
  }, [agreementStepStatus.data]);

  return (
    <div
      className={
        isMobile
          ? "loan-app-status-container-vertical"
          : "loan-app-status-container-horizontal"
      }
    >
      <h3 className="section-title">{t("loanStatus:Agreement_Steps")}</h3>
      <Card>
        {agreementDetails.isLoading ? (
          <>
            <Skeleton paragraph={{ rows: 1 }} avatar active />
            <Skeleton paragraph={{ rows: 1 }} avatar active />
            <Skeleton paragraph={{ rows: 1 }} avatar active />
            <Skeleton paragraph={{ rows: 1 }} avatar active />
          </>
        ) : (
          <div
            className={isMobile ? "app-steps-vertical" : "app-steps-horizontal"}
          >
            {!isMobile && (
              <div className={"loan-app-status-icon-group-horizontal"}>
                {Array.isArray(stepItems) &&
                  stepItems.map((item, index) => (
                    <img
                      src={item.image}
                      className={"loan-app-status-icon-horizontal"}
                      key={index}
                      alt="icon"
                    />
                  ))}
              </div>
            )}
            <Steps
              className="loan-status-step"
              current={currentStep}
              items={stepItems}
              direction={isMobile ? "vertical" : "horizontal"}
              progressDot
            />
          </div>
        )}
      </Card>
    </div>
  );
};
