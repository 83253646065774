import "./index.less";

import { Modal, Tabs } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { createBreakpoint } from "react-use";

import { MailOutlined, MobileOutlined } from "@ant-design/icons";

import RedCashLoginForm from "./RedCashLoginForm";
import SSOLoginForm from "./SSOLoginForm";

import type { TabsProps } from "antd";

const useBreakpoint = createBreakpoint();

export type LoginModalProps = {
  defaultLoginType?: string;
  buttonClass?: string;
  buttonTitle?: string;
  defaultShowModal?: boolean; // default value for isModalOpen
  hideButton?: boolean;
  callback?: any;
};

const LoginModal: React.FC<LoginModalProps> = (props) => {
  const {
    defaultLoginType,
    buttonClass,
    buttonTitle,
    hideButton,
    defaultShowModal,
    callback,
  } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation(["common", "login"]);
  const queryParams = new URLSearchParams(location.search);
  const loginType = queryParams.get("loginType");
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === "tablet";

  const showLoginSearchParam = new URLSearchParams(location.search).get(
    "showLogin",
  );

  const [isModalOpen, setIsModalOpen] = useState(
    defaultShowModal ||
      showLoginSearchParam === "true" ||
      showLoginSearchParam === "1",
  );

  const items: TabsProps["items"] = [
    {
      key: "redcash",
      label: (
        <span>
          <MailOutlined /> {t("login:Login_Using_redCASH_Account")}
        </span>
      ),
      children: <RedCashLoginForm callback={callback} />,
    },
    {
      key: "sso",
      label: (
        <span>
          <MobileOutlined /> {t("login:redONE_Customer")}
        </span>
      ),
      children: <SSOLoginForm callback={callback} />,
    },
  ];

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    navigate("/");
    setIsModalOpen(false);
    if (callback) {
      callback();
    }
  };

  const onChange = (key: string) => {
    console.log(key);
  };

  return (
    <>
      {hideButton ?? (
        <button
          className={buttonClass ?? "apply-button"}
          style={isMobile ? { width: "100%" } : {}}
          onClick={showModal}
        >
          {buttonTitle ?? t("login:Apply_Now")}
        </button>
      )}
      <Modal
        centered
        title={null}
        className="login-modal"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={580}
      >
        {defaultLoginType === "sso" ? (
          <SSOLoginForm callback={callback} />
        ) : (
          <Tabs
            defaultActiveKey={loginType ?? defaultLoginType ?? "redcash"}
            onChange={onChange}
            items={items}
            centered
          />
        )}
      </Modal>
    </>
  );
};

export default LoginModal;
