import "./MobileSmeApprovedLoanSummaryPage.less";

import { useNavigate } from "react-router-dom";

import { ApprovedLoanSummaryContent } from "../../../components/ApprovedLoanSummaryContent";
import { MobileTopNavHeaderLayout } from "../../../layouts/mobile/MobileTopNavHeaderLayout/MobileTopNavHeaderLayout";
import { useTranslation } from "react-i18next";
import { SmeApprovedLoanSummaryContent } from "../../../components/SmeApprovedLoanSummaryContent";

const MobileSmeApprovedLoanSummaryPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["common", "approvedLoanSummary"]);

  return (
    <MobileTopNavHeaderLayout
      title={t("approvedLoanSummary:pageTitle")}
      onBack={() => navigate(`/home`)}
    >
      <SmeApprovedLoanSummaryContent showLoanSummary={true} />
    </MobileTopNavHeaderLayout>
  );
};

export default MobileSmeApprovedLoanSummaryPage;
