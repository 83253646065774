import { Alert, Space } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { createBreakpoint } from "react-use";

import { numberWithCommas } from "../../../helpers/number";
import { RootState } from "../../../store/store";

const useBreakpoint = createBreakpoint();

const PendingAgreementStatusContent = () => {
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === "tablet";
  const { t } = useTranslation(["common", "loanStatus"]);

  const { applicationDetails } = useSelector(
    (state: RootState) => state.application,
  );
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <div
        className="flex-center-vertical"
        style={{ alignItems: isMobile ? "center" : "flex-start" }}
      >
        <span>{t("loanStatus:Your_Requested_Loan_Amount")}</span>
        <h1>
          RM {numberWithCommas(applicationDetails?.data?.financeAmount ?? 0)}
        </h1>
      </div>
      <Alert
        className="alert-card"
        style={{ textAlign: isMobile ? "center" : "left" }}
        message={
          <h3 className="alert-title-info">
            {t("loanStatus:pending_agreement_title")}
          </h3>
        }
        description={
          <p style={{ margin: 0 }}>
            {t("loanStatus:pending_agreement_description")}
          </p>
        }
        type="info"
      />
    </Space>
  );
};

export default PendingAgreementStatusContent;
