import "./index.less";

import { Carousel, Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import eligibilityRequirements1 from "../../../assets/images/EligibilityRequirements/eligibility-requirements-1.png";
import eligibilityRequirements2 from "../../../assets/images/EligibilityRequirements/eligibility-requirements-2.png";
import eligibilityRequirements3 from "../../../assets/images/EligibilityRequirements/eligibility-requirements-3.png";
import redCashLogo from "../../../assets/logo/redCASH-logo-based-red-transp.png";
import { FooterAbout } from "../../../components/WebFooter/FooterAbout";

export const WebRegistrationLayout = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["common", "registration"]);

  return (
    <Row className="web-registration-layout">
      <Col span={8} className="left-info-panel">
        <img
          className="left-info-panel-logo"
          src={redCashLogo}
          alt="redCASH logo"
          onClick={() => navigate("/home")}
        />
        <div className="left-info-panel-content">
          <h2>{t("registration:title")}</h2>
          <Carousel
            className="eligibility-requirements-carousel"
            effect="fade"
            autoplay
          >
            <div className="eligibility-requirements-carousel-content">
              <img
                src={eligibilityRequirements1}
                alt="eligibility requirements 1"
              />
              <p>{t("registration:requirements1")}</p>
            </div>
            <div className="eligibility-requirements-carousel-content">
              <img
                src={eligibilityRequirements2}
                alt="eligibility requirements 2"
              />
              <p>{t("registration:requirements2")}</p>
            </div>
            <div className="eligibility-requirements-carousel-content">
              <img
                src={eligibilityRequirements3}
                alt="eligibility requirements 3"
              />
              <p>{t("registration:requirements3")}</p>
            </div>
            <div className="eligibility-requirements-carousel-content">
              <img
                src={eligibilityRequirements2}
                alt="eligibility requirements 4"
              />
              <p>{t("registration:requirements4")}</p>
            </div>
          </Carousel>
        </div>
      </Col>
      <Col span={16}>
        <div className="right-panel-content">
          {children}
          <FooterAbout />
        </div>
      </Col>
    </Row>
  );
};
