import { beSetupAxiosInstance } from "../../axios/beSetupAxios";

const getLoanHistory = async () => {
  const url = `/redCash/api/v1.0/agreement/me`;
  const res = await beSetupAxiosInstance.get(url);
  return res.data;
};

const loanHistoryService = {
  getLoanHistory,
};

export default loanHistoryService;
