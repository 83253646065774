import "./index.less";

import { Collapse, Skeleton } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { numberWithCommas } from "../../helpers/number";
import { getAccountDetailsThunk } from "../../services/collectionService/collectionThunk";
import { AppDispatch, RootState } from "../../store/store";
import { displayDate } from "../../utils/datetime.util";

const { Panel } = Collapse;

export const RepaymentDetails = () => {
  const { t } = useTranslation(["common", "repaymentDetails"]);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { applicationDetails } = useSelector(
    (state: RootState) => state.application,
  );
  const { accountEnquiry } = useSelector(
    (state: RootState) => state.collection,
  );

  const { accountDetails, agreementDetails, tenureDetails } =
    accountEnquiry.data;

  const accountCharges = accountDetails?.accountCharges;

  const totalOtherCharges =
    +(accountCharges?.legalCharge ?? 0) +
    +(accountCharges?.bankCharge ?? 0) +
    +(accountCharges?.ecaCharge ?? 0) +
    +(accountCharges?.lodCharge ?? 0) +
    +(accountCharges?.noticesCharge ?? 0) +
    +(accountCharges?.earlySettlementCharge ?? 0) +
    +(accountCharges?.miscCharge ?? 0) +
    +(accountCharges?.refundCharge ?? 0) +
    +(accountCharges?.adminCharge ?? 0) +
    +(accountCharges?.latePaymentCharge ?? 0) +
    +(accountCharges?.fieldCharge ?? 0) +
    +(accountCharges?.storageCharge ?? 0) +
    +(accountCharges?.repoCharge ?? 0) +
    +(accountCharges?.adjustmentCharge ?? 0) +
    +(accountCharges?.reminderLetter1Charge ?? 0) +
    +(accountCharges?.reminderLetter2Charge ?? 0) +
    +(accountCharges?.reminderLetter3Charge ?? 0) +
    +(accountCharges?.finalNoticeCharge ?? 0) +
    +(accountCharges?.thirdPartyCollectCharge ?? 0) +
    +(accountCharges?.towingCharge ?? 0) +
    +(accountCharges?.fourthScheduleCharge ?? 0) +
    +(accountCharges?.fifthScheduleCharge ?? 0) +
    +(accountCharges?.blacklistUpliftCharge ?? 0);

  const overdueInstalment = Math.abs(
    +(accountDetails?.totalDueInstalment ?? 0),
  );
  const currentInstalment = +(accountDetails?.currentMonthInsBalance ?? 0);
  const totalDueAmount =
    overdueInstalment + currentInstalment + totalOtherCharges;

  useEffect(() => {
    if (applicationDetails?.data?.agreement?.agreementNo)
      dispatch(
        getAccountDetailsThunk({
          agreementNo: applicationDetails?.data?.agreement?.agreementNo,
        }),
      );
  }, [applicationDetails?.data?.agreement?.agreementNo, dispatch]);

  return (
    <div className="flex justify-center items-center bg-white rounded shadow px-4 py-2 w-full">
      {accountEnquiry?.isLoading || applicationDetails?.isLoading ? (
        <Skeleton className="px-4 py-8" active />
      ) : (
        <div className="max-w-4xl w-full mx-auto text-center">
          <Collapse
            bordered={false}
            expandIconPosition="end"
            className="headerCollapse"
            // defaultActiveKey={["1"]}
          >
            <Panel
              // showArrow={false}
              header={
                <h2 className="font-bold text-lg mb-0">
                  Loan Agreement:{" "}
                  {applicationDetails.data.agreement?.agreementNo}
                </h2>
              }
              key="1"
            >
              <div>
                <div className="flex justify-between items-center mb-2">
                  <span>{t("repaymentDetails:First_Instalment_Date")}</span>
                  <span>
                    {agreementDetails?.firstInstalmentDate != null
                      ? displayDate(agreementDetails?.firstInstalmentDate)
                      : "-"}
                  </span>
                </div>
                <div className="flex justify-between items-center mb-2">
                  <span>{t("repaymentDetails:Maturity_Date")}</span>
                  <span>
                    {accountDetails?.maturedDate != null
                      ? displayDate(accountDetails?.maturedDate)
                      : "-"}
                  </span>
                </div>
                <div className="flex justify-between items-center mb-2">
                  <span>{t("repaymentDetails:Interest_Amount")}</span>
                  <span>
                    {accountDetails?.totalInterestAmount != null
                      ? `RM ${numberWithCommas(
                          accountDetails?.totalInterestAmount,
                        )}`
                      : "-"}
                  </span>
                </div>
                <div className="flex justify-between items-center mb-2">
                  <span>{t("repaymentDetails:Interest_Rate")}</span>
                  <span>
                    {agreementDetails?.loanInterestRate != null
                      ? `${agreementDetails?.loanInterestRate}%`
                      : "-"}
                  </span>
                </div>
                <div className="flex justify-between items-center mb-2">
                  <span>{t("repaymentDetails:Instalment_Period")}</span>
                  <span>
                    {agreementDetails?.loanTenure != null
                      ? `${agreementDetails?.loanTenure} 
                      ${t("repaymentDetails:months")}`
                      : "-"}
                  </span>
                </div>
                <div className="flex justify-between items-center mb-2">
                  <span>{t("repaymentDetails:Total_Instalment_Amount")}</span>
                  <span>
                    {accountDetails?.totalPayableAmount != null
                      ? `RM ${numberWithCommas(
                          accountDetails?.totalPayableAmount,
                        )}`
                      : "-"}
                  </span>
                </div>
                <div className="flex justify-between items-center mb-2">
                  <span>
                    {t("repaymentDetails:Loan_Protection_Personal_Accident")}
                  </span>
                  <span>
                    {agreementDetails?.policyStatus === "SUCCESS"
                      ? agreementDetails.policyNumber
                      : "-"}
                  </span>
                </div>
              </div>
            </Panel>
          </Collapse>

          <h3 className="font-bold text-lg mb-2 text-left underline">
            {t("repaymentDetails:sectionTitle")}
          </h3>
          <div>
            <div className="flex justify-between items-center mb-2">
              <span>{t("repaymentDetails:Loan_Amount")}</span>
              <span>
                {applicationDetails?.data?.loanApplied != null
                  ? `RM ${numberWithCommas(
                      accountEnquiry.data.accountDetails?.totalFinanceAmount,
                    )}`
                  : "-"}
              </span>
            </div>
            <div className="flex justify-between items-center mb-2">
              <span>{t("repaymentDetails:Loan_Outstanding_Balance")}</span>
              <span>
                {accountDetails?.totalLoanOutstanding != null
                  ? `RM ${numberWithCommas(
                      Math.abs(accountDetails?.totalLoanOutstanding),
                    )}`
                  : "-"}
              </span>
            </div>
            <div className="flex justify-between items-center mb-2">
              <span>{t("repaymentDetails:Monthly_Instalment_Amount")}</span>
              <span>
                {accountDetails?.monthlyInstalmentAmount != null
                  ? `RM ${numberWithCommas(
                      accountDetails?.monthlyInstalmentAmount,
                    )}`
                  : "-"}
              </span>
            </div>
            <div className="flex justify-between items-center mb-2">
              <span>{t("repaymentDetails:Instalment_Due_Date")}</span>
              <span>
                {accountDetails?.firstDueDate != null ||
                accountDetails?.nextInstalmentDate != null
                  ? displayDate(
                      accountDetails?.nextInstalmentDate ??
                        accountDetails?.firstDueDate,
                    )
                  : "-"}
              </span>
            </div>

            <Collapse
              bordered={false}
              // expandIconPosition="end"
              // defaultActiveKey={["1"]}
              className="customCollapse"
            >
              <Panel
                // showArrow={false}
                header={
                  <div className="flex justify-between items-center gap-4">
                    <span>{t("repaymentDetails:Pay_Now")}</span>
                    <span className="font-bold">
                      {totalDueAmount != null
                        ? `RM ${numberWithCommas(totalDueAmount)}`
                        : "-"}
                    </span>
                  </div>
                }
                key="1"
              >
                <div className="ml-2">
                  <div className="flex justify-between items-center mb-2">
                    <span>{t("repaymentDetails:Overdue_Instalment")}</span>
                    <span>
                      {overdueInstalment != null
                        ? `RM ${numberWithCommas(overdueInstalment)}`
                        : "-"}
                    </span>
                  </div>
                  <div className="flex justify-between items-center mb-2">
                    <span>{t("repaymentDetails:Current_Instalment")}</span>
                    <span>
                      {currentInstalment != null
                        ? `RM ${numberWithCommas(currentInstalment)}`
                        : "-"}
                    </span>
                  </div>

                  <div className="flex justify-between items-center mb-2">
                    <span>{t("repaymentDetails:Other_Charges")}</span>
                    <span>
                      {totalOtherCharges != null
                        ? `RM ${numberWithCommas(totalOtherCharges)}`
                        : "-"}
                    </span>
                  </div>
                </div>
              </Panel>
            </Collapse>
          </div>
          <div className="flex justify-center my-4">
            <button
              className="primary-button max-w-xs"
              onClick={() => navigate(`/pay-instalment-now`)}
            >
              {t("payInstalmentNow:Pay_Now")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
