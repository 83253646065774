import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import common_EN from "./assets/i18n/common/en.json";
import common_MS from "./assets/i18n/common/ms.json";

import aboutUs_EN from "./assets/i18n/aboutUs/en.json";
import aboutUs_MS from "./assets/i18n/aboutUs/ms.json";

import applicationForm_EN from "./assets/i18n/applicationForm/en.json";
import applicationForm_MS from "./assets/i18n/applicationForm/ms.json";

import applicationSigningComplete_EN from "./assets/i18n/applicationSigningComplete/en.json";
import applicationSigningComplete_MS from "./assets/i18n/applicationSigningComplete/ms.json";

import applicationSummary_EN from "./assets/i18n/applicationSummary/en.json";
import applicationSummary_MS from "./assets/i18n/applicationSummary/ms.json";

import applicationSteps_EN from "./assets/i18n/applicationSteps/en.json";
import applicationSteps_MS from "./assets/i18n/applicationSteps/ms.json";

import approvedLoanSummary_EN from "./assets/i18n/approvedLoanSummary/en.json";
import approvedLoanSummary_MS from "./assets/i18n/approvedLoanSummary/ms.json";

import attestationOption_EN from "./assets/i18n/attestationOption/en.json";
import attestationOption_MS from "./assets/i18n/attestationOption/ms.json";

import contactUs_EN from "./assets/i18n/contactUs/en.json";
import contactUs_MS from "./assets/i18n/contactUs/ms.json";

import digitalSigning_EN from "./assets/i18n/digitalSigning/en.json";
import digitalSigning_MS from "./assets/i18n/digitalSigning/ms.json";

import documentUpload_EN from "./assets/i18n/documentUpload/en.json";
import documentUpload_MS from "./assets/i18n/documentUpload/ms.json";

import draftApplication_EN from "./assets/i18n/draftApplication/en.json";
import draftApplication_MS from "./assets/i18n/draftApplication/ms.json";

import eligibilityResult_EN from "./assets/i18n/eligibilityResult/en.json";
import eligibilityResult_MS from "./assets/i18n/eligibilityResult/ms.json";

import emailVerification_EN from "./assets/i18n/emailVerification/en.json";
import emailVerification_MS from "./assets/i18n/emailVerification/ms.json";

import faq_EN from "./assets/i18n/faq/en.json";
import faq_MS from "./assets/i18n/faq/ms.json";

import featuresAndBenefits_EN from "./assets/i18n/featuresAndBenefits/en.json";
import featuresAndBenefits_MS from "./assets/i18n/featuresAndBenefits/ms.json";

import home_EN from "./assets/i18n/home/en.json";
import home_MS from "./assets/i18n/home/ms.json";

import identityVerification_EN from "./assets/i18n/identityVerification/en.json";
import identityVerification_MS from "./assets/i18n/identityVerification/ms.json";

import landing_EN from "./assets/i18n/landing/en.json";
import landing_MS from "./assets/i18n/landing/ms.json";

import legalTerms_EN from "./assets/i18n/legalTerms/en.json";
import legalTerms_MS from "./assets/i18n/legalTerms/ms.json";

import liveCallAttestation_EN from "./assets/i18n/liveCallAttestation/en.json";
import liveCallAttestation_MS from "./assets/i18n/liveCallAttestation/ms.json";

import loanAgreement_EN from "./assets/i18n/loanAgreement/en.json";
import loanAgreement_MS from "./assets/i18n/loanAgreement/ms.json";

import loanHistory_EN from "./assets/i18n/loanHistory/en.json";
import loanHistory_MS from "./assets/i18n/loanHistory/ms.json";

import loanHistoryDetails_EN from "./assets/i18n/loanHistoryDetails/en.json";
import loanHistoryDetails_MS from "./assets/i18n/loanHistoryDetails/ms.json";

import loanStatus_EN from "./assets/i18n/loanStatus/en.json";
import loanStatus_MS from "./assets/i18n/loanStatus/ms.json";

import login_EN from "./assets/i18n/login/en.json";
import login_MS from "./assets/i18n/login/ms.json";

import payInstalmentNow_EN from "./assets/i18n/payInstalmentNow/en.json";
import payInstalmentNow_MS from "./assets/i18n/payInstalmentNow/ms.json";

import payment_EN from "./assets/i18n/payment/en.json";
import payment_MS from "./assets/i18n/payment/ms.json";

import privacyPolicy_EN from "./assets/i18n/privacyPolicy/en.json";
import privacyPolicy_MS from "./assets/i18n/privacyPolicy/ms.json";

import otpVerification_EN from "./assets/i18n/otpVerification/en.json";
import otpVerification_MS from "./assets/i18n/otpVerification/ms.json";

import registration_EN from "./assets/i18n/registration/en.json";
import registration_MS from "./assets/i18n/registration/ms.json";

import repaymentDetails_EN from "./assets/i18n/repaymentDetails/en.json";
import repaymentDetails_MS from "./assets/i18n/repaymentDetails/ms.json";

import repaymentSchedule_EN from "./assets/i18n/repaymentSchedule/en.json";
import repaymentSchedule_MS from "./assets/i18n/repaymentSchedule/ms.json";

import repaymentSimulation_EN from "./assets/i18n/repaymentSimulation/en.json";
import repaymentSimulation_MS from "./assets/i18n/repaymentSimulation/ms.json";

import requirementPanel_EN from "./assets/i18n/requirementPanel/en.json";
import requirementPanel_MS from "./assets/i18n/requirementPanel/ms.json";

import testingEligibility_EN from "./assets/i18n/testingEligibility/en.json";
import testingEligibility_MS from "./assets/i18n/testingEligibility/ms.json";

import updatePassword_EN from "./assets/i18n/updatePassword/en.json";
import updatePassword_MS from "./assets/i18n/updatePassword/ms.json";

import updateProfile_EN from "./assets/i18n/updateProfile/en.json";
import updateProfile_MS from "./assets/i18n/updateProfile/ms.json";

import verificationComplete_EN from "./assets/i18n/verificationComplete/en.json";
import verificationComplete_MS from "./assets/i18n/verificationComplete/ms.json";

import videoAttestation_EN from "./assets/i18n/videoAttestation/en.json";
import videoAttestation_MS from "./assets/i18n/videoAttestation/ms.json";

import updateEInvoiceTin_EN from "./assets/i18n/updateEInvoiceTin/en.json";
import updateEInvoiceTin_MS from "./assets/i18n/updateEInvoiceTin/ms.json";

import directDebit_EN from "./assets/i18n/directDebit/en.json";
import directDebit_MS from "./assets/i18n/directDebit/ms.json";

import smeApplicationForm_EN from "./assets/i18n/smeApplicationForm/en.json";
import smeApplicationForm_MS from "./assets/i18n/smeApplicationForm/ms.json";

import smeApplicationSteps_EN from "./assets/i18n/smeApplicationSteps/en.json";
import smeApplicationSteps_MS from "./assets/i18n/smeApplicationSteps/ms.json";

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: "en",
    fallbackLng: "en",
    // debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        common: common_EN,
        aboutUs: aboutUs_EN,
        applicationForm: applicationForm_EN,
        applicationSigningComplete: applicationSigningComplete_EN,
        applicationSummary: applicationSummary_EN,
        applicationSteps: applicationSteps_EN,
        approvedLoanSummary: approvedLoanSummary_EN,
        attestationOption: attestationOption_EN,
        contactUs: contactUs_EN,
        digitalSigning: digitalSigning_EN,
        documentUpload: documentUpload_EN,
        draftApplication: draftApplication_EN,
        eligibilityResult: eligibilityResult_EN,
        emailVerification: emailVerification_EN,
        faq: faq_EN,
        featuresAndBenefits: featuresAndBenefits_EN,
        home: home_EN,
        identityVerification: identityVerification_EN,
        landing: landing_EN,
        legalTerms: legalTerms_EN,
        liveCallAttestation: liveCallAttestation_EN,
        loanAgreement: loanAgreement_EN,
        loanHistory: loanHistory_EN,
        loanHistoryDetails: loanHistoryDetails_EN,
        loanStatus: loanStatus_EN,
        login: login_EN,
        otpVerification: otpVerification_EN,
        payInstalmentNow: payInstalmentNow_EN,
        payment: payment_EN,
        privacyPolicy: privacyPolicy_EN,
        registration: registration_EN,
        repaymentDetails: repaymentDetails_EN,
        repaymentSchedule: repaymentSchedule_EN,
        repaymentSimulation: repaymentSimulation_EN,
        requirementPanel: requirementPanel_EN,
        testingEligibility: testingEligibility_EN,
        updatePassword: updatePassword_EN,
        updateProfile: updateProfile_EN,
        verificationComplete: verificationComplete_EN,
        videoAttestation: videoAttestation_EN,
        directDebit: directDebit_EN,
        updateEInvoiceTin: updateEInvoiceTin_EN,
        smeApplicationForm: smeApplicationForm_EN,
        smeApplicationSteps: smeApplicationSteps_EN,
      },
      ms: {
        common: common_MS,
        aboutUs: aboutUs_MS,
        applicationForm: applicationForm_MS,
        applicationSigningComplete: applicationSigningComplete_MS,
        applicationSummary: applicationSummary_MS,
        applicationSteps: applicationSteps_MS,
        approvedLoanSummary: approvedLoanSummary_MS,
        attestationOption: attestationOption_MS,
        contactUs: contactUs_MS,
        digitalSigning: digitalSigning_MS,
        documentUpload: documentUpload_MS,
        draftApplication: draftApplication_MS,
        eligibilityResult: eligibilityResult_MS,
        emailVerification: emailVerification_MS,
        faq: faq_MS,
        featuresAndBenefits: featuresAndBenefits_MS,
        home: home_MS,
        identityVerification: identityVerification_MS,
        landing: landing_MS,
        legalTerms: legalTerms_MS,
        liveCallAttestation: liveCallAttestation_MS,
        loanAgreement: loanAgreement_MS,
        loanHistory: loanHistory_MS,
        loanHistoryDetails: loanHistoryDetails_MS,
        loanStatus: loanStatus_MS,
        login: login_MS,
        otpVerification: otpVerification_MS,
        payInstalmentNow: payInstalmentNow_MS,
        payment: payment_MS,
        privacyPolicy: privacyPolicy_MS,
        registration: registration_MS,
        repaymentDetails: repaymentDetails_MS,
        repaymentSchedule: repaymentSchedule_MS,
        repaymentSimulation: repaymentSimulation_MS,
        requirementPanel: requirementPanel_MS,
        testingEligibility: testingEligibility_MS,
        updatePassword: updatePassword_MS,
        updateProfile: updateProfile_MS,
        verificationComplete: verificationComplete_MS,
        videoAttestation: videoAttestation_MS,
        directDebit: directDebit_MS,
        updateEInvoiceTin: updateEInvoiceTin_MS,
        smeApplicationForm: smeApplicationForm_MS,
        smeApplicationSteps: smeApplicationSteps_MS,
      },
    },
  });

export default i18n;
