import { createAsyncThunk } from "@reduxjs/toolkit";

import { restructureErrorMessage } from "../../helpers/errorMessage";
import fileService from "./fileService";

export const getFilesByEntityIdThunk = createAsyncThunk(
  "file/getFilesByEntityId",
  async (requestData: any, thunkAPI) => {
    try {
      return await fileService.getFilesByEntityId(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getFileByIdThunk = createAsyncThunk(
  "file/getFileById",
  async (requestData: any, thunkAPI) => {
    try {
      return await fileService.getFileById(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fileUploadThunk = createAsyncThunk(
  "file/create",
  async (requestData: any, thunkAPI) => {
    try {
      return await fileService.fileUpload(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fileDeleteThunk = createAsyncThunk(
  "file/delete",
  async (requestData: any, thunkAPI) => {
    try {
      return await fileService.fileDelete(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getMasterSetupFilesByEntityIdThunk = createAsyncThunk(
  "master/file/getFilesByEntityId",
  async (requestData: any, thunkAPI) => {
    try {
      return await fileService.getFilesByEntityId(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getMasterSetupFileByIdThunk = createAsyncThunk(
  "master/file/getFileById",
  async (requestData: any, thunkAPI) => {
    try {
      return await fileService.getMasterSetupFileById(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getMasterSetupVideoFileByIdThunk = createAsyncThunk(
    "master/video/file/getFileById",
    async (requestData: any, thunkAPI) => {
        try {
            return await fileService.getMasterSetupVideoFileById(requestData);
        } catch (error) {
            const message = restructureErrorMessage(error);
            return thunkAPI.rejectWithValue(message);
        }
    },
);

export const getPaymentReceiptByIdThunk = createAsyncThunk(
  "file/getFileById",
  async (requestData: any, thunkAPI) => {
    try {
      return await fileService.getPaymentReceiptById(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const masterSetupFileUploadThunk = createAsyncThunk(
  "master/file/create",
  async (requestData: any, thunkAPI) => {
    try {
      return await fileService.masterSetupFileUpload(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);