import { useEffect, useState } from "react";

export const useInit = (callback: () => void, ...args: any) => {
  const [mounted, setMounted] = useState(false);

  const resetInit = () => setMounted(false);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);

      // @ts-ignore
      callback(...args);
    }
  }, [mounted, callback, args]);

  return [resetInit];
};
