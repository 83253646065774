import { createAsyncThunk } from "@reduxjs/toolkit";
import { restructureErrorMessage } from "../../helpers/errorMessage";

import loanInsuranceService from "./loanInsuranceService";

export const getLoanInsuranceForUserThunk = createAsyncThunk(
  "loanInsurance/getLoanInsuranceForUser",
  async (requestData: any, thunkAPI) => {
    try {
      return await loanInsuranceService.getLoanInsuranceForUser(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
