import React from "react";
import { FacebookFilled, InstagramFilled } from "@ant-design/icons";
import { Button, Row, Space } from "antd";

import "./index.less";

export const FooterAbout = () => {
  return (
    <div className="footer-about">
      <Row justify="center">
        <Space>
          <Button
            type="text"
            target="_blank"
            className="social-icon-button"
            icon={<FacebookFilled />}
            href="https://www.facebook.com/redONEMobile"
          />
          <Button
            type="text"
            target="_blank"
            className="social-icon-button"
            icon={<InstagramFilled />}
            href="https://www.instagram.com/redonenetwork"
          />
        </Space>
      </Row>
    </div>
  );
};
