import { createAsyncThunk } from "@reduxjs/toolkit";

import { restructureErrorMessage } from "../../helpers/errorMessage";
import { RazerPaymentRequestBody } from "../../features/payment/paymentSlice";
import paymentService from "./paymentService";

export const postRazerMerchantPaymentRequestThunk = createAsyncThunk(
  "payment/postRazerMerchantPaymentRequest",
  async (requestData: RazerPaymentRequestBody, thunkAPI) => {
    try {
      return await paymentService.postRazerMerchantPaymentRequest(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getPaymentHistoryThunk = createAsyncThunk(
  "payment/getHistory",
  async (requestData: any, thunkAPI) => {
    try {
      return await paymentService.getPaymentHistory(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
