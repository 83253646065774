import { createAsyncThunk } from "@reduxjs/toolkit";

import { restructureErrorMessage } from "../../helpers/errorMessage";
import attestationService from "./attestationService";

export const getVideoAttestationThunk = createAsyncThunk(
  "attestation/videoAttestation",
  async (requestData, thunkAPI) => {
    try {
      return await attestationService.getVideoAttestation();
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getVideoAttestationWatchStatusThunk = createAsyncThunk(
  "attestation/getVideoWatchStatus",
  async (requestData: any, thunkAPI) => {
    try {
      return await attestationService.getVideoAttestationWatchStatus(
        requestData
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const finishVideoAttestationThunk = createAsyncThunk(
  "attestation/finishVideoAttestation",
  async (requestData: any, thunkAPI) => {
    try {
      return await attestationService.finishVideoAttestation(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createAttestationScheduleThunk = createAsyncThunk(
  "attestation/createAttestationSchedule",
  async (requestData: any, thunkAPI) => {
    try {
      return await attestationService.createAttestationSchedule(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
