import "./App.less";

import dayjs from "dayjs";
import { lazy, Suspense, useEffect, useState } from "react";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { createBreakpoint } from "react-use";

import LoadingSpinner from "./components/LoadingSpinner";
import ProtectedRoute, {
  ProtectedRouteProps,
} from "./components/ProtectedRoute";
import { ApplicationStatusEnum } from "./enums/applicationStepStatus";
import { LANGUAGE, setLanguage } from "./features/config/configSlice";
import { setHasLoggedUser } from "./features/user/userSlice";
import i18n from "./i18n";
import { logoutThunk } from "./services/userService/userThunk";
import { AppDispatch, RootState } from "./store/store";
import { lazyRetry } from "./utils/routing.util";
import MobileUpdateEInvoiceTinPage from "./pages/mobile/UpdateEInvoiceTin/MobileUpdateProfilePage";
import WebUpdateEInvoiceTinPage from "./pages/web/UpdateEInvoiceTin/WebUpdateEInvoiceTinPage";
import MobileSmeTestingEligibilityPage from "./pages/mobile/SmeTestingEligibility/MobileSmeTestingEligibilityPage";
import WebSmeTestingEligibilityPage from "./pages/web/SmeTestingEligibility/WebSmeTestingEligibilityPage";
import MobileSmeHomePage from "./pages/mobile/SmeHomepage/MobileSmeHomePage";
import WebSmeHomePage from "./pages/web/SmeHomepage/WebSmeHomePage";
import MobileSmeDocumentUploadPage from "./pages/mobile/SmeDocumentUpload/MobileSmeDocumentUploadPage";
import WebSmeDocumentUploadPage from "./pages/web/SmeDocumentUpload/WebSmeDocumentUploadPage";
import MobileSmeApplicationSummaryPage from "./pages/mobile/SmeApplicationSummary/MobileSmeApplicationSummaryPage";
import WebSmeApplicationSummaryPage from "./pages/web/SmeApplicationSummary/WebSmeApplicationSummaryPage";
import MobileSmeIdentityVerificationPage from "./pages/mobile/SmeIdentityVerification/MobileSmeIdentityVerificationPage";
import WebSmeIdentityVerificationPage from "./pages/web/SmeIdentityVerification/WebSmeIdentityVerificationPage";
import MobileSmeVerificationCompletePage from "./pages/mobile/SmeVerificationComplete/MobileSmeVerificationCompletePage";
import WebSmeVerificationCompletePage from "./pages/web/SmeVerificationComplete/WebSmeVerificationCompletePage";
import MobileSmeApprovedLoanSummaryPage from "./pages/mobile/SmeApprovedLoanSummary/MobileSmeApprovedLoanSummaryPage";
import WebSmeApprovedLoanSummaryPage from "./pages/web/SmeApprovedLoanSummary/WebSmeApprovedLoanSummaryPage";
import MobileSmeAttestationOptionPage from "./pages/mobile/SmeAttestationOption/MobileSmeAttestationOptionPage";
import WebSmeAttestationOptionPage from "./pages/web/SmeAttestationOption/WebSmeAttestationOptionPage";
import MobileSmeVideoAttestationPage from "./pages/mobile/SmeVideoAttestation/MobileSmeVideoAttestationPage";
import WebSmeVideoAttestationPage from "./pages/web/SmeVideoAttestation/WebSmeVideoAttestationPage";
import MobileSmeLiveCallAttestationPage from "./pages/mobile/SmeLiveCallAttestation/MobileSmeLiveCallAttestationPage";
import WebSmeLiveCallAttestationPage from "./pages/web/SmeLiveCallAttestation/WebSmeLiveCallAttestationPage";
import MobileSmeLoanAgreementPage from "./pages/mobile/SmeLoanAgreement/MobileSmeLoanAgreementPage";
import WebSmeLoanAgreementPage from "./pages/web/SmeLoanAgreement/WebSmeLoanAgreementPage";
import MobileSmeAccountCreationPage from "./pages/mobile/SmeAccountCreation";
import WebSmeAccountCreationPage from "./pages/web/SmeAccountCreation";
import MobileSmeEligibilityResultPage from "./pages/mobile/SmeEligibilityResult/MobileSmeEligibilityResultPage";
import WebSmeEligibilityResultPage from "./pages/web/SmeEligibilityResult/WebSmeEligibilityResultPage";
import MobileSmeIndividualDocumentUploadPage from "./pages/mobile/SmeIndividualDocumentUpload/MobileSmeIndividualDocumentUploadPage";
import WebSmeIndividualDocumentUploadPage from "./pages/web/SmeIndividualDocumentUpload/WebSmeIndividualDocumentUploadPage";

// google analytic
ReactGA.initialize(process.env.REACT_APP_GA_ID!, { debug: true });

// google tag manager
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID!,
};
TagManager.initialize(tagManagerArgs);

const MobileLandingPage = lazy(() =>
  lazyRetry(() => import("./pages/mobile/Landing/MobileLandingPage")),
);
const WebLandingPage = lazy(() =>
  lazyRetry(() => import("./pages/web/Landing/WebLandingPage")),
);
const MobileRegistrationPage = lazy(() =>
  lazyRetry(() => import("./pages/mobile/Registration/MobileRegistrationPage")),
);
const WebRegistrationPage = lazy(() =>
  lazyRetry(() => import("./pages/web/Registration/WebRegistrationPage")),
);
const MobileTestingEligibilityPage = lazy(() =>
  lazyRetry(
    () =>
      import("./pages/mobile/TestingEligibility/MobileTestingEligibilityPage"),
  ),
);
const WebTestingEligibilityPage = lazy(() =>
  lazyRetry(
    () => import("./pages/web/TestingEligibility/WebTestingEligibilityPage"),
  ),
);
const MobileEligibilityResultPage = lazy(() =>
  lazyRetry(
    () =>
      import("./pages/mobile/EligibilityResult/MobileEligibilityResultPage"),
  ),
);
const MobileHomePage = lazy(() =>
  lazyRetry(() => import("./pages/mobile/Homepage/MobileHomePage")),
);
const WebHomePage = lazy(() =>
  lazyRetry(() => import("./pages/web/Homepage/WebHomePage")),
);
const WebEligibilityResultPage = lazy(() =>
  lazyRetry(
    () => import("./pages/web/EligibilityResult/WebEligibilityResultPage"),
  ),
);
const MobileApplicationPage = lazy(() =>
  lazyRetry(() => import("./pages/mobile/Application/MobileApplicationPage")),
);
const WebApplicationPage = lazy(() =>
  lazyRetry(() => import("./pages/web/Application/WebApplicationPage")),
);
const WebEmailVerificationPage = lazy(() =>
  lazyRetry(
    () => import("./pages/web/EmailVerification/WebEmailVerificationPage"),
  ),
);
const MobileEmailVerificationPage = lazy(() =>
  lazyRetry(
    () =>
      import("./pages/mobile/EmailVerification/MobileEmailVerificationPage"),
  ),
);
const MobileDocumentUploadPage = lazy(() =>
  lazyRetry(
    () => import("./pages/mobile/DocumentUpload/MobileDocumentUploadPage"),
  ),
);
const WebDocumentUploadPage = lazy(() =>
  lazyRetry(() => import("./pages/web/DocumentUpload/WebDocumentUploadPage")),
);
const MobileApplicationSummaryPage = lazy(() =>
  lazyRetry(
    () =>
      import("./pages/mobile/ApplicationSummary/MobileApplicationSummaryPage"),
  ),
);
const WebApplicationSummaryPage = lazy(() =>
  lazyRetry(
    () => import("./pages/web/ApplicationSummary/WebApplicationSummaryPage"),
  ),
);
const WebIdentityVerificationPage = lazy(() =>
  lazyRetry(
    () =>
      import("./pages/web/IdentityVerification/WebIdentityVerificationPage"),
  ),
);
const MobileIdentityVerificationPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        "./pages/mobile/IdentityVerification/MobileIdentityVerificationPage"
      ),
  ),
);
const MobileVerificationCompletePage = lazy(() =>
  lazyRetry(
    () =>
      import(
        "./pages/mobile/VerificationComplete/MobileVerificationCompletePage"
      ),
  ),
);
const WebVerificationCompletePage = lazy(() =>
  lazyRetry(
    () =>
      import("./pages/web/VerificationComplete/WebVerificationCompletePage"),
  ),
);
const MobileApplicationSigningCompletePage = lazy(() =>
  lazyRetry(
    () =>
      import(
        "./pages/mobile/ApplicationSigningComplete/MobileApplicationSigningCompletePage"
      ),
  ),
);
const WebApplicationSigningCompletePage = lazy(() =>
  lazyRetry(
    () =>
      import(
        "./pages/web/ApplicationSigningComplete/WebApplicationSigningCompletePage"
      ),
  ),
);
const MobileAttestationOptionPage = lazy(() =>
  lazyRetry(
    () =>
      import("./pages/mobile/AttestationOption/MobileAttestationOptionPage"),
  ),
);
const WebAttestationOptionPage = lazy(() =>
  lazyRetry(
    () => import("./pages/web/AttestationOption/WebAttestationOptionPage"),
  ),
);
const MobileLiveCallAttestationPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        "./pages/mobile/LiveCallAttestation/MobileLiveCallAttestationPage"
      ),
  ),
);
const WebLiveCallAttestationPage = lazy(() =>
  lazyRetry(
    () => import("./pages/web/LiveCallAttestation/WebLiveCallAttestationPage"),
  ),
);
const MobileVideoAttestationPage = lazy(() =>
  lazyRetry(
    () => import("./pages/mobile/VideoAttestation/MobileVideoAttestationPage"),
  ),
);
const WebVideoAttestationPage = lazy(() =>
  lazyRetry(
    () => import("./pages/web/VideoAttestation/WebVideoAttestationPage"),
  ),
);
const MobileApprovedLoanSummaryPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        "./pages/mobile/ApprovedLoanSummary/MobileApprovedLoanSummaryPage"
      ),
  ),
);
const WebApprovedLoanSummaryPage = lazy(() =>
  lazyRetry(
    () => import("./pages/web/ApprovedLoanSummary/WebApprovedLoanSummaryPage"),
  ),
);
const MobileLoanAgreementPage = lazy(() =>
  lazyRetry(
    () => import("./pages/mobile/LoanAgreement/MobileLoanAgreementPage"),
  ),
);
const WebLoanAgreementPage = lazy(() =>
  lazyRetry(() => import("./pages/web/LoanAgreement/WebLoanAgreementPage")),
);
const MobileDigitalSigningPage = lazy(() =>
  lazyRetry(
    () => import("./pages/mobile/DigitalSigning/MobileDigitalSigningPage"),
  ),
);
const WebDigitalSigningPage = lazy(() =>
  lazyRetry(() => import("./pages/web/DigitalSigning/WebDigitalSigningPage")),
);
const MobileLoanRequestTACPage = lazy(() =>
  lazyRetry(
    () => import("./pages/mobile/LoanRequestTAC/MobileLoanRequestTACPage"),
  ),
);
const WebLoanRequestTACPage = lazy(() =>
  lazyRetry(() => import("./pages/web/LoanRequestTAC/WebLoanRequestTACPage")),
);
const MobileUpdateProfilePage = lazy(() =>
  lazyRetry(
    () => import("./pages/mobile/UpdateProfile/MobileUpdateProfilePage"),
  ),
);
const WebUpdateProfilePage = lazy(() =>
  lazyRetry(() => import("./pages/web/UpdateProfile/WebUpdateProfilePage")),
);
const MobileRepaymentSchedulePage = lazy(() =>
  lazyRetry(
    () =>
      import("./pages/mobile/RepaymentSchedule/MobileRepaymentSchedulePage"),
  ),
);
const WebRepaymentSchedulePage = lazy(() =>
  lazyRetry(
    () => import("./pages/web/RepaymentSchedule/WebRepaymentSchedulePage"),
  ),
);
const MobilePaymentHistoryPage = lazy(() =>
  lazyRetry(
    () => import("./pages/mobile/PaymentHistory/MobilePaymentHistoryPage"),
  ),
);
const WebPaymentHistoryPage = lazy(() =>
  lazyRetry(() => import("./pages/web/PaymentHistory/WebPaymentHistoryPage")),
);
const MobilePayInstalmentNowPage = lazy(() =>
  lazyRetry(
    () => import("./pages/mobile/PayInstalmentNow/MobilePayInstalmentNowPage"),
  ),
);
const WebPayInstalmentNowPage = lazy(() =>
  lazyRetry(
    () => import("./pages/web/PayInstalmentNow/WebPayInstalmentNowPage"),
  ),
);
const MobileContactUsPage = lazy(() =>
  lazyRetry(() => import("./pages/mobile/ContactUs/MobileContactUsPage")),
);
const WebContactUsPage = lazy(() =>
  lazyRetry(() => import("./pages/web/ContactUs/WebContactUsPage")),
);
const MobileLoanHistoryPage = lazy(() =>
  lazyRetry(() => import("./pages/mobile/LoanHistory/MobileLoanHistoryPage")),
);
const MobileLoanHistoryDetailsPage = lazy(() =>
  lazyRetry(
    () =>
      import("./pages/mobile/LoanHistoryDetails/MobileLoanHistoryDetailsPage"),
  ),
);
const WebLoanHistoryPage = lazy(() =>
  lazyRetry(() => import("./pages/web/LoanHistory/WebLoanHistoryPage")),
);
const WebLoanHistoryDetailsPage = lazy(() =>
  lazyRetry(
    () => import("./pages/web/LoanHistoryDetails/WebLoanHistoryDetailsPage"),
  ),
);
const MobileAboutUsPage = lazy(() =>
  lazyRetry(() => import("./pages/mobile/AboutUs/MobileAboutUsPage")),
);
const WebAboutUsPage = lazy(() =>
  lazyRetry(() => import("./pages/web/AboutUs/WebAboutUsPage")),
);
const WebResetPasswordPage = lazy(
  () => import("./pages/web/ResetPassword/WebResetPasswordPage"),
);
const MobileResetPasswordPage = lazy(
  () => import("./pages/mobile/ResetPassword/MobileResetPasswordPage"),
);
const MobileFaqPage = lazy(() =>
  lazyRetry(() => import("./pages/mobile/Faq/MobileFaqPage")),
);
const WebFaqPage = lazy(() =>
  lazyRetry(() => import("./pages/web/Faq/WebFaqPage")),
);
const WebLegalTermsPage = lazy(() =>
  lazyRetry(() => import("./pages/web/LegalTerms/WebLegalTermsPage")),
);
const MobileLegalTermsPage = lazy(() =>
  lazyRetry(() => import("./pages/mobile/LegalTerms/MobileLegalTermsPage")),
);
const MobileLoanStatusCheckPage = lazy(() =>
  lazyRetry(
    () => import("./pages/mobile/LoanStatusCheck/MobileLoanStatusCheckPage"),
  ),
);
const WebLoanStatusCheckPage = lazy(() =>
  lazyRetry(() => import("./pages/web/LoanStatusCheck/WebLoanStatusCheckPage")),
);
const MobileDraftApplicationPage = lazy(() =>
  lazyRetry(
    () => import("./pages/mobile/DraftApplication/MobileDraftApplicationPage"),
  ),
);
const WebDraftApplicationPage = lazy(() =>
  lazyRetry(
    () => import("./pages/web/DraftApplication/WebDraftApplicationPage"),
  ),
);
const MobilePaymentResultPage = lazy(() =>
  lazyRetry(
    () => import("./pages/mobile/PaymentResult/MobilePaymentResultPage"),
  ),
);
const WebPaymentResultPage = lazy(() =>
  lazyRetry(() => import("./pages/web/PaymentResult/WebPaymentResultPage")),
);
const PendingPaymentPage = lazy(() =>
  lazyRetry(
    () => import("./pages/shared/PendingPaymentPage/PendingPaymentPage"),
  ),
);

const TempBanErrorPage = lazy(() =>
  lazyRetry(() => import("./pages/shared/TempBanErrorPage")),
);
const LogoutPage = lazy(() =>
  lazyRetry(() => import("./pages/shared/Logout/LogoutPage")),
);

const MobileAccountCreationPage = lazy(() =>
  lazyRetry(() => import("./pages/mobile/AccountCreation")),
);
const WebAccountCreationPage = lazy(() =>
  lazyRetry(() => import("./pages/web/AccountCreation")),
);

const MobileUpdatePasswordPage = lazy(() =>
  lazyRetry(
    () => import("./pages/mobile/UpdatePassword/MobileUpdatePasswordPage"),
  ),
);

const WebUpdatePasswordPage = lazy(() =>
  lazyRetry(() => import("./pages/web/UpdatePassword/WebUpdatePasswordPage")),
);

const MobileDirectDebitPage = lazy(() =>
  lazyRetry(() => import("./pages/mobile/DirectDebit/MobileDirectDebitPage")),
);

const WebDirectDebitPage = lazy(() =>
  lazyRetry(() => import("./pages/web/DirectDebit/WebDirectDebitPage")),
);

const MobileDirectDebitConfirmationPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        "./pages/mobile/DirectDebitConfirmation/MobileDirectDebitConfirmationPage"
      ),
  ),
);

const WebDirectDebitConfirmationPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        "./pages/web/DirectDebitConfirmation/WebDirectDebitConfirmationPage"
      ),
  ),
);

const DirectDebitEnrollmentPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        "./pages/shared/DirectDebitEnrollmentPage/DirectDebitEnrollmentPage"
      ),
  ),
);

const WebUpdateDirectDebitPage = lazy(() =>
  lazyRetry(
    () => import("./pages/web/UpdateDirectDebit/WebUpdateDirectDebitPage"),
  ),
);

const MobileUpdateDirectDebitPage = lazy(() =>
  lazyRetry(
    () =>
      import("./pages/mobile/UpdateDirectDebit/MobileUpdateDirectDebitPage"),
  ),
);

const MobileUpdateDirectDebitResultPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        "./pages/mobile/UpdateDirectDebitResult/MobileUpdateDirectDebitResultPage"
      ),
  ),
);
const WebUpdateDirectDebitResultPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        "./pages/web/UpdateDirectDebitResult/WebUpdateDirectDebitResultPage"
      ),
  ),
);

const MobileSmeIndividualApplicationPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        "./pages/mobile/SmeIndividualApplication/MobileSmeIndividualApplicationPage"
      ),
  ),
);
const WebSmeIndividualApplicationPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        "./pages/web/SmeIndividualApplication/WebSmeIndividualApplicationPage"
      ),
  ),
);

const MobileSmeSolePropApplicationPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        "./pages/mobile/SmeSolePropApplication/MobileSmeSolePropApplicationPage"
      ),
  ),
);
const WebSmeSolePropApplicationPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        "./pages/web/SmeSolePropApplication/WebSmeSolePropApplicationPage"
      ),
  ),
);

const useBreakpoint = createBreakpoint();

function App() {
  const dispatch = useDispatch<AppDispatch>();
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === "tablet";
  const { currentUser, hasLoggedUser } = useSelector(
    (state: RootState) => state.user,
  );
  const { language } = useSelector((state: RootState) => state.config);
  const { applicationDetails } = useSelector(
    (state: RootState) => state.application,
  );
  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
    isAuthenticated: hasLoggedUser,
    authenticationPath: "/",
  };

  const languageSearchParam = new URLSearchParams(window.location.search).get(
    "language",
  );

  const onLogout = () => {
    dispatch(logoutThunk());
  };

  const setHasLoggedUserReduxState = async (hasUser: boolean) => {
    await dispatch(setHasLoggedUser(hasUser));
  };

  const [state, setState] = useState<string>("Active");
  const [count, setCount] = useState<number>(0);
  const [remaining, setRemaining] = useState<number>(0);

  const onIdle = () => {
    onLogout();
    setState("Idle");
  };

  const onActive = () => {
    setState("Active");
  };

  const onAction = () => {
    setCount(count + 1);
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 900000, // 900000ms = 15 min
    throttle: 500,
  });

  const handleLangChange = (lang: LANGUAGE) => {
    dispatch(setLanguage(lang));
    localStorage.setItem("language", lang);
  };

  const initialCallbackState = {
    handleLangChange,
    setHasLoggedUserReduxState,
  };

  const [callbackState] = useState(initialCallbackState);

  useEffect(() => {
    if (currentUser?.data != null && applicationDetails?.data != null) {
      // if the application applied by user is rejected before,
      // the user can only apply after allowToApplyNewLoanAfterDateTime
      // similar status check also exist in LoanApplicationStatusCard, case ApplicationStatusEnum.REJECTED
      const allowToApplyNewLoanAfterDateTime =
        currentUser.data?.allowToApplyNewLoanAfterDateTime;

      const isBeforeAllowToApplyNewLoanAfterDateTime =
        allowToApplyNewLoanAfterDateTime != null &&
        dayjs().isBefore(dayjs(allowToApplyNewLoanAfterDateTime));

      if (
        applicationDetails.data?.applicationStatus ===
          ApplicationStatusEnum.REJECTED &&
        isBeforeAllowToApplyNewLoanAfterDateTime &&
        !window.location.href.includes(`/not-allow-new-loan`)
      ) {
        window.location.href = `/not-allow-new-loan?language=${language}`;
      }
    }
  }, [
    applicationDetails.data?.applicationStatus,
    currentUser.data?.allowToApplyNewLoanAfterDateTime,
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  // google analytic
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [window.location.pathname]);

  useEffect(() => {
    let language = localStorage.getItem("language") ?? LANGUAGE.EN;
    if (languageSearchParam != null && languageSearchParam !== "") {
      language = languageSearchParam;
    }
    console.log("🚀 ~ file: App.tsx:407 ~ useEffect ~ language:", language);
    const enumLangauge = language === LANGUAGE.EN ? LANGUAGE.EN : LANGUAGE.MS;
    callbackState.handleLangChange(enumLangauge);
    i18n.changeLanguage(language.toLowerCase());
  }, [callbackState, languageSearchParam]);

  useEffect(() => {
    const handleStorageChange = () => {
      if (!localStorage.getItem("accessToken")) {
        callbackState.setHasLoggedUserReduxState(false);
      }
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [callbackState]);

  // If has logged user but missing user id and phoneNo
  // then logout user
  useEffect(() => {
    if (hasLoggedUser && (!currentUser.data?.id || !currentUser.data.phoneNo)) {
      dispatch(logoutThunk());
    }
  }, []);

  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div className="h-screen m-auto">
            <LoadingSpinner />
          </div>
        }
      >
        <Routes>
          <Route
            path={"/"}
            element={
              hasLoggedUser ? (
                <Navigate to={`/home?language=${language}`} />
              ) : isMobile ? (
                <MobileLandingPage />
              ) : (
                <WebLandingPage />
              )
            }
          />
          <Route
            path={"/registration"}
            element={
              isMobile ? <MobileRegistrationPage /> : <WebRegistrationPage />
            }
          />
          <Route path={"/logout"} element={<LogoutPage />} />
          <Route path={"/not-allow-new-loan"} element={<TempBanErrorPage />} />

          <Route
            path={"/testing-eligibility"}
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  isMobile ? (
                    <MobileTestingEligibilityPage />
                  ) : (
                    <WebTestingEligibilityPage />
                  )
                }
              />
            }
          />
          <Route
            path={"/testing-eligibility/result/:result"}
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  isMobile ? (
                    <MobileEligibilityResultPage />
                  ) : (
                    <WebEligibilityResultPage />
                  )
                }
              />
            }
          />
          <Route
            path={"/home"}
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={isMobile ? <MobileHomePage /> : <WebHomePage />}
              />
            }
          />
          <Route
            path={"/application"}
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  isMobile ? <MobileApplicationPage /> : <WebApplicationPage />
                }
              />
            }
          />
          <Route
            path={"/application/email-verification"}
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  isMobile ? (
                    <MobileEmailVerificationPage />
                  ) : (
                    <WebEmailVerificationPage />
                  )
                }
              />
            }
          />
          <Route
            path={"/application/document-upload"}
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  isMobile ? (
                    <MobileDocumentUploadPage />
                  ) : (
                    <WebDocumentUploadPage />
                  )
                }
              />
            }
          />
          <Route
            path={"/application/summary"}
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  isMobile ? (
                    <MobileApplicationSummaryPage />
                  ) : (
                    <WebApplicationSummaryPage />
                  )
                }
              />
            }
          />
          <Route
            path={"/application/identity-verification"}
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  isMobile ? (
                    <MobileIdentityVerificationPage />
                  ) : (
                    <WebIdentityVerificationPage />
                  )
                }
              />
            }
          />

          <Route
            path={"/application/verification-complete"}
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  isMobile ? (
                    <MobileVerificationCompletePage />
                  ) : (
                    <WebVerificationCompletePage />
                  )
                }
              />
            }
          />

          <Route
            path={"/loan-status/attestation-option"}
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  isMobile ? (
                    <MobileAttestationOptionPage />
                  ) : (
                    <WebAttestationOptionPage />
                  )
                }
              />
            }
          />
          <Route
            path={"/loan-status/attestation-option/video"}
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  isMobile ? (
                    <MobileVideoAttestationPage />
                  ) : (
                    <WebVideoAttestationPage />
                  )
                }
              />
            }
          />
          <Route
            path={"/loan-status/attestation-option/live-call"}
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  isMobile ? (
                    <MobileLiveCallAttestationPage />
                  ) : (
                    <WebLiveCallAttestationPage />
                  )
                }
              />
            }
          />
          <Route
            path={"/loan-status/approved-loan-summary"}
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  isMobile ? (
                    <MobileApprovedLoanSummaryPage />
                  ) : (
                    <WebApprovedLoanSummaryPage />
                  )
                }
              />
            }
          />
          <Route
            path={"/loan-status/loan-agreement"}
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  isMobile ? (
                    <MobileLoanAgreementPage />
                  ) : (
                    <WebLoanAgreementPage />
                  )
                }
              />
            }
          />
          <Route
            path={"/loan-status/account-creation"}
            element={
              isMobile ? (
                <MobileAccountCreationPage />
              ) : (
                <WebAccountCreationPage />
              )
            }
          />
          <Route
            path={"/loan-status/digital-signing"}
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  isMobile ? (
                    <MobileDigitalSigningPage />
                  ) : (
                    <WebDigitalSigningPage />
                  )
                }
              />
            }
          />
          <Route
            path={"/direct-debit"}
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  isMobile ? <MobileDirectDebitPage /> : <WebDirectDebitPage />
                }
              />
            }
          />

          <Route
            path={"/direct-debit/confirmation"}
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  isMobile ? (
                    <MobileDirectDebitConfirmationPage />
                  ) : (
                    <WebDirectDebitConfirmationPage />
                  )
                }
              />
            }
          />

          <Route
            path={"/loan-status/request-tac"}
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  isMobile ? (
                    <MobileLoanRequestTACPage />
                  ) : (
                    <WebLoanRequestTACPage />
                  )
                }
              />
            }
          />

          <Route
            path={"/loan-status/application-complete"}
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  isMobile ? (
                    <MobileApplicationSigningCompletePage />
                  ) : (
                    <WebApplicationSigningCompletePage />
                  )
                }
              />
            }
          />

          <Route
            path={"/update-profile"}
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  isMobile ? (
                    <MobileUpdateProfilePage />
                  ) : (
                    <WebUpdateProfilePage />
                  )
                }
              />
            }
          />

          <Route
            path={"/update-direct-debit"}
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  isMobile ? (
                    <MobileUpdateDirectDebitPage />
                  ) : (
                    <WebUpdateDirectDebitPage />
                  )
                }
              />
            }
          />

          <Route
            path={"/change-password"}
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  isMobile ? (
                    <MobileUpdatePasswordPage />
                  ) : (
                    <WebUpdatePasswordPage />
                  )
                }
              />
            }
          />

          <Route
            path={"/repayment-schedule"}
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  isMobile ? (
                    <MobileRepaymentSchedulePage />
                  ) : (
                    <WebRepaymentSchedulePage />
                  )
                }
              />
            }
          />

          <Route
            path={"/payment-history"}
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  isMobile ? (
                    <MobilePaymentHistoryPage />
                  ) : (
                    <WebPaymentHistoryPage />
                  )
                }
              />
            }
          />

          <Route
            path={"/pay-instalment-now"}
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  isMobile ? (
                    <MobilePayInstalmentNowPage />
                  ) : (
                    <WebPayInstalmentNowPage />
                  )
                }
              />
            }
          />

          <Route
            path={"/payment-result"}
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  isMobile ? (
                    <MobilePaymentResultPage />
                  ) : (
                    <WebPaymentResultPage />
                  )
                }
              />
            }
          />

          <Route
            path={"/update-direct-debit-result"}
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  isMobile ? (
                    <MobileUpdateDirectDebitResultPage />
                  ) : (
                    <WebUpdateDirectDebitResultPage />
                  )
                }
              />
            }
          />

          <Route
            path={"/contact-us"}
            element={isMobile ? <MobileContactUsPage /> : <WebContactUsPage />}
          />

          <Route
            path={"/loan-history"}
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  isMobile ? <MobileLoanHistoryPage /> : <WebLoanHistoryPage />
                }
              />
            }
          />

          <Route
            path={"/loan-history/:applicationId"}
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  isMobile ? (
                    <MobileLoanHistoryDetailsPage />
                  ) : (
                    <WebLoanHistoryDetailsPage />
                  )
                }
              />
            }
          />

          <Route
            path={"/about-us"}
            element={isMobile ? <MobileAboutUsPage /> : <WebAboutUsPage />}
          />

          <Route
            path={"/reset-password-request"}
            element={
              isMobile ? <MobileResetPasswordPage /> : <WebResetPasswordPage />
            }
          />

          <Route
            path={"/legal-terms"}
            element={
              isMobile ? <MobileLegalTermsPage /> : <WebLegalTermsPage />
            }
          />

          <Route
            path={"/faq"}
            element={isMobile ? <MobileFaqPage /> : <WebFaqPage />}
          />

          <Route
            path={"/loan-status-check"}
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  isMobile ? (
                    <MobileLoanStatusCheckPage />
                  ) : (
                    <WebLoanStatusCheckPage />
                  )
                }
              />
            }
          />

          <Route
            path={"/draft-application"}
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  isMobile ? (
                    <MobileDraftApplicationPage />
                  ) : (
                    <WebDraftApplicationPage />
                  )
                }
              />
            }
          />

          <Route
            path={"/pending-payment"}
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={<PendingPaymentPage />}
              />
            }
          />

          <Route
            path={"/request-e-invoice"}
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={
                  isMobile ? (
                    <MobileUpdateEInvoiceTinPage />
                  ) : (
                    <WebUpdateEInvoiceTinPage />
                  )
                }
              />
            }
          />

          <Route
            path="*"
            element={
              <Navigate
                to={hasLoggedUser ? `/home?language=${language}` : "/"}
                replace
              />
            }
          />

          <Route
            path={"/direct-debit-enrollment"}
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={<DirectDebitEnrollmentPage />}
              />
            }
          />

          {/*TODO: Wrap all SME endpoints with protected route after everything is done*/}
          <Route
            path={"/smeIndividual/application"}
            element={
              isMobile ? (
                <MobileSmeIndividualApplicationPage />
              ) : (
                <WebSmeIndividualApplicationPage />
              )
            }
          />

          <Route
            path={"/smeSoleProp/application"}
            element={
              isMobile ? (
                <MobileSmeSolePropApplicationPage />
              ) : (
                <WebSmeSolePropApplicationPage />
              )
            }
          />

          <Route
            path={"/sme/testing-eligibility"}
            element={
              isMobile ? (
                <MobileSmeTestingEligibilityPage />
              ) : (
                <WebSmeTestingEligibilityPage />
              )
            }
          />

          <Route
            path={"/sme/home"}
            element={isMobile ? <MobileSmeHomePage /> : <WebSmeHomePage />}
          />

          <Route
            path={"/sme/document-upload"}
            element={
              isMobile ? (
                <MobileSmeDocumentUploadPage />
              ) : (
                <WebSmeDocumentUploadPage />
              )
            }
          />

          <Route
            path={"/smeIndividual/application/document-upload"}
            element={
              isMobile ? (
                <MobileSmeIndividualDocumentUploadPage />
              ) : (
                <WebSmeIndividualDocumentUploadPage />
              )
            }
          />

          <Route
            path={"/sme/application/summary"}
            element={
              isMobile ? (
                <MobileSmeApplicationSummaryPage />
              ) : (
                <WebSmeApplicationSummaryPage />
              )
            }
          />

          <Route
            path={"/sme/application/identity-verification"}
            element={
              isMobile ? (
                <MobileSmeIdentityVerificationPage />
              ) : (
                <WebSmeIdentityVerificationPage />
              )
            }
          />

          <Route
            path={"/sme/application/verification-complete"}
            element={
              isMobile ? (
                <MobileSmeVerificationCompletePage />
              ) : (
                <WebSmeVerificationCompletePage />
              )
            }
          />

          <Route
            path={"/sme/loan-status/approved-loan-summary"}
            element={
              isMobile ? (
                <MobileSmeApprovedLoanSummaryPage />
              ) : (
                <WebSmeApprovedLoanSummaryPage />
              )
            }
          />

          <Route
            path={"/sme/loan-status/attestation-option"}
            element={
              isMobile ? (
                <MobileSmeAttestationOptionPage />
              ) : (
                <WebSmeAttestationOptionPage />
              )
            }
          />

          <Route
            path={"/sme/loan-status/attestation-option/video"}
            element={
              isMobile ? (
                <MobileSmeVideoAttestationPage />
              ) : (
                <WebSmeVideoAttestationPage />
              )
            }
          />

          <Route
            path={"/sme/loan-status/attestation-option/live-call"}
            element={
              isMobile ? (
                <MobileSmeLiveCallAttestationPage />
              ) : (
                <WebSmeLiveCallAttestationPage />
              )
            }
          />

          <Route
            path={"/sme/loan-status/loan-agreement"}
            element={
              isMobile ? (
                <MobileSmeLoanAgreementPage />
              ) : (
                <WebSmeLoanAgreementPage />
              )
            }
          />

          <Route
            path={"/sme/loan-status/account-creation"}
            element={
              isMobile ? (
                <MobileSmeAccountCreationPage />
              ) : (
                <WebSmeAccountCreationPage />
              )
            }
          />

          <Route
            path={"/sme/testing-eligibility/result/:result"}
            element={
              isMobile ? (
                <MobileSmeEligibilityResultPage />
              ) : (
                <WebSmeEligibilityResultPage />
              )
            }
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
