import "./MobileLayoutWithMenu.less";

import { Button, Layout } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import redCashLogo from "../../assets/logo/redCASH-logo-based-red-transp.png";
import LoginModal from "../../components/modals/LoginModal";
import MenuModal from "../../components/modals/MenuModal";
import { LANGUAGE, setLanguage } from "../../features/config/configSlice";
import { AppDispatch, RootState } from "../../store/store";

const { Header, Content } = Layout;

export const MobileLayoutWithMenu = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { language } = useSelector((state: RootState) => state.config);
  const { currentUser } = useSelector((state: RootState) => state.user);

  const hasUser = currentUser?.data?.id != null;

  const handleLangChange = (lang: LANGUAGE) => {
    dispatch(setLanguage(lang));
    localStorage.setItem("language", lang);
    navigate(`${location.pathname}?language=${lang}`);
  };

  return (
    <Layout>
      <Header className="site-layout-header">
        <div
          style={{ cursor: "pointer" }}
          onClick={() => navigate(hasUser ? "/home/" : "/")}
        >
          <img src={redCashLogo} className="header-logo" alt="logo" />
        </div>
        <div className="flex">
          <button
            className="header-text-button"
            style={{ fontWeight: "bold" }}
            onClick={() =>
              handleLangChange(
                language === LANGUAGE.EN ? LANGUAGE.MS : LANGUAGE.EN,
              )
            }
          >
            <span
              className={
                language === LANGUAGE.EN ? "opacity-50" : "opacity-100"
              }
            >
              BM
            </span>{" "}
            |{" "}
            <span
              className={
                language === LANGUAGE.EN ? "opacity-100" : "opacity-50"
              }
            >
              EN
            </span>
          </button>
          {!hasUser && (
            <div style={{ marginTop: -4 }}>
              <Button shape="round" ghost onClick={() => setIsModalOpen(true)}>
                <b>Login</b>
              </Button>
            </div>
          )}
          <MenuModal />
        </div>
      </Header>
      <Content className="site-layout-content">{children}</Content>
      {isModalOpen && (
        <LoginModal
          hideButton={true}
          defaultShowModal={isModalOpen}
          callback={() => setIsModalOpen(false)}
        />
      )}
    </Layout>
  );
};
