import EligibilityResultContent from "../../../components/EligibilityResultContent";

import "./WebSmeEligibilityResultPage.less";
import SmeEligibilityResultContent from "../../../components/SmeEligibilityResultContent";

const WebSmeEligibilityResultPage = () => {
  return (
    <div className="max-w-xl mx-auto">
      <SmeEligibilityResultContent />
    </div>
  );
};

export default WebSmeEligibilityResultPage;
