import { PayloadAction, createSlice } from "@reduxjs/toolkit";
export enum LANGUAGE {
  "EN" = "EN",
  "MS" = "MS",
}
export type ConfigState = {
  language: LANGUAGE;
};

const initialState: ConfigState = {
  language: LANGUAGE.EN,
};

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    configStateReset: () => initialState,
    setLanguage: (state, action: PayloadAction<LANGUAGE>) => {
      state.language = action.payload;
      localStorage.setItem("language", action.payload);
    },
  },
});

export const { configStateReset, setLanguage } = configSlice.actions;

export default configSlice.reducer;
