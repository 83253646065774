import "./index.less";

import { Col, Row, Steps } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import applyApplicationImage from "../../../assets/images/apply-application-1.png";
import redCashLogo from "../../../assets/logo/redCASH-logo-red-transp.png";
import { FooterAbout } from "../../../components/WebFooter/FooterAbout";

export type WebApplyApplicationLayoutProps = {
  step: number;
} & {
  children: React.ReactNode;
};

export const WebApplyApplicationLayout: React.FC<
  WebApplyApplicationLayoutProps
> = (props) => {
  const { step, children } = props;
  const navigate = useNavigate();
  const { t } = useTranslation(["common", "applicationSteps"]);

  return (
    <Row className="web-apply-application-layout" wrap={false}>
      <Col span={8} className="web-apply-application-left-info-panel">
        <img
          className="web-apply-application-left-info-panel-logo"
          src={redCashLogo}
          alt="redCASH logo"
          onClick={() => navigate("/home")}
        />
        <div className="web-apply-application-left-info-panel-content">
          <h4>{t("applicationSteps:Application_Progress")}</h4>
          <Steps
            current={step}
            direction="vertical"
            progressDot
            items={[
              { title: t("applicationSteps:applyApplicationStep1") },
              { title: t("applicationSteps:applyApplicationStep2") },
              { title: t("applicationSteps:applyApplicationStep3") },
              { title: t("applicationSteps:applyApplicationStep4") },
              { title: t("applicationSteps:applyApplicationStep5") },
              { title: t("applicationSteps:applyApplicationStep6") },
            ]}
          />
          <img src={applyApplicationImage} alt="Apply Application" />
        </div>
      </Col>
      <Col span={16}>
        <div className="web-apply-application-right-panel-content">
          {children}
          <FooterAbout />
        </div>
      </Col>
    </Row>
  );
};
