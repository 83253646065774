import "./index.less";

import { Card, Input, message, Select, Slider, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { LoadingOutlined } from "@ant-design/icons";

import { primaryColor } from "../../assets/style/variables";
import {
  applicationStateReset,
  setApplicationDetails,
  setApplicationDraft,
} from "../../features/application/applicationSlice";
import { numberWithCommas } from "../../helpers/number";
import { useDebounce } from "../../hooks/useDebounceHook";
import {
  createApplicationDraftThunk,
  testEligibilityThunk,
} from "../../services/applicationService/applicationThunk";
import { getLoanReasonListThunk } from "../../services/dropdownParameterService/dropdownParameterThunk";
import {
  getProductByReferralCodeThunk,
  getProductsThunk,
} from "../../services/productService/productThunk";
import { AppDispatch, RootState } from "../../store/store";

import type { SliderMarks } from "antd/es/slider";
import { LANGUAGE } from "../../features/config/configSlice";
import { patchUserApplicationCreatedThunk } from "../../services/userService/userThunk";
import {
  setSmeApplicationDetails,
  setSmeApplicationDraft,
} from "../../features/smeApplication/smeApplicationSlice";
import { createSmeApplicationDraftThunk } from "../../services/smeApplicationService/smeApplicationThunk";
import { ORGANIZATION_TYPE } from "../../enums/partnerType";
import { jwtDecode } from "jwt-decode";

const { Title } = Typography;

export const SmeTestingEligibilityForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation(["common", "testingEligibility"]);

  const { updateSmeApplicationDraftFormData, partnerDetails } = useSelector(
    (state: RootState) => state.smeApplication,
  );

  const { loanReasonState } = useSelector(
    (state: RootState) => state.dropdownParameter,
  );
  const { currentUser } = useSelector((state: RootState) => state.user);
  // const { updateApplicationDraftFormData, applicationDetails } = useSelector(
  //   (state: RootState) => state.application,
  // );
  const { productState } = useSelector((state: RootState) => state.product);
  const { selectedProduct } = productState;

  const [loanAmt, setLoanAmt] = useState(0);
  const [loanPeriod, setLoanPeriod] = useState(0);
  const [loanReasonId, setLoanReasonId] = useState(
    // TODO: set loan id here
    "loan id",
    // updateApplicationDraftFormData.loanReasonId,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [referralCode, setReferralCode] = useState<string>("");
  const [otherReason, setOtherReason] = useState<string | null>(null);
  const debouncedReferralCode = useDebounce<string>(referralCode, 1000);
  const [validReferralCode, setValidReferralCode] = useState<boolean>(false);
  const { language } = useSelector((state: RootState) => state.config);

  const loanPeriodMarks: SliderMarks = {
    [`${selectedProduct?.minTenure}`]: selectedProduct?.minTenure,
    [`${selectedProduct?.maxTenure}`]: selectedProduct?.maxTenure,
  };

  const loanAmountMarks: SliderMarks = {
    [`${selectedProduct?.minLoanAmount}`]: selectedProduct?.minLoanAmount,
    [`${selectedProduct?.maxLoanAmount}`]: selectedProduct?.maxLoanAmount,
  };

  const onLoanAmthange = (newValue: number | null) => {
    setLoanAmt(newValue ?? 0);
    dispatch(
      setSmeApplicationDraft({
        ...updateSmeApplicationDraftFormData,
        appliedLoanAmount: newValue ?? 0,
      }),
    );
  };

  const onLoanPeriodChange = (newValue: number | null) => {
    setLoanPeriod(newValue ?? 0);
    dispatch(
      setSmeApplicationDraft({
        ...updateSmeApplicationDraftFormData,
        appliedLoanTenure: newValue ?? 0,
      }),
    );
  };

  const onLoanReasonChange = (value: string) => {
    setLoanReasonId(value);
    dispatch(
      setSmeApplicationDraft({
        ...updateSmeApplicationDraftFormData,
        // TODO: set loan id here
        loanReason: value,
      }),
    );
  };

  const onFinish = async () => {
    if (referralCode && !validReferralCode) {
      return message.error(t("testingEligibility:refCodeInvalideErrMsg"));
    }

    if (loanReasonId == null || loanReasonId === "") {
      message.error(t("testingEligibility:loanReasonEmptyErrMsg"));
      return;
    }

    if (
      loanReasonState.data.some(
        (reason) => reason.id === loanReasonId && reason.code === "6",
      ) &&
      (otherReason == null || otherReason === "")
    ) {
      message.error(t("testingEligibility:loanReasonOthersEmptyErrMsg"));
      return;
    }

    if (loanAmt === 0) {
      message.error(t("testingEligibility:loanAmtEmptyErrMsg"));
      return;
    }

    if (loanPeriod === 0) {
      message.error(t("testingEligibility:loanPeriodEmptyErrMsg"));
      return;
    }

    setLoading(true);

    // TODO: Check if the user is eligible for the loan
    // If the user is eligible, then create the application draft

    const decoded: any = jwtDecode(localStorage.getItem("accessToken") ?? "");

    dispatch(
      setSmeApplicationDraft({
        ...updateSmeApplicationDraftFormData,
        redOneUsername: decoded.username,
        status: "DRAFT",
        loanReason: "loan reason",
        // TODO: take from product config
        appliedLoanInterestRate: 18,
        appliedLoanTenure: loanAmt,
        appliedLoanAmount: loanAmt,
      }),
    );
    await dispatch(
      createSmeApplicationDraftThunk({
        redOneUsername: decoded.username,
        loanReason: "loan reason",
        // TODO: take from product config
        appliedLoanInterestRate: 18,
        appliedLoanTenure: loanAmt,
        appliedLoanAmount: loanAmt,
        organizationType: partnerDetails.organizationType,
      }),
    )
      .unwrap()
      .then((res) => {
        dispatch(
          setSmeApplicationDetails({
            ...res,
            id: res?.id,
          }),
        );
        navigate(`/sme/testing-eligibility/result/success`, { replace: true });
      })
      .finally(() => {
        setLoading(false);
      });

    // dispatch(
    //   testEligibilityThunk({
    //     callerId: currentUser?.data?.phoneNo,
    //     nric: currentUser?.data?.nric?.toUpperCase(),
    //   }),
    // )
    //   .unwrap()
    //   .then(async (res) => {
    //     await dispatch(applicationStateReset());
    //     await dispatch(
    //       setApplicationDraft({
    //         ...updateApplicationDraftFormData,
    //         userId: currentUser?.data?.id,
    //         productId: selectedProduct?.id,
    //         loanApplied: loanAmt,
    //         loanTenure: loanPeriod,
    //         loanInterestRate: productState?.selectedProduct?.interestRate ?? 0,
    //         loanReasonId: loanReasonId,
    //       }),
    //     );
    //     await dispatch(
    //       createApplicationDraftThunk({
    //         userId: currentUser?.data?.id,
    //         companyId: process.env.REACT_APP_COMPANY_ID,
    //         productCode: selectedProduct?.code,
    //         productId: selectedProduct?.id,
    //         loanApplied: loanAmt,
    //         loanTenure: loanPeriod,
    //         loanReasonId,
    //         loanInterestRate: productState?.selectedProduct?.interestRate ?? 0,
    //         isCustomReason: otherReason !== null,
    //         customReason: otherReason?.toUpperCase(),
    //         language,
    //         applicantNric: currentUser?.data?.nric,
    //         applicantFirstName: currentUser?.data?.firstName,
    //       }),
    //     )
    //       .unwrap()
    //       .then((res) => {
    //         dispatch(
    //           patchUserApplicationCreatedThunk({
    //             userId: currentUser?.data?.id,
    //           }),
    //         );
    //         dispatch(
    //           setApplicationDetails({
    //             ...applicationDetails?.data,
    //             id: res?.id,
    //           }),
    //         );
    //         navigate(`/testing-eligibility/result/success`, { replace: true });
    //       });
    //   })
    //   .catch(() => navigate(`/testing-eligibility/result/failed`))
    //   .finally(() => setLoading(false));
  };

  const onInsertReferralCode = (value: string) => {
    setReferralCode(value);
  };

  const onOtherReasonInputChange = (value: string) => {
    setOtherReason(value);
  };

  useEffect(() => {
    dispatch(getLoanReasonListThunk());
    dispatch(
      getProductsThunk({
        productCode: localStorage.getItem("productCode"),
      }),
    );
  }, []);

  useEffect(() => {
    let ignore = false;
    if (debouncedReferralCode && !ignore) {
      dispatch(
        getProductByReferralCodeThunk({ referralCode: debouncedReferralCode }),
      )
        .unwrap()
        .then((res) => {
          res == null
            ? setValidReferralCode(false)
            : setValidReferralCode(true);
        });
    }
    return () => {
      ignore = true;
    };
  }, [debouncedReferralCode]);

  return (
    <div className="test-eligibility-form">
      <p>{t("testingEligibility:instruction")}</p>
      <Card className="test-eligibility-card" bordered={false}>
        <Title level={5}>{t("testingEligibility:refCodeInputTitle")}</Title>
        <Input
          placeholder={t("testingEligibility:refCodeInputPlaceholder")}
          onChange={(e) => onInsertReferralCode(e.target.value)}
        />

        {debouncedReferralCode &&
          (validReferralCode ? (
            <span style={{ color: "forestgreen" }}>Valid code!</span>
          ) : (
            <span style={{ color: "red" }}>Invalid code!</span>
          ))}
      </Card>

      <Card className="test-eligibility-card" bordered={false}>
        <Title level={5}>{t("testingEligibility:loanAmountInputTitle")}</Title>
        <Card
          className="test-eligibility-input-card"
          bodyStyle={{
            padding: 16,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span>RM</span>
          <h3>{numberWithCommas(loanAmt ?? 0)}</h3>
        </Card>
        <Slider
          min={selectedProduct?.minLoanAmount}
          max={selectedProduct?.maxLoanAmount}
          step={selectedProduct?.intervalLoanAmount}
          tooltip={{ open: false }}
          onChange={onLoanAmthange}
          marks={loanAmountMarks}
          value={typeof loanAmt === "number" ? loanAmt : 0}
          trackStyle={{ backgroundColor: primaryColor }}
          handleStyle={{ border: `solid 2px ${primaryColor}` }}
        />
      </Card>

      <Card className="test-eligibility-card" bordered={false}>
        <Title level={5}>{t("testingEligibility:loanTenureInputTitle")}</Title>
        <Card
          className="test-eligibility-input-card"
          bodyStyle={{
            padding: 16,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h3>{loanPeriod}</h3>
          <span>
            {loanPeriod > 1
              ? t("testingEligibility:months")
              : t("testingEligibility:month")}
          </span>
        </Card>
        <Slider
          min={selectedProduct?.minTenure}
          max={selectedProduct?.maxTenure}
          step={selectedProduct?.intervalTenure}
          tooltip={{ open: false }}
          onChange={onLoanPeriodChange}
          marks={loanPeriodMarks}
          value={typeof loanPeriod === "number" ? loanPeriod : 0}
          trackStyle={{ backgroundColor: primaryColor }}
          handleStyle={{ border: `solid 2px ${primaryColor}` }}
        />
      </Card>

      <Card className="test-eligibility-card" bordered={false}>
        <Title level={5}>{t("testingEligibility:loanReasonInputTitle")}</Title>
        <Select
          placeholder={t("testingEligibility:loanReasonInputPlaceholder")}
          allowClear
          style={{ width: "100%" }}
          onChange={onLoanReasonChange}
          loading={loanReasonState.isLoading}
          options={
            Array.isArray(loanReasonState.data)
              ? loanReasonState.data.map((item) => ({
                  value: item.id,
                  label: language === LANGUAGE.MS ? item.malayName : item.name,
                }))
              : []
          }
        />

        {loanReasonState.data.some(
          (reason) => reason.id === loanReasonId && reason.code === "6",
        ) && (
          <Input
            style={{ marginTop: 8 }}
            placeholder={t(
              "testingEligibility:loanReasonOthersInputPlaceholder",
            )}
            onChange={(e) => onOtherReasonInputChange(e.target.value)}
          />
        )}
      </Card>

      <div className="submit-section">
        <button
          className="primary-button"
          disabled={loading}
          onClick={onFinish}
        >
          {loading && (
            <LoadingOutlined style={{ fontSize: 28, marginRight: 8 }} />
          )}
          {t("testingEligibility:submitBtnLabel")}
        </button>
      </div>
    </div>
  );
};
