import { AxiosRequestConfig } from "axios";
import { collectionSetupAxiosInstance } from "../../axios/collectionSetupAxios";

const blobConfig: AxiosRequestConfig = { responseType: "blob" };

const getAccountDetails = async (requestData: any) => {
  const url = `/redCash/api/v1.0/accountDetails/agreementNo/${requestData.agreementNo}`;
  const res = await collectionSetupAxiosInstance.get(url);
  return res.data;
};

const getEnglishAccountStatementbyAgreementId = async (requestData: any) => {
  const url = `/redCash/admin/api/v1.0/statement-of-account/en/agreement/${requestData.agreementId}`;
  const res = await collectionSetupAxiosInstance.get(url, blobConfig);
  return res.data;
};

const getMalayAccountStatementbyAgreementId = async (requestData: any) => {
  const url = `/redCash/admin/api/v1.0/statement-of-account/bm/agreement/${requestData.agreementId}`;
  const res = await collectionSetupAxiosInstance.get(url, blobConfig);
  return res.data;
};

const getAccountStatement = async (requestData: any) => {
  const { payload } = requestData;
  const params = {
    currentPage: requestData.params?.pagination.current,
    pageSize: requestData.params?.pagination.pageSize,
    // sortField: requestData.sortField,
    // sortOrder: requestData.sortOrder,
  };
  const url = `/redCash/admin/api/v1.0/statement-of-account/search`;
  const res = await collectionSetupAxiosInstance.post(url, payload, { params });
  return res.data;
};

const getAccountDetailsForAoc = async (requestData: any) => {
  const url = `/redCash/api/v1.0/accountDetails/getAocAgreement/${requestData.agreementNo}`;
  const res = await collectionSetupAxiosInstance.get(url);
  return res.data;
};

const getAccountTenureDetails = async (requestData: any) => {
  const url = `/redCash/api/v1.0/accountTenureDetails/agreementNo/${requestData.agreementNo}`;
  const res = await collectionSetupAxiosInstance.get(url);
  return res.data;
};

const getAccountWaiverCharges = async (requestData: any) => {
  const url = `/redCash/api/v1.0/accountDetails/waiverCharges/accountDetailsId/${requestData}`;
  const res = await collectionSetupAxiosInstance.get<any>(url);
  return res.data;
};
const collectionService = {
  getAccountDetails,
  getEnglishAccountStatementbyAgreementId,
  getMalayAccountStatementbyAgreementId,
  getAccountStatement,
  getAccountDetailsForAoc,
  getAccountTenureDetails,
  getAccountWaiverCharges,
};

export default collectionService;
