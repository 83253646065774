import dayjs from "dayjs";
import moment from "moment";

import type { Moment } from "moment";

export const dateFormat = "DD/MM/YYYY";
export const monthYearFormat = "MM/YYYY";
export const dateTimeFormat = "DD/MM/YYYY HH:mm:ss";
export const timeFormat = "HH:mm:ss";

export const displayDateAndTime = (
  date: string | number | Date | null | undefined,
) => {
  return dayjs(date).format(dateTimeFormat);
};

export const displayDate = (
  date: string | number | Date | null | undefined,
) => {
  return dayjs(date).format(dateFormat);
};

export const displayDateFromString = (date: string | moment.MomentInput) => {
  return moment(date).format(dateFormat);
};

export function isToday(givenDate: Moment): boolean {
  const today = moment();
  return givenDate.isSame(today, "day");
}

export const displayTime = (
  date: string | number | Date | null | undefined,
) => {
  return dayjs(date).format(timeFormat);
};
