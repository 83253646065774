import "./index.less";

import {
  Alert,
  Form,
  Input,
  InputNumber,
  message,
  Space,
  Typography,
} from "antd";
import dayjs from "dayjs";
import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { LoadingOutlined } from "@ant-design/icons";

import { primaryColor } from "../../../../assets/style/variables";
import { authSetupAxiosInstance } from "../../../../axios/authSetupAxios";
import { ApplicationStatusEnum } from "../../../../enums/applicationStepStatus";
import { setAgreementDetails } from "../../../../features/agreement/agreementSlice";
import {
  setApplicationDetails,
  setApplicationDraft,
} from "../../../../features/application/applicationSlice";
import { LANGUAGE, setLanguage } from "../../../../features/config/configSlice";
import {
  setCurrentUser,
  setHasLoggedUser,
  setRegistrationFormDataLogin,
  setRegistrationFormData,
} from "../../../../features/user/userSlice";
import {
  checkValidPhoneNoThunk,
  loginThunk,
  resetPasswordThunk,
} from "../../../../services/userService/userThunk";
import { AppDispatch, RootState } from "../../../../store/store";
import { startCase, styleBrandLabel } from "../../../../utils/stringFormatter";
import { phoneNumberValidator } from "../../../../utils/validation";

import {
  HIDE_RECAPTCHA,
  IS_DEVELOPMENT_MODE,
} from "../../../../utils/environment";
import { setAccountDetails } from "../../../../features/collection/collectionSlice";

const { Title } = Typography;

export type SSOLoginFormProps = {
  callback?: any;
};

const SSOLoginForm: React.FC<SSOLoginFormProps> = ({ callback }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [accountFound, setAccountFound] = useState(true);

  const recaptchaRef = useRef<any>(null);

  const { t } = useTranslation(["common", "login"]);
  const { language } = useSelector((state: RootState) => state.config);
  const { registrationFormData } = useSelector(
    (state: RootState) => state.user,
  );

  const handleCheckPhoneNo = async () => {
    await form
      .validateFields()
      .then(async (values) => {
        setLoading(true);
        await dispatch(
          checkValidPhoneNoThunk({ callerId: `0${values.callerId}` }),
        )
          .unwrap()
          .then((res) => {
            // if (res.isFirstTimeLogin) {
            //   return navigate("/registration");
            // }
            setSubmitted(true);
            setAccountFound(true);
          })
          .catch((err) => {
            setSubmitted(true);
            setAccountFound(false);
          });
      })

      .finally(() => {
        setLoading(false);
      });
  };

  const handleLangChange = (lang: LANGUAGE) => {
    dispatch(setLanguage(lang));
    localStorage.setItem("language", lang);
  };

  const onBypassReCapthaLogin = async () => {
    await form.validateFields().then(async (values) => {
      setLoading(true);

      dispatch(
        loginThunk({
          ...values,
          callerId: `0${values.callerId}`,
        }),
      )
        .unwrap()
        .then(async (res) => {
          console.log("Login success! Injecting Data...");

          localStorage.setItem("productCode", res.productCode);

          localStorage.setItem("redOneJwt", res.redOneJwt);
          if (localStorage.getItem("language") === "MS") {
            handleLangChange(LANGUAGE.MS);
          }

          if (res.systemAccessJwt) {
            localStorage.setItem("accessToken", res.systemAccessJwt);
          }

          if (res.systemRefreshJwt) {
            localStorage.setItem("refreshToken", res.systemRefreshJwt);
          }

          if (res?.accountDetails?.accountDetails != null) {
            await dispatch(
              setAccountDetails(res?.accountDetails.accountDetails),
            );
          }

          if (res.userDetails != null) {
            await dispatch(setHasLoggedUser(true));
            await dispatch(setCurrentUser(res.userDetails));
            !registrationFormData?.currentlyEditing &&
              (await dispatch(setRegistrationFormDataLogin(res.userDetails)));

            if (
              res.userDetails.language === "MS" ||
              res.userDetails.language === "ms" ||
              res.userDetails.language === "BM" ||
              res.userDetails.language === "bm"
            ) {
              handleLangChange(LANGUAGE.MS);
            } else {
              handleLangChange(language);
            }

            // if the application applied by user is rejected before,
            // the user can only apply after allowToApplyNewLoanAfterDateTime
            // similar status check also exist in LoanApplicationStatusCard, case ApplicationStatusEnum.REJECTED
            const allowToApplyNewLoanAfterDateTime =
              res.userDetails?.allowToApplyNewLoanAfterDateTime;

            const isBeforeAllowToApplyNewLoanAfterDateTime =
              allowToApplyNewLoanAfterDateTime != null &&
              dayjs().isBefore(dayjs(allowToApplyNewLoanAfterDateTime));

            if (
              res.applicationDetails.applicationStatus ===
                ApplicationStatusEnum.REJECTED &&
              isBeforeAllowToApplyNewLoanAfterDateTime
            ) {
              return navigate(`/not-allow-new-loan?language=${language}`);
            }

            message.success(t("login:loginSuccessfulMsg"));

            if (
              res.applicationDetails?.applicationStatus ===
              ApplicationStatusEnum.DRAFT
            ) {
              await dispatch(setApplicationDraft(res.applicationDetails));
              await dispatch(setApplicationDetails(res.applicationDetails));
              return navigate(`/home?language=${language}`);
            }

            await dispatch(setApplicationDetails(res.applicationDetails));
            !registrationFormData?.currentlyEditing &&
              (await dispatch(setRegistrationFormDataLogin(res.userDetails)));

            return navigate(`/home?language=${language}`);
          } else {
            // message.error("User not found in system");

            // new register user,
            await dispatch(
              setRegistrationFormData({
                ...registrationFormData,
                phoneNo: values.callerId,
              }),
            );
            return navigate(`/registration?language=${language}`);
          }
        })
        .catch((error) => {
          message.error("Invalid Credentials");
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const onFinish = async () => {
    const recaptchaValue = recaptchaRef.current.getValue();

    if (recaptchaValue === "") {
      message.error(t("login:botCheckRequiredMsg"));
      return;
    }

    await form.validateFields().then(async (values) => {
      setLoading(true);

      authSetupAxiosInstance
        .post("/redCash/auth/api/v1.0/recapthaV2/verify-token", {
          token: recaptchaValue,
        })
        .then((res) => {
          if (res.data.success) {
            dispatch(
              loginThunk({
                ...values,
                callerId: `0${values.callerId}`,
              }),
            )
              .unwrap()
              .then(async (res) => {
                console.log("Login success! Injecting Data...");

                localStorage.setItem("productCode", res.productCode);

                if (localStorage.getItem("language") === "MS") {
                  handleLangChange(LANGUAGE.MS);
                }

                localStorage.setItem("redOneJwt", res.redOneJwt);

                if (res.systemAccessJwt) {
                  localStorage.setItem("accessToken", res.systemAccessJwt);
                }

                if (res.systemRefreshJwt) {
                  localStorage.setItem("refreshToken", res.systemRefreshJwt);
                }

                if (res?.accountDetails?.accountDetails != null) {
                  await dispatch(
                    setAccountDetails(res?.accountDetails.accountDetails),
                  );
                }

                if (res.userDetails != null) {
                  await dispatch(setHasLoggedUser(true));
                  await dispatch(setCurrentUser(res.userDetails));
                  !registrationFormData?.currentlyEditing &&
                    (await dispatch(
                      setRegistrationFormDataLogin(res.userDetails),
                    ));

                  if (
                    localStorage.getItem("language") === "MS" ||
                    res.userDetails.language === "MS" ||
                    res.userDetails.language === "ms" ||
                    res.userDetails.language === "BM" ||
                    res.userDetails.language === "bm"
                  ) {
                    handleLangChange(LANGUAGE.MS);
                  } else {
                    handleLangChange(language);
                  }

                  // if the application applied by user is rejected before,
                  // the user can only apply after allowToApplyNewLoanAfterDateTime
                  // similar status check also exist in LoanApplicationStatusCard, case ApplicationStatusEnum.REJECTED
                  const allowToApplyNewLoanAfterDateTime =
                    res.userDetails?.allowToApplyNewLoanAfterDateTime;

                  const isBeforeAllowToApplyNewLoanAfterDateTime =
                    allowToApplyNewLoanAfterDateTime != null &&
                    dayjs().isBefore(dayjs(allowToApplyNewLoanAfterDateTime));

                  if (
                    res.applicationDetails.applicationStatus ===
                      ApplicationStatusEnum.REJECTED &&
                    isBeforeAllowToApplyNewLoanAfterDateTime
                  ) {
                    return navigate(`/not-allow-new-loan?language=${language}`);
                  }

                  message.success(t("login:loginSuccessfulMsg"));

                  if (
                    res.applicationDetails.applicationStatus ===
                    ApplicationStatusEnum.DRAFT
                  ) {
                    await dispatch(setApplicationDraft(res.applicationDetails));
                    await dispatch(
                      setApplicationDetails(res.applicationDetails),
                    );
                    return navigate(`/home?language=${language}`);
                  }

                  await dispatch(setApplicationDetails(res.applicationDetails));
                  await dispatch(setAgreementDetails(res.agreementDetails));
                  !registrationFormData?.currentlyEditing &&
                    (await dispatch(
                      setRegistrationFormDataLogin(res.userDetails),
                    ));

                  return navigate(`/home?language=${language}`);
                } else {
                  // message.error("User not found in system");

                  // new register user,
                  await dispatch(
                    setRegistrationFormData({
                      ...registrationFormData,
                      phoneNo: values.callerId,
                    }),
                  );
                  return navigate(`/registration?language=${language}`);
                }
              })
              .catch((error) => {
                message.error("Invalid Credentials");
              })
              .finally(() => {
                recaptchaRef?.current?.reset();
                setLoading(false);
              });
          } else {
            setLoading(false);
          }
        })
        .catch(() => {
          message.error(t("login:recapthaKeyInvalidMsg"));
          setLoading(false);
        });
    });
  };

  const byPassLogin = async () => {
    setLoading(true);

    await form.validateFields().then(async (values) => {
      dispatch(
        loginThunk({
          ...values,
          callerId: `0${values.callerId}`,
        }),
      )
        .unwrap()
        .then(async (res) => {
          console.log("Login success! Injecting Data...");

          localStorage.setItem("productCode", res.productCode);

          if (localStorage.getItem("language") === "MS") {
            handleLangChange(LANGUAGE.MS);
          }

          localStorage.setItem("redOneJwt", res.redOneJwt);

          if (res.systemAccessJwt) {
            localStorage.setItem("accessToken", res.systemAccessJwt);
          }

          if (res.systemRefreshJwt) {
            localStorage.setItem("refreshToken", res.systemRefreshJwt);
          }

          if (res?.accountDetails?.accountDetails != null) {
            await dispatch(
              setAccountDetails(res?.accountDetails.accountDetails),
            );
          }

          if (res.userDetails != null) {
            await dispatch(setHasLoggedUser(true));
            await dispatch(setCurrentUser(res.userDetails));
            !registrationFormData?.currentlyEditing &&
              (await dispatch(setRegistrationFormDataLogin(res.userDetails)));

            if (
              localStorage.getItem("language") === "MS" ||
              res.userDetails.language === "MS" ||
              res.userDetails.language === "ms" ||
              res.userDetails.language === "BM" ||
              res.userDetails.language === "bm"
            ) {
              handleLangChange(LANGUAGE.MS);
            } else {
              handleLangChange(language);
            }

            // if the application applied by user is rejected before,
            // the user can only apply after allowToApplyNewLoanAfterDateTime
            // similar status check also exist in LoanApplicationStatusCard, case ApplicationStatusEnum.REJECTED
            const allowToApplyNewLoanAfterDateTime =
              res.userDetails?.allowToApplyNewLoanAfterDateTime;

            const isBeforeAllowToApplyNewLoanAfterDateTime =
              allowToApplyNewLoanAfterDateTime != null &&
              dayjs().isBefore(dayjs(allowToApplyNewLoanAfterDateTime));

            if (
              res.applicationDetails.applicationStatus ===
                ApplicationStatusEnum.REJECTED &&
              isBeforeAllowToApplyNewLoanAfterDateTime
            ) {
              return navigate(`/not-allow-new-loan?language=${language}`);
            }

            message.success(t("login:loginSuccessfulMsg"));

            if (
              res.applicationDetails.applicationStatus ===
              ApplicationStatusEnum.DRAFT
            ) {
              await dispatch(setApplicationDraft(res.applicationDetails));
              await dispatch(setApplicationDetails(res.applicationDetails));
              return navigate(`/home?language=${language}`);
            }

            await dispatch(setApplicationDetails(res.applicationDetails));
            await dispatch(setAgreementDetails(res.agreementDetails));
            !registrationFormData?.currentlyEditing &&
              (await dispatch(setRegistrationFormDataLogin(res.userDetails)));

            return navigate(`/home?language=${language}`);
          } else {
            // message.error("User not found in system");

            // new register user,
            await dispatch(
              setRegistrationFormData({
                ...registrationFormData,
                phoneNo: values.callerId,
              }),
            );
            return navigate(`/registration?language=${language}`);
          }
        })
        .catch((error) => {
          message.error("Invalid Credentials");
        })
        .finally(() => {
          recaptchaRef?.current?.reset();
          setLoading(false);
        });
    });
  };

  const sendTempPasswordLink = () => {
    if (!form.getFieldsValue().callerId) {
      message.error(t("login:phoneRequiredMsg"));
      return;
    }

    message.success(t("login:sendingTempPasswordMsg"));

    dispatch(
      resetPasswordThunk({ callerId: `0${form.getFieldsValue().callerId}` }),
    )
      .unwrap()
      .then((res) => {
        message.success(t("login:tempPasswordSentMsg"));
      });
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      accountFound ? onFinish() : handleCheckPhoneNo();
    }
  };

  return (
    <>
      <Title level={4} className="login-modal-title">
        {styleBrandLabel(t("login:redONE_Customer"))}
      </Title>
      <p>
        {accountFound
          ? t("login:passwordRequiredMsg")
          : t("login:phoneRequiredMsg")}
      </p>

      <Form
        form={form}
        id="loginForm"
        name="loginForm"
        requiredMark="optional"
        layout="vertical"
        // validateMessages={validateMessages}
        scrollToFirstError
      >
        <Form.Item
          name="callerId"
          label={startCase(t("login:Phone_Number"))}
          validateFirst={true}
          rules={[
            { required: true, message: t("login:phoneRequiredMsg") },
            { validator: (_, value) => phoneNumberValidator(`${value}`) },
          ]}
        >
          <InputNumber
            type={"number"}
            className="phone-input-with-prefix"
            addonBefore="(MY)+60"
            placeholder={t("login:phoneInputPlaceholder")}
            style={{ width: "100%" }}
            onKeyDown={handleKeyDown}
            inputMode="numeric"
            controls={false}
            stringMode
          />
        </Form.Item>
        {submitted && !accountFound && !loading && (
          <Alert
            className="alert-card"
            message={
              <span style={{ color: primaryColor }}>
                {t("login:phoneInvalidMsg")}
              </span>
            }
            type="error"
            showIcon
          />
        )}
        {accountFound && (
          <>
            <Form.Item
              name="password"
              label={startCase(t("login:Password"))}
              validateFirst={true}
              rules={[
                {
                  required: true,
                  message: t("login:passwordRequiredMsg"),
                },
              ]}
            >
              <Input.Password
                placeholder={t("login:inputPlaceholder")}
                onKeyDown={handleKeyDown}
              />
            </Form.Item>

            <div className="flex-center">
              <button
                className="text-button"
                type={"button"}
                onClick={() => sendTempPasswordLink()}
              >
                {startCase(t("login:Forgot_password"))}
              </button>
            </div>

            {!HIDE_RECAPTCHA && (
              <>
                <div className="flex justify-center mt-4">
                  {process.env.REACT_APP_RECAPTHA_SITE_KEY ? (
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={process.env.REACT_APP_RECAPTHA_SITE_KEY}
                    />
                  ) : (
                    <Alert
                      message={t("login:recapthaKeyMissingMsg")}
                      type="error"
                    />
                  )}
                </div>
              </>
            )}
          </>
        )}

        <div className="login-submit">
          {accountFound ? (
            <Space direction={"vertical"} style={{ width: "100%" }}>
              {IS_DEVELOPMENT_MODE && (
                <button
                  type="button"
                  className="primary-button"
                  disabled={loading}
                  onClick={onBypassReCapthaLogin}
                >
                  {loading && (
                    <LoadingOutlined style={{ fontSize: 28, marginRight: 8 }} />
                  )}
                  Bypass reCAPTHA Login
                </button>
              )}
              <button
                type="button"
                className="primary-button"
                disabled={loading}
                onClick={!HIDE_RECAPTCHA ? onFinish : byPassLogin}
              >
                {loading && (
                  <LoadingOutlined style={{ fontSize: 28, marginRight: 8 }} />
                )}
                {t("login:Login")}
              </button>
            </Space>
          ) : (
            <button
              type="button"
              className="primary-button"
              disabled={loading}
              onClick={handleCheckPhoneNo}
            >
              {loading && (
                <LoadingOutlined style={{ fontSize: 28, marginRight: 8 }} />
              )}
              {t("login:Submit")}
            </button>
          )}
        </div>
      </Form>
    </>
  );
};

export default SSOLoginForm;
