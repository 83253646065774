import { beSetupAxiosInstance } from "../../axios/beSetupAxios";
import { RazerPaymentRequestBody } from "../../features/payment/paymentSlice";

const postRazerMerchantPaymentRequest = async (
  requestData: RazerPaymentRequestBody,
) => {
  const url = `/redCash/api/v1.0/razer`;

  const res = await beSetupAxiosInstance.post(url, requestData);
  return res.data;
};

const getPaymentHistory = async (requestDate: any) => {
  const { agreementNo } = requestDate;
  const url = `/redCash/api/v1.0/paymentHistory/agreementNo/${agreementNo}`;
  const res = await beSetupAxiosInstance.get(url);
  return res.data;
};

const applicationService = {
  postRazerMerchantPaymentRequest,
  getPaymentHistory,
};

export default applicationService;
