import { authSetupAxiosInstance } from "../axios/authSetupAxios";

export const refreshAccessToken = async () => {
  const { data } = await authSetupAxiosInstance.post(
    `/redCash/auth/api/v1.0/refresh`,
    { refreshToken: localStorage.getItem("refreshToken") },
    { headers: { "Content-Type": "application/json" } }
  );
  localStorage.setItem("accessToken", data.accessToken);
  localStorage.setItem("refreshToken", data.refreshToken);
};
