import { Alert, Space } from "antd";
import { useTranslation } from "react-i18next";
import { createBreakpoint } from "react-use";

const useBreakpoint = createBreakpoint();

const TemporaryBannedStatusContent = () => {
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === "tablet";
  const { t } = useTranslation(["common", "loanStatus"]);

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Alert
        className="alert-card"
        style={{ textAlign: isMobile ? "center" : "left" }}
        message={
          <h3 className="alert-title-error">
            {t("loanStatus:temporary_banned_title")}
          </h3>
        }
        description={
          <p style={{ margin: 0 }}>
            {t("loanStatus:temporary_banned_description")}
          </p>
        }
        type="error"
      />
    </Space>
  );
};

export default TemporaryBannedStatusContent;
