import { ekycSetupAxiosInstance } from "../../axios/ekycSetupAxios";

const checkEkycResultByUserId = async (requestData: any) => {
  const { userId } = requestData;
  const url = `/redCash/api/v1.0/wiseai/user/${userId}/exist`;
  const res = await ekycSetupAxiosInstance.get(url);
  return res.data;
};

const getEkycResultDetailsByUserId = async (requestData: any) => {
  const { userId } = requestData;
  const url = `/redCash/api/v1.0/wiseai/result/${userId}`;
  const res = await ekycSetupAxiosInstance.get(url);
  return res.data;
};

const checkEkycResultByApplicationId = async (requestData: any) => {
  const { applicationId } = requestData;
  const url = `/redCash/api/v1.0/wiseai/checkResultExist/applicationId/${applicationId}`;
  const res = await ekycSetupAxiosInstance.get(url);
  return res.data;
};

const getEkycResultByApplicationId = async (requestData: any) => {
  const { applicationId } = requestData;
  const url = `/redCash/api/v1.0/wiseai/applicationResult/applicationId/${applicationId}`;
  const res = await ekycSetupAxiosInstance.get(url);
  return res.data;
};

const ekycService = {
  checkEkycResultByUserId,
  getEkycResultDetailsByUserId,
  checkEkycResultByApplicationId,
  getEkycResultByApplicationId,
};

export default ekycService;
