import { Alert, Space } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createBreakpoint } from "react-use";

import { setApplicationDetails } from "../../../features/application/applicationSlice";
import { numberWithCommas } from "../../../helpers/number";
import { createApplicationDraftThunk } from "../../../services/applicationService/applicationThunk";
import { AppDispatch, RootState } from "../../../store/store";
import { patchUserApplicationCreatedThunk } from "../../../services/userService/userThunk";

const useBreakpoint = createBreakpoint();

const LoanCompletedStatusContent = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === "tablet";
  const { t } = useTranslation(["common", "loanStatus"]);
  const [loading, setLoading] = useState(false);

  const { currentUser } = useSelector((state: RootState) => state.user);
  const { productState } = useSelector((state: RootState) => state.product);
  const { updateApplicationDraftFormData, applicationDetails } = useSelector(
    (state: RootState) => state.application,
  );
  const { language } = useSelector((state: RootState) => state.config);

  const proceedToApplication = () => {
    setLoading(true);
    const firstProduct = productState.data.find(
      (element) => element !== undefined,
    );
    if (
      updateApplicationDraftFormData.loanApplied &&
      updateApplicationDraftFormData.loanTenure &&
      updateApplicationDraftFormData.loanReasonId
    ) {
      dispatch(
        createApplicationDraftThunk({
          userId: currentUser?.data?.id,
          companyId: process.env.REACT_APP_COMPANY_ID,
          productCode: firstProduct?.code,
          productId: firstProduct?.id,
          isCustomReason:
            updateApplicationDraftFormData?.isCustomReason ?? false,
          customReason:
            updateApplicationDraftFormData?.customReason?.toUpperCase() ?? null,
          language: language,
          applicantNric: currentUser?.data?.nric,
          applicantFirstName: currentUser?.data?.firstName,
        }),
      )
        .unwrap()
        .then((res) => {
          dispatch(
            patchUserApplicationCreatedThunk({ userId: currentUser?.data?.id }),
          );
          dispatch(
            setApplicationDetails({ ...applicationDetails?.data, id: res?.id }),
          );
          navigate(`/application`);
        })
        .finally(() => setLoading(false));
    } else {
      navigate(`/testing-eligibility`);
    }
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <div
        className="flex-center-vertical"
        style={{ alignItems: isMobile ? "center" : "flex-start" }}
      >
        <span>{t("loanStatus:Your_Approved_Loan_Amount")}</span>
        <h1>
          RM {numberWithCommas(updateApplicationDraftFormData.loanApplied ?? 0)}
        </h1>
      </div>
      <Alert
        className="alert-card"
        style={{ textAlign: isMobile ? "center" : "left" }}
        message={
          <h3 className="alert-title-success">
            {t("loanStatus:loan_completed_title")}
          </h3>
        }
        description={
          <p style={{ margin: 0 }}>
            {t("loanStatus:loan_completed_description")}
          </p>
        }
        type="success"
      />
      <button
        className="primary-button"
        disabled={loading}
        onClick={proceedToApplication}
      >
        {t("loanStatus:Apply_New_Loan")}
      </button>
    </Space>
  );
};

export default LoanCompletedStatusContent;
