import groupBy from "lodash.groupby";

import { createSlice } from "@reduxjs/toolkit";

import { ApplicationStepStatusEnum } from "../../enums/applicationStepStatus";
import {
  createApplicationDraftThunk,
  getApplicationStepStatusThunk,
  getApplicationDetailsThunk,
  updateApplicationThunk,
} from "../../services/applicationService/applicationThunk";
import { getFilesByEntityIdThunk } from "../../services/fileService/fileThunk";
import { Agreement } from "../agreement/agreementSlice";
import {
  Bank,
  EmployeeType,
  LoanReason,
  Relationship,
} from "../dropdownParameter/dropdownParameterSlice";
import { Product } from "../product/productSlice";
import { User } from "../user/userSlice";

import type { PayloadAction } from "@reduxjs/toolkit";
import { LANGUAGE } from "../config/configSlice";

export type CreateApplicationDraftPayload = {
  userId?: string;
  companyId?: string;
  productCode?: string;
  productId?: string;
  loanApplied?: number;
  loanTenure?: number;
  loanReasonId?: string;
  loanInterestRate?: number;
  productReferralCode?: string | null;
  isCustomReason: boolean;
  customReason?: string | null;
  language: LANGUAGE;
  applicantNric?: string;
  applicantFirstName?: string;
};

export type Application = {
  applicationFormFileId?: string;
  applicantAcceptTncAndPp?: boolean;
  applicantAcceptMarketing?: boolean;
  applicantAcceptSharePersonalInfo?: boolean;
  productName?: string;
  productCode?: string;
  productMinAgeChecking?: number;
  productMaxAgeChecking?: number;
  productMinGrossIncomeChecking?: number;
  productInterestRate?: number;
  productMinTenure?: number;
  productMaxTenure?: number;
  productIntervalTenure?: number;
  productStampingDutyPercentage?: number;
  productProcessingFee?: number;
  productReferralCode?: string;
  productProcessingFeeToDiscount?: number;

  applicantFirstName?: string;
  applicantNric?: string;
  applicantPhoneNo?: string;
  applicantAddress1?: string;
  applicantAddress2?: string;
  applicantAddress3?: string;
  applicantCity?: string;
  applicantPostcode?: string | number;
  applicantState?: string;
  applicantEmail?: string;
  applicantGrossSalaryRange?: string;
  applicantRace?: string;
  experianSpkccsFileId?: string;
  experianIrissFileId?: string;
  experianNvrbFileId?: string;
  experianAmlaFileId?: string;
  customerType?: string;
  loanType?: string;
  id?: string;
  applicationNo?: string;
  userId?: string;
  adminId?: string;
  agreementId?: string;
  productId?: string;
  applicationStatus?: string;
  remarks?: string;
  loanReasonId?: string;
  employmentTypeId?: string;
  personalBankId?: string;
  personalBankAccountNo?: string;
  actualMonthlySalary?: number;
  employerName?: string;
  employerContactNo?: string;
  employerContactNoGroup?: {
    countryCode?: string;
    contactNo?: number;
  };
  jobTitle?: string;
  emergencyContactName?: string;
  emergencyContactRelationshipId?: string;
  emergencyContactPersonEmail?: string;
  emergencyContactNo?: string;
  emergencyContactNoGroup?: {
    countryCode?: string;
    contactNo?: number;
  };
  secondEmergencyContactName?: string;
  secondEmergencyContactRelationshipId?: string;
  secondEmergencyContactPersonEmail?: string;
  secondEmergencyContactNo?: string;
  secondEmergencyContactNoGroup?: {
    countryCode?: string;
    contactNo?: number;
  };
  employmentStartingFrom?: string;
  loanApplied: number;
  eligibleLoanAmount?: number;
  financeAmount?: number;
  loanTenure?: number;
  loanInterestRate?: number;
  submissionDate?: string;
  dsr?: number;
  bucket?: string;
  createdAt?: string;
  updatedAt?: string;
  user?: User;
  agreement?: Agreement;
  admin?: { username?: string };
  product?: Product;
  loanReason?: LoanReason;
  employmentType?: EmployeeType;
  personalBank?: Bank;
  emergencyContactRelationship?: Relationship;
  applicantLoanReason?: string;
  isCustomReason?: boolean;
  customReason?: string | null;
  isESignDone?: boolean;
  secondEmergencyContactRelationship?: Relationship | undefined;
};

export type UploadedFile = {
  id: string;
  entityId: string;
  filename: string;
  originalName: string;
  mimeType: string;
  fileTypeId: string;
  fileType: {
    id: string;
    name: string;
    description?: string;
    fileSizeLimit?: number;
    createdAt?: string;
    updatedAt?: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type ApplicationStepStatus = {
  id: string;
  name: ApplicationStepStatusEnum;
  status: boolean;
  applicationId: string;
  createdAt: string;
  updatedAt: string;
};

export type ApplicationState = {
  userFilledReferralCode?: boolean;
  cancelledApplication?: boolean;
  applicationStepStatus: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: ApplicationStepStatus[];
  };
  // application page form data
  updateApplicationDraftFormData: Application;
  applicationDetails: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data: Application;
  };
  // uploaded document list in document upload page
  // & application summary page
  applicationFiles: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    unGroupData: UploadedFile[];
    data: {
      salaryslip?: UploadedFile[];
      bankstatement?: UploadedFile[];
      othersrelevantdocuments?: UploadedFile[];
    };
  };
};

const initialState: ApplicationState = {
  userFilledReferralCode: false,
  cancelledApplication: false,
  updateApplicationDraftFormData: {
    userId: undefined,
    agreementId: undefined,
    jobTitle: undefined,
    applicationStatus: undefined,
    remarks: undefined,
    employmentTypeId: undefined,
    personalBankId: undefined,
    personalBankAccountNo: undefined,
    actualMonthlySalary: undefined,
    productId: undefined,
    employerName: undefined,
    employerContactNo: undefined,
    emergencyContactName: undefined,
    emergencyContactNo: undefined,
    emergencyContactRelationshipId: undefined,
    emergencyContactPersonEmail: undefined,
    secondEmergencyContactName: undefined,
    secondEmergencyContactNo: undefined,
    secondEmergencyContactRelationshipId: undefined,
    secondEmergencyContactPersonEmail: undefined,
    employmentStartingFrom: undefined,
    loanApplied: 0,
    financeAmount: 0,
    loanTenure: 0,
    loanInterestRate: 0,
    submissionDate: "",
    dsr: 0,
    bucket: "",
    loanReasonId: undefined,
    customReason: undefined,
    isCustomReason: false,
  },
  applicationStepStatus: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: [],
  },
  applicationDetails: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: {
      id: undefined,
      applicationNo: undefined,
      userId: undefined,
      agreementId: undefined,
      jobTitle: undefined,
      applicationStatus: undefined,
      remarks: undefined,
      employmentTypeId: undefined,
      personalBankId: undefined,
      personalBankAccountNo: undefined,
      actualMonthlySalary: 0,
      productId: undefined,
      employerName: undefined,
      employerContactNo: undefined,
      emergencyContactName: undefined,
      emergencyContactNo: undefined,
      emergencyContactRelationshipId: undefined,
      emergencyContactPersonEmail: undefined,
      secondEmergencyContactName: undefined,
      secondEmergencyContactNo: undefined,
      secondEmergencyContactRelationshipId: undefined,
      secondEmergencyContactPersonEmail: undefined,
      employmentStartingFrom: undefined,
      loanApplied: 0,
      financeAmount: 0,
      loanTenure: 0,
      loanInterestRate: 0,
      submissionDate: undefined,
      dsr: 0,
      bucket: undefined,
      loanReasonId: undefined,
      isCustomReason: false,
      customReason: undefined,
    },
  },
  applicationFiles: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    unGroupData: [],
    data: {
      salaryslip: [],
      bankstatement: [],
      othersrelevantdocuments: [],
    },
  },
};

export const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    setCancelApplication: () => ({
      ...initialState,
      cancelledApplication: true,
    }),
    setApplicationDraft: (state, action: PayloadAction<Application>) => {
      state.updateApplicationDraftFormData = action.payload;
    },
    setApplicationDetails: (state, action: PayloadAction<Application>) => {
      state.applicationDetails.data = action.payload;
    },
    setUserFilledReferralCodeState: (state, action: PayloadAction<boolean>) => {
      state.userFilledReferralCode = action.payload;
    },
    applicationStateReset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getApplicationDetailsThunk.pending, (state) => {
        state.applicationDetails.isLoading = true;
      })
      .addCase(getApplicationDetailsThunk.fulfilled, (state, action) => {
        state.applicationDetails.isLoading = false;
        state.applicationDetails.data = action.payload;
      })
      .addCase(getApplicationDetailsThunk.rejected, (state, action) => {
        state.applicationDetails.isLoading = false;
        state.applicationDetails.isError = true;
        state.applicationDetails.errorMessage = action.payload;
      });

    builder
      .addCase(updateApplicationThunk.pending, (state) => {
        state.applicationDetails.isLoading = true;
      })
      .addCase(updateApplicationThunk.fulfilled, (state, action) => {
        state.applicationDetails.isLoading = false;
        state.applicationDetails.data = action.payload;
        state.updateApplicationDraftFormData = action.payload;
      })
      .addCase(updateApplicationThunk.rejected, (state, action) => {
        state.applicationDetails.isLoading = false;
        state.applicationDetails.isError = true;
        state.applicationDetails.errorMessage = action.payload;
      });

    builder
      .addCase(createApplicationDraftThunk.pending, (state) => {
        state.applicationDetails.isLoading = true;
      })
      .addCase(createApplicationDraftThunk.fulfilled, (state, action) => {
        state.applicationDetails.isLoading = false;
        state.applicationDetails.data = action.payload;
      })
      .addCase(createApplicationDraftThunk.rejected, (state, action) => {
        state.applicationDetails.isLoading = false;
        state.applicationDetails.isError = true;
        state.applicationDetails.errorMessage = action.payload;
      });

    builder
      .addCase(getApplicationStepStatusThunk.pending, (state) => {
        state.applicationStepStatus.isLoading = true;
      })
      .addCase(getApplicationStepStatusThunk.fulfilled, (state, action) => {
        state.applicationStepStatus.isLoading = false;
        state.applicationStepStatus.data = action.payload;
      })
      .addCase(getApplicationStepStatusThunk.rejected, (state, action) => {
        state.applicationStepStatus.isLoading = false;
        state.applicationStepStatus.isError = true;
        state.applicationStepStatus.errorMessage = action.payload;
      });

    builder
      .addCase(getFilesByEntityIdThunk.pending, (state) => {
        state.applicationFiles.isLoading = true;
      })
      .addCase(getFilesByEntityIdThunk.fulfilled, (state, action) => {
        state.applicationFiles.isLoading = false;
        state.applicationFiles.unGroupData = action.payload;
        state.applicationFiles.data = groupBy(
          action.payload,
          (file: UploadedFile) => file.fileType.name.toLowerCase(),
        );
      })
      .addCase(getFilesByEntityIdThunk.rejected, (state, action) => {
        state.applicationFiles.isLoading = false;
        state.applicationFiles.isError = true;
        state.applicationFiles.errorMessage = action.payload;
      });
  },
});

export const {
  setApplicationDraft,
  setCancelApplication,
  applicationStateReset,
  setApplicationDetails,
  setUserFilledReferralCodeState,
} = applicationSlice.actions;

export default applicationSlice.reducer;
