import { createAsyncThunk } from "@reduxjs/toolkit";

import { restructureErrorMessage } from "../../helpers/errorMessage";
import loanHistoryService from "./loanHistoryService";

export const getLoanHistoryThunk = createAsyncThunk(
  "loanHistory/list",
  async (requestData, thunkAPI) => {
    try {
      return await loanHistoryService.getLoanHistory();
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
