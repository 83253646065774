import React from "react";
import { WebRegistrationLayout } from "../../../layouts/web/WebRegistrationLayout";

import "./WebSmeTestingEligibilityPage.less";
import { useNavigate } from "react-router";
import { PageHeader } from "antd";
import { useTranslation } from "react-i18next";
import { SmeTestingEligibilityForm } from "../../../components/SmeTestingEligibilityForm";
import { ORGANIZATION_TYPE } from "../../../enums/partnerType";

const WebSmeTestingEligibilityPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["common", "testingEligibility"]);

  return (
    <WebRegistrationLayout>
      <div className="test-eligibility-frame">
        <PageHeader
          style={{ padding: 12 }}
          onBack={() => navigate("/sme/registration")}
          title={<strong>{t("testingEligibility:title")}</strong>}
        />
        <SmeTestingEligibilityForm />
      </div>
    </WebRegistrationLayout>
  );
};

export default WebSmeTestingEligibilityPage;
