import { NavBar } from "antd-mobile";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SmeDocumentUploadForm } from "../../../components/SmeDocumentUploadForm";
import { ORGANIZATION_TYPE } from "../../../enums/partnerType";

const MobileSmeIndividualDocumentUploadPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["common", "documentUpload"]);

  return (
    <>
      <NavBar onBack={() => navigate("/application")}>
        <strong>{t("documentUpload:pageTitle")}</strong>
      </NavBar>
      <SmeDocumentUploadForm />
    </>
  );
};

export default MobileSmeIndividualDocumentUploadPage;
