import "./index.less";

import { Button, Card, List } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { primaryColor } from "../../assets/style/variables";
import { numberWithCommas } from "../../helpers/number";
import { getAgreementDetailsThunk } from "../../services/agreementService/agreementThunk";
import { getApplicationDetailsThunk } from "../../services/applicationService/applicationThunk";
import { AppDispatch, RootState } from "../../store/store";
import {
  onCalculateEstimatedMonthlyAmount,
  onCalculateNetDisbursementAmount,
  onCalculateStampingDutyFee,
  onCalculateTotalPremium,
} from "../../utils/calculation";
import { startCase } from "../../utils/stringFormatter";
import { getLoanInsuranceForUserThunk } from "../../services/loanInsuranceService/loanInsuranceThunk";
import { getSmeAgreementByIdThunk } from "../../services/smeAgreementService/smeAgreementThunk";

const cardBodyStyle = { padding: "8px 16px" };

export const SmeApprovedLoanSummaryContent = ({
  showLoanSummary,
}: {
  showLoanSummary: boolean;
}) => {
  const [isLoanSummaryMode, setIsLoanSummaryMode] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const { t } = useTranslation([
    "common",
    "approvedLoanSummary",
    "applicationSummary",
  ]);

  const { applicationDetails } = useSelector(
    (state: RootState) => state.smeApplication,
  );
  const { agreementDetails } = useSelector(
    (state: RootState) => state.smeAgreement,
  );
  const { userLoanInsuranceDetails } = useSelector(
    (state: RootState) => state.user,
  );

  const fetchApplicationDetailsThunk = (applicationId: string) => {
    dispatch(getApplicationDetailsThunk({ applicationId }));
  };

  const fetchAgreementDetailsThunk = () => {
    dispatch(
      getSmeAgreementByIdThunk({
        id: applicationDetails?.data?.smeAgreementId,
      }),
    );
  };

  const initialCallbackState = {
    fetchApplicationDetailsThunk,
    fetchAgreementDetailsThunk,
  };

  const [callbackState] = useState(initialCallbackState);

  // useEffect(() => {
  //   if (applicationDetails?.data?.id) {
  //     callbackState.fetchApplicationDetailsThunk(applicationDetails?.data?.id);
  //     dispatch(
  //       getLoanInsuranceForUserThunk(applicationDetails?.data?.loanTenure),
  //     );
  //   }
  //   if (applicationDetails?.data?.agreementId) {
  //     callbackState.fetchAgreementDetailsThunk();
  //   }
  // }, [
  //   applicationDetails?.data?.id,
  //   applicationDetails?.data?.agreementId,
  //   callbackState,
  // ]);

  // useEffect(() => {
  //   const isLoanSummaryFromUrl = new URLSearchParams(location.search).get(
  //     "showLoanSummary",
  //   );
  //   if (isLoanSummaryFromUrl) {
  //     if (isLoanSummaryFromUrl === "1") {
  //       setIsLoanSummaryMode(true);
  //     } else {
  //       setIsLoanSummaryMode(false);
  //     }
  //   } else {
  //     setIsLoanSummaryMode(showLoanSummary);
  //   }
  // }, [location.search, showLoanSummary]);

  return (
    <div className="approved-loan-summary-content">
      <Card className="mobile-summary-card" bodyStyle={cardBodyStyle}>
        <List>
          <List.Item>
            <strong>{t("approvedLoanSummary:Approved_Loan_Amount")}</strong>
            <strong>
              {`RM ${numberWithCommas(
                applicationDetails?.data?.financeAmount ?? 0,
              )}`}
            </strong>
          </List.Item>
          <List.Item>
            <span>{t("approvedLoanSummary:Interest_Rate")}</span>
            <span>{`${applicationDetails?.data?.financeAmount}% ${t(
              "approvedLoanSummary:per_annum",
            )}`}</span>
          </List.Item>
          <List.Item>
            <span>{t("approvedLoanSummary:Tenure")}</span>
            <span>{`${agreementDetails?.data?.loanTenure} ${t(
              "approvedLoanSummary:months",
            )}`}</span>
          </List.Item>
        </List>
        <List>
          <List.Item>
            <b>{t("approvedLoanSummary:Standard_Deductions")}</b>
          </List.Item>
          <List.Item>
            <span>{t("approvedLoanSummary:Stamp_Duty")}</span>
            <span>
              {onCalculateStampingDutyFee(
                applicationDetails?.data?.financeAmount ?? 0,
                applicationDetails?.data?.stampingDutyPercentage ?? 0,
              )
                ? `RM ${numberWithCommas(
                    onCalculateStampingDutyFee(
                      applicationDetails.data.financeAmount ?? 0,
                      applicationDetails.data.stampingDutyPercentage ?? 0,
                    ),
                  )}`
                : "-"}
            </span>
          </List.Item>
          <List.Item>
            <div className="flex-start-vertical">
              <span>{t("approvedLoanSummary:Processing_Fee")}</span>
            </div>
            <span>
              {applicationDetails.data.processingFee
                ? `RM ${numberWithCommas(
                    applicationDetails.data.processingFee,
                  )}`
                : "-"}
            </span>
          </List.Item>
          <List.Item>
            <div className="flex-start-vertical">
              <span>{t("approvedLoanSummary:loanProtectionPlan")}</span>
            </div>
            <div>
              RM{" "}
              {numberWithCommas(userLoanInsuranceDetails.data?.amountWithTax)}
            </div>
          </List.Item>
        </List>
        {/*TODO: add referral code*/}
        {/*  {applicationDetails?.data?.productReferralCode !== null && (*/}
        {/*    <List>*/}
        {/*      <List.Item>*/}
        {/*        <strong>{`${startCase(*/}
        {/*          t("approvedLoanSummary:Referral_Waiver"),*/}
        {/*        )} :`}</strong>*/}
        {/*      </List.Item>*/}
        {/*      <List.Item>*/}
        {/*        <div className="flex-start-vertical">*/}
        {/*          <span>{`${startCase(*/}
        {/*            t("approvedLoanSummary:Campaign_Code"),*/}
        {/*          )} :`}</span>*/}
        {/*          <span style={{ color: primaryColor }}>*/}
        {/*            {applicationDetails.data.productReferralCode}*/}
        {/*          </span>*/}
        {/*        </div>*/}
        {/*        <span>*/}
        {/*          RM{" "}*/}
        {/*          {numberWithCommas(*/}
        {/*            applicationDetails.data.productProcessingFeeToDiscount ?? 0,*/}
        {/*          )}*/}
        {/*        </span>*/}
        {/*      </List.Item>*/}
        {/*    </List>*/}
        {/*  )}*/}
      </Card>

      <Card className="mobile-summary-card" bodyStyle={cardBodyStyle}>
        <List>
          <List.Item>
            <strong style={{ maxWidth: 200 }}>
              {t("approvedLoanSummary:Loan_amount_receive")}
            </strong>
            <strong>
              {`RM ${numberWithCommas(
                onCalculateNetDisbursementAmount(
                  applicationDetails?.data?.financeAmount ?? 0,
                  applicationDetails.data.processingFee ?? 0,
                  applicationDetails?.data?.stampingDutyPercentage ?? 0,
                  0,
                  applicationDetails?.data?.appliedLoanTenure ?? 0,
                ),
              )}`}
            </strong>
          </List.Item>
        </List>
      </Card>

      <Card className="mobile-summary-card" bodyStyle={cardBodyStyle}>
        <div className="flex-center-vertical monthly-instalment-card">
          <h3>{t("approvedLoanSummary:Monthly_Instalment")}</h3>
          <h1>
            {`RM ${numberWithCommas(
              onCalculateEstimatedMonthlyAmount(
                applicationDetails?.data?.financeAmount ?? 0,
                agreementDetails?.data?.loanInterestRate ?? 0,
                agreementDetails?.data?.loanTenure ?? 0,
              ),
            )}`}
          </h1>
        </div>
      </Card>
      <br />
      {isLoanSummaryMode === true && (
        <>
          <button
            type="submit"
            className="primary-button mb-16"
            onClick={() => navigate(`/loan-status/attestation-option`)}
          >
            {t("approvedLoanSummary:submitBtnLabel")}
          </button>

          <>
            <div className="loan-Insurance-Message">
              <span>{t("applicationSummary:loanProtectionPlanMessage7")}</span>
            </div>
            <div className="loan-Insurance-Message">
              <span>
                {t("applicationSummary:loanProtectionPlanMessage8")}
                <a href="https://www.zurich.com.my" target="_blank">
                  <u> Zurich General Insurance Malaysia's website</u>
                  &nbsp;
                </a>
                {t("applicationSummary:loanProtectionPlanMessage9")}
                <a href="https://www.pidm.gov.my " target="_blank">
                  <u> PIDM's website.</u>
                </a>
              </span>
            </div>
          </>
        </>
      )}
      <Button onClick={() => navigate("/sme/loan-status/attestation-option")}>
        Next page
      </Button>
    </div>
  );
};
