import { Button, message, Result } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useLifecycles } from "react-use";

import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

import { dangerIconRed, successIconGreen } from "../../assets/style/variables";
import {
  ApplicationStatusEnum,
  ApplicationStepStatusEnum,
} from "../../enums/applicationStepStatus";
import {
  GrossSalary,
  Race,
} from "../../features/dropdownParameter/dropdownParameterSlice";
import { Product } from "../../features/product/productSlice";
import { setCurrentUserFullName } from "../../features/user/userSlice";
import {
  getApplicationDetailsThunk,
  patchApplicationStepErrorThunk,
  patchEkycCompletedStepThunk,
  updateApplicationThunk,
} from "../../services/applicationService/applicationThunk";
import {
  getGrossSalaryRangeListThunk,
  getRaceListThunk,
} from "../../services/dropdownParameterService/dropdownParameterThunk";
import { getProductsThunk } from "../../services/productService/productThunk";
import { logoutThunk } from "../../services/userService/userThunk";
import { AppDispatch, RootState } from "../../store/store";
import { IS_DEVELOPMENT_MODE } from "../../utils/environment";
import LoadingSpinner from "../LoadingSpinner";
import EkycCheckList from "../EkycCheckList";
import { fontWeight } from "@mui/system";
import {
  getSmeApplicationDetailsThunk,
  patchSmeApplicationToPendingThunk,
} from "../../services/smeApplicationService/smeApplicationThunk";

export const SmeVerificationCompleteContent = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const navigate = useNavigate();
  const status = new URLSearchParams(location.search).get("status");
  const desc = new URLSearchParams(location.search).get("desc");
  const username = new URLSearchParams(location.search).get("username");
  const docType = new URLSearchParams(location.search).get("docType");
  const applicationId = new URLSearchParams(location.search).get(
    "applicationId",
  );
  const companyId = new URLSearchParams(location.search).get("companyId");
  const ekycUrl = new URLSearchParams(location.search).get("ekycUrl");
  const fromDesktop =
    new URLSearchParams(location.search).get("fromDesktop") === "true";

  const { origin } = window.location;

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialzied, setInitialzied] = useState(false);

  const { language } = useSelector((state: RootState) => state.config);
  const { currentUser } = useSelector((state: RootState) => state.user);
  const { raceState, grossSalaryRangeState } = useSelector(
    (state: RootState) => state.dropdownParameter,
  );
  const { applicationDetails } = useSelector(
    (state: RootState) => state.application,
  );
  const { productState } = useSelector((state: RootState) => state.product);

  const { t } = useTranslation([
    "common",
    "verificationComplete",
    "applicationSummary",
  ]);

  const { selectedProduct } = productState;

  async function initProcess() {
    setLoading(true);
    setInitialzied(true);
    setSuccess(status === "success");

    if (status === "success" && !fromDesktop) {
      console.log("patching application to pending");
      dispatch(
        patchSmeApplicationToPendingThunk({
          applicationId: applicationDetails?.data?.id,
        }),
      );

      // Sync latest application into to state
      dispatch(
        getSmeApplicationDetailsThunk({
          applicationId: applicationDetails?.data?.id,
        }),
      );
    }
    if (status !== "success") {
      await dispatch(
        patchApplicationStepErrorThunk({
          applicationId: applicationDetails?.data?.id,
          stepName: ApplicationStepStatusEnum.EKYCCOMPLETED,
        }),
      );
    }
    setLoading(false);
  }

  // useLifecycles(async () => {
  //   dispatch(
  //     getProductsThunk({
  //       productCode: localStorage.getItem("productCode"),
  //     }),
  //   );
  //   await dispatch(getRaceListThunk());
  //   await dispatch(getGrossSalaryRangeListThunk());
  //   await dispatch(
  //     getApplicationDetailsThunk({
  //       applicationId: applicationDetails?.data?.id,
  //     }),
  //   );
  // });

  useEffect(() => {
    initProcess();
  }, []);

  return loading ? (
    <div className="flex justify-center items-center h-[90vh]">
      <LoadingSpinner />
    </div>
  ) : (
    <>
      {success ? (
        <Result
          icon={<CheckCircleOutlined style={{ color: successIconGreen }} />}
          title={
            fromDesktop ? (
              <>
                <h4 className="text-xl">
                  {t("verificationComplete:successTitle1")}
                </h4>
              </>
            ) : (
              <>
                <h4 className="text-xl">
                  {t("verificationComplete:successTitle2")}
                </h4>
              </>
            )
          }
          subTitle={
            <>
              <br />
              {fromDesktop ? (
                <p>{t("verificationComplete:successTitle1")}</p>
              ) : (
                <>
                  <h4 className="text-xl">
                    {`${t("verificationComplete:mobileSuccessSubtitle1")} : ${
                      applicationDetails?.data?.applicationNo
                    }`}
                  </h4>
                  <p>{t("verificationComplete:mobileSuccessSubtitle2")}</p>
                </>
              )}
            </>
          }
          extra={
            fromDesktop
              ? []
              : [
                  <button
                    key="home"
                    className="primary-button mt-24"
                    onClick={() => navigate("/home", {})} //  pass empty object to clear state
                  >
                    {t("common:Back")}
                  </button>,
                  <button
                    key={"dummy"}
                    className="secondary-button mt-4"
                    onClick={() => {
                      navigate(`/sme/home`);
                    }}
                  >
                    goto homepage
                  </button>,
                ]
          }
        />
      ) : (
        <Result
          icon={<CloseCircleOutlined style={{ color: dangerIconRed }} />}
          style={{ padding: 16 }}
          title={
            desc === "icMatchWithDatabase:false" ? (
              <>
                <h4 className="text-xl">
                  {t("verificationComplete:idVerificationFailErrMsg1")}
                </h4>
                <h4 className="text-xl">
                  {t("verificationComplete:idVerificationFailErrMsg2")}
                </h4>
              </>
            ) : (
              <h4 className="text-xl">
                {t(
                  "verificationComplete:idVerificationFailMatchWithDatabaseErrMsg",
                )}
              </h4>
            )
          }
          subTitle={
            <>
              {desc === "icMatchWithDatabase:false" ? (
                <p>{t("verificationComplete:idVerificationFailSubtitle")}</p>
              ) : (
                <p>{t("verificationComplete:defaultErrMsg")}</p>
              )}
              {IS_DEVELOPMENT_MODE && (
                <p>
                  <b>Debug Purpose: {desc}</b>
                </p>
              )}
              <br />
              <EkycCheckList retry={true} />
            </>
          }
          extra={[
            <>
              {desc === "icMatchWithDatabase:false" ? (
                <button
                  className="primary-button mt-8"
                  onClick={async () => {
                    await dispatch(logoutThunk());
                    navigate("/");
                  }}
                >
                  {t("verificationComplete:reApplytBtnLabel")}
                </button>
              ) : (
                <button
                  className="primary-button mt-8"
                  onClick={() => {
                    const url = fromDesktop
                      ? `${origin}/ekyc-sme.html?username=${username}&companyId=${companyId}&fromDesktop=${fromDesktop}&ekycUrl=${ekycUrl}&docType=${docType}&applicationId=${applicationId}`
                      : `/application/identity-verification`;

                    window.location.replace(url);
                  }}
                >
                  {t("verificationComplete:retryBtnLabel")}
                </button>
              )}
            </>,
            <button
              key={"contact_us"}
              className="secondary-button mt-4"
              onClick={() => {
                navigate(`/contact-us`);
              }}
            >
              {t("verificationComplete:contactUsText")}
            </button>,
            <button
              key={"dummy"}
              className="secondary-button mt-4"
              onClick={() => {
                navigate(`/sme/home`);
              }}
            >
              goto homepage
            </button>,
          ]}
        />
      )}
    </>
  );
};
