import { createSlice } from "@reduxjs/toolkit";
import {
  getPaymentHistoryThunk,
  postRazerMerchantPaymentRequestThunk,
} from "../../services/paymentService/paymentThunk";

export type RazerPaymentRequestBody = {
  ReferenceNo: string;
  TxnType: string;
  TxnChannel: string;
  TxnCurrency: string;
  TxnAmount: number;
  CustName: string;
  CustEmail: string;
  CustContact: string;
  CustDesc: string;
  ReturnURL: string;
  CallbackURL: string;
};

export type RazerPaymentRequestResponse = {
  MerchantID: string;
  ReferenceNo: string;
  TxnID: string;
  TxnType: string;
  TxnCurrency: string;
  TxnAmount: string;
  TxnChannel: string;
  TxnData: {
    RequestURL: string;
    RequestMethod: string;
    RequestType: string;
    RequestData: {
      fpx_msgType: string;
      fpx_msgToken: string;
      fpx_sellerExId: string;
      fpx_sellerExOrderNo: string;
      fpx_sellerTxnTime: string;
      fpx_sellerOrderNo: string;
      fpx_sellerId: string;
      fpx_sellerBankCode: string;
      fpx_txnCurrency: string;
      fpx_txnAmount: string;
      fpx_buyerEmail: string;
      fpx_checkSum: string;
      fpx_buyerName: string;
      fpx_buyerBankId: string;
      fpx_buyerBankBranch: string;
      fpx_buyerAccNo: string;
      fpx_buyerId: string;
      fpx_makerName: string;
      fpx_buyerIban: string;
      fpx_version: string;
      fpx_productDesc: string;
    };
  };
};

export type PaymentHistotry = {
  id: string;
  razerLogId: string;
  applicationNo: string;
  fileId: string;
  createdAt: number;
  updatedAt: string;
};

export type PaymentState = {
  paymentRequestState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: RazerPaymentRequestResponse;
  };
  paymentHistoryState: {
    isLoading: boolean;
    isError: boolean;
    errorMessage: any;
    data?: PaymentHistotry[];
  };
};

const initialState: PaymentState = {
  paymentRequestState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: undefined,
  },
  paymentHistoryState: {
    isLoading: false,
    isError: false,
    errorMessage: "",
    data: undefined,
  },
};

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postRazerMerchantPaymentRequestThunk.pending, (state) => {
        state.paymentRequestState.isLoading = true;
      })
      .addCase(
        postRazerMerchantPaymentRequestThunk.fulfilled,
        (state, action) => {
          state.paymentRequestState.isLoading = false;
          state.paymentRequestState.data = action.payload;
        },
      )
      .addCase(
        postRazerMerchantPaymentRequestThunk.rejected,
        (state, action) => {
          state.paymentRequestState.isLoading = false;
          state.paymentRequestState.isError = true;
          state.paymentRequestState.errorMessage = action.payload;
        },
      );

    builder
      .addCase(getPaymentHistoryThunk.pending, (state) => {
        state.paymentHistoryState.isLoading = true;
      })
      .addCase(getPaymentHistoryThunk.fulfilled, (state, action) => {
        state.paymentHistoryState.isLoading = false;
        state.paymentHistoryState.data = action.payload;
      })
      .addCase(getPaymentHistoryThunk.rejected, (state, action) => {
        state.paymentHistoryState.isLoading = false;
        state.paymentHistoryState.isError = true;
        state.paymentHistoryState.errorMessage = action.payload;
      });
  },
});
// eslint-disable-next-line no-empty-pattern
export const {} = paymentSlice.actions;

export default paymentSlice.reducer;
