import "./index.less";

import { Avatar, Drawer, Space, Typography } from "antd";
import { CSSProperties, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import {
  CloseOutlined,
  LogoutOutlined,
  MenuOutlined,
  SettingFilled,
} from "@ant-design/icons";

import redCashLogo from "../../../assets/logo/redCASH-logo-red-transp.png";
import { primaryColor } from "../../../assets/style/variables";
import { AgreementStatusEnum } from "../../../enums/agreementStepStatus";
import { LANGUAGE, setLanguage } from "../../../features/config/configSlice";
import { logoutThunk } from "../../../services/userService/userThunk";
import { AppDispatch, RootState } from "../../../store/store";
import LoginModal from "../LoginModal";
import { ApplicationStatusEnum } from "../../../enums/applicationStepStatus";

const { Title } = Typography;

type menuItemType = {
  label: string;
  path: string;
  hide?: boolean;
  style?: CSSProperties;
};

const MenuModal = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation(["common"]);
  const dispatch = useDispatch<AppDispatch>();
  const [open, setOpen] = useState(false);
  const [menuItems, setMenuItems] = useState<menuItemType[]>([]);
  const { currentUser, hasLoggedUser } = useSelector(
    (state: RootState) => state.user,
  );
  const { agreementDetails } = useSelector(
    (state: RootState) => state.agreement,
  );
  const { applicationDetails } = useSelector(
    (state: RootState) => state.application,
  );
  const { language } = useSelector((state: RootState) => state.config);

  useEffect(() => {
    const noAuthMenuItems: menuItemType[] = [
      { label: "SSO Login", path: "/sso-login" },
      { label: t("common:Legal_Terms"), path: "/legal-terms" },
      { label: t("common:Contact_Us"), path: "/contact-us" },
      { label: t("common:FAQs"), path: "/faq" },
    ];

    let authMenuItems: menuItemType[] = [
      { label: t("common:Draft_Application"), path: "/draft-application" },
      { label: t("common:Loan_History"), path: "/loan-history" },
      {
        label: t("common:Repayment_Schedule"),
        path: "/repayment-schedule",
        hide: agreementDetails?.data == null,
      },
      {
        label: t("common:Payment_History"),
        path: "/payment-history",
        hide: agreementDetails?.data == null,
      },
      { label: t("common:Update_Profile"), path: "/update-profile" },
      { label: t("common:Change_Password"), path: "/change-password" },
      { label: t("common:Legal_Terms"), path: "/legal-terms" },
      {
        label: t("common:Pay_Instalment_Now"),
        path: "/pay-instalment-now",
        hide: agreementDetails?.data == null,
        style: {
          color: primaryColor,
          borderRadius: 50,
          border: "2px solid rgba(0, 0, 0, 0.4)",
          display: "inline-block",
          padding: "8px 16px",
          marginLeft: 8,
        },
      },
      {
        label: t("common:E_Invoice"),
        path: "/request-e-invoice",
        hide: !(
          applicationDetails?.data?.applicationStatus ===
            ApplicationStatusEnum.APPROVED &&
          (agreementDetails?.data?.agreementStatus ===
            AgreementStatusEnum.PENDING_DISBURSEMENT ||
            agreementDetails?.data?.agreementStatus ===
              AgreementStatusEnum.DISBURSED)
        ),
        style: {
          color: primaryColor,
          borderRadius: 50,
          border: "2px solid rgba(0, 0, 0, 0.4)",
          display: "inline-block",
          padding: "8px 16px",
          marginLeft: 8,
        },
      },
      { label: t("common:Contact_Us"), path: "/contact-us" },
      { label: t("common:Update_Direct_Debit"), path: "/update-direct-debit" },
      { label: t("common:FAQs"), path: "/faq" },
    ];

    if (
      agreementDetails.data?.agreementStatus !== AgreementStatusEnum.DISBURSED
    ) {
      authMenuItems = authMenuItems.filter(
        (item) =>
          item.path !== "/pay-instalment-now" &&
          item.path !== "/update-direct-debit",
      );
    }
    if (agreementDetails.data?.signDate == null) {
      authMenuItems = authMenuItems.filter(
        (item) => item.path !== "/repayment-schedule",
      );
    }

    setMenuItems(hasLoggedUser ? authMenuItems : noAuthMenuItems);
  }, [agreementDetails.data, hasLoggedUser, t]);

  const showMenu = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onLogout = () => {
    dispatch(logoutThunk()).then(() => navigate("/"));
  };

  const handleLangChange = (lang: LANGUAGE) => {
    dispatch(setLanguage(lang));
    localStorage.setItem("language", lang);
    navigate(`${location.pathname}?language=${lang}`);
  };

  return (
    <>
      <button className="text-button menu-button" onClick={showMenu}>
        <MenuOutlined className="menu-button-icon" />
      </button>
      <Drawer
        placement="left"
        closable={false}
        onClose={onClose}
        open={open}
        bodyStyle={{ padding: "16px 0", width: "100%", height: "100vh" }}
      >
        <div className="flex justify-between flex-col h-full">
          <div className="h-full">
            <div className="flex justify-between items-center">
              <img className="menu-logo" src={redCashLogo} alt="logo" />
              <button className="text-button" onClick={onClose}>
                <CloseOutlined
                  style={{ fontSize: 32, color: "rgb(55 65 81)" }}
                />
              </button>
            </div>
            {hasLoggedUser && (
              <Space className="logged-user-section" align="center">
                <Avatar size={64} icon={<SettingFilled />} />
                <Title level={4} style={{ margin: 0, color: "black" }}>
                  {currentUser?.data?.firstName}
                </Title>
              </Space>
            )}
            <nav className="web-menu-drawer-item-list">
              <ul className="list-disc list-outside">
                {Array.isArray(menuItems) &&
                  menuItems.map(
                    (item) =>
                      !item.hide && (
                        <li key={item.label}>
                          <a
                            className={
                              location.pathname.includes(item.path)
                                ? "active"
                                : ""
                            }
                            style={item?.style}
                            // TODO: remove hardcode
                            href={
                              item.path === "/sso-login"
                                ? "https://uat-cep-redcash.arkmind-dev.com/auth"
                                : item.path
                            }
                          >
                            {item.label}
                          </a>
                        </li>
                      ),
                  )}
              </ul>
            </nav>
            <button
              className="text-button text-start -mt-2 mb-2"
              style={{ fontWeight: "bold" }}
              onClick={() =>
                handleLangChange(
                  language === LANGUAGE.EN ? LANGUAGE.MS : LANGUAGE.EN,
                )
              }
            >
              {language === LANGUAGE.EN ? "Bahasa Malaysia" : "English"}
            </button>
            {hasLoggedUser ? (
              <button className="text-button log-button" onClick={onLogout}>
                <LogoutOutlined style={{ marginRight: 8 }} />
                {t("common:Logout")}
              </button>
            ) : (
              <LoginModal
                buttonClass="text-button log-button login-button"
                buttonTitle={t("common:Login")}
              />
            )}
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default MenuModal;
