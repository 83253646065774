import { createAsyncThunk } from "@reduxjs/toolkit";
import smeApplicationService from "./smeApplicationService";
import { restructureErrorMessage } from "../../helpers/errorMessage";
import { CreateApplicationDraftPayload } from "../../features/application/applicationSlice";
import { CreateSmeApplicationDraftPayload } from "../../features/smeApplication/smeApplicationSlice";

export const getSmeApplicationDetailsThunk = createAsyncThunk(
  "sme/application/getDetails",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.getSmeApplicationDetails(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getApplicationStepStatusThunk = createAsyncThunk(
  "application/getStepStatus",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.getApplicationStepStatus(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const testEligibilityThunk = createAsyncThunk(
  "application/testEligibility",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.testEligibility(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const createSmeApplicationDraftThunk = createAsyncThunk(
  "/sme/application/createDraft",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.createSmeApplicationDraft(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const patchSmeApplicationToPendingThunk = createAsyncThunk(
  "/sme/application/pending",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.patchSmeApplicationToPending(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateApplicationThunk = createAsyncThunk(
  "application/update",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.updateApplication(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const deleteApplicationThunk = createAsyncThunk(
  "application/delete",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.deleteApplication(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const cancelApplicationThunk = createAsyncThunk(
  "application/cancel",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.cancelApplication(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const patchApplicationFormCompletedStepThunk = createAsyncThunk(
  "application/application-form-completed",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.patchApplicationFormCompletedStep(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const patchEkycCompletedStepThunk = createAsyncThunk(
  "application/ekyc-completed",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.patchEkycCompletedStep(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const patchEmailVerificationCompletedStepThunk = createAsyncThunk(
  "application/email-verification-completed",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.patchEmailVerificationCompletedStep(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const patchDocumentUploadCompletedStepThunk = createAsyncThunk(
  "application/document-upload-completed",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.patchDocumentUploadCompletedStep(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const patchApplicationStepErrorThunk = createAsyncThunk(
  "application/application-step-error",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.patchApplicationStepError(requestData);
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getLatestApplicationStatusThunk = createAsyncThunk(
  "application/getLatestStatus",
  async (requestData: any, thunkAPI) => {
    try {
      return await smeApplicationService.getLatestApplicationStatus(
        requestData,
      );
    } catch (error) {
      const message = restructureErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  },
);
