import { Alert, Space } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { createBreakpoint } from "react-use";

const useBreakpoint = createBreakpoint();

const LoanCancelledStatusContent = () => {
  const navigate = useNavigate();
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === "tablet";
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(["common", "loanStatus"]);

  const proceedToApplyNewLoan = () => {
    setLoading(true);
    navigate(`/registration`);
    setLoading(false);
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Alert
        className="alert-card"
        style={{ textAlign: isMobile ? "center" : "left" }}
        message={
          <h3 className="alert-title-error">
            {t("loanStatus:loan_removed_title")}
          </h3>
        }
        description={
          <p style={{ margin: 0 }}>
            {t("loanStatus:loan_removed_description")}
          </p>
        }
        type="error"
      />
      <button
        className="primary-button"
        disabled={loading}
        onClick={proceedToApplyNewLoan}
      >
        {t("loanStatus:Apply_New_Loan")}
      </button>
    </Space>
  );
};

export default LoanCancelledStatusContent;
