import "./MobileSmeIdentityVerificationPage.less";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { IdentityVerificationContent } from "../../../components/IdentityVerificationContent";
import { MobileTopNavHeaderLayout } from "../../../layouts/mobile/MobileTopNavHeaderLayout/MobileTopNavHeaderLayout";
import { SmeIdentityVerificationContent } from "../../../components/SmeIdentityVerificationContent";

const MobileSmeIdentityVerificationPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["common", "identityVerification"]);

  return (
    <MobileTopNavHeaderLayout
      title={t("identityVerification:pageTitle")}
      onBack={() => navigate(-1)}
    >
      <SmeIdentityVerificationContent />
    </MobileTopNavHeaderLayout>
  );
};

export default MobileSmeIdentityVerificationPage;
