import "./index.less";

import { Alert, Form, Input, message, Space, Typography } from "antd";
import dayjs from "dayjs";
import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { LoadingOutlined } from "@ant-design/icons";

import { authSetupAxiosInstance } from "../../../../axios/authSetupAxios";
import { ApplicationStatusEnum } from "../../../../enums/applicationStepStatus";
import { setAgreementDetails } from "../../../../features/agreement/agreementSlice";
import {
  setApplicationDetails,
  setApplicationDraft,
} from "../../../../features/application/applicationSlice";
import { setAccountDetails } from "../../../../features/collection/collectionSlice";
import { LANGUAGE, setLanguage } from "../../../../features/config/configSlice";
import {
  setCurrentUser,
  setHasLoggedUser,
  setRegistrationFormData,
  setRegistrationFormDataLogin,
} from "../../../../features/user/userSlice";
import {
  redCashLoginThunk,
  resetPasswordThunk,
} from "../../../../services/userService/userThunk";
import { AppDispatch, RootState } from "../../../../store/store";
import {
  HIDE_RECAPTCHA,
  IS_DEVELOPMENT_MODE,
} from "../../../../utils/environment";
import { startCase, styleBrandLabel } from "../../../../utils/stringFormatter";

const { Title } = Typography;

export type RedCashLoginFormProps = {
  callback?: any;
};

const RedCashLoginForm: React.FC<RedCashLoginFormProps> = ({ callback }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const recaptchaRef = useRef<any>(null);

  const { t } = useTranslation(["common", "login"]);
  const { language } = useSelector((state: RootState) => state.config);
  const { registrationFormData } = useSelector(
    (state: RootState) => state.user,
  );

  const handleLangChange = (lang: LANGUAGE) => {
    dispatch(setLanguage(lang));
    localStorage.setItem("language", lang);
  };

  const onBypassReCapthaLogin = async () => {
    await form.validateFields().then(async (values) => {
      setLoading(true);

      dispatch(
        redCashLoginThunk({
          email: values?.email?.trim(),
          password: values?.password,
        }),
      )
        .unwrap()
        .then(async (res) => {
          console.log("Login success! Injecting Data...");

          localStorage.setItem("productCode", res.productCode);

          localStorage.setItem("redOneJwt", res.redOneJwt);
          if (localStorage.getItem("language") === "MS") {
            handleLangChange(LANGUAGE.MS);
          }

          if (res.systemAccessJwt) {
            localStorage.setItem("accessToken", res.systemAccessJwt);
          }

          if (res.systemRefreshJwt) {
            localStorage.setItem("refreshToken", res.systemRefreshJwt);
          }

          if (res?.accountDetails?.accountDetails != null) {
            await dispatch(
              setAccountDetails(res?.accountDetails?.accountDetails),
            );
          }

          if (res.userDetails != null) {
            await dispatch(setHasLoggedUser(true));
            await dispatch(setCurrentUser(res.userDetails));
            !registrationFormData?.currentlyEditing &&
              (await dispatch(setRegistrationFormDataLogin(res.userDetails)));

            if (
              res.userDetails.language === "MS" ||
              res.userDetails.language === "ms" ||
              res.userDetails.language === "BM" ||
              res.userDetails.language === "bm"
            ) {
              handleLangChange(LANGUAGE.MS);
            } else {
              handleLangChange(language);
            }

            // if the application applied by user is rejected before,
            // the user can only apply after allowToApplyNewLoanAfterDateTime
            // similar status check also exist in LoanApplicationStatusCard, case ApplicationStatusEnum.REJECTED
            const allowToApplyNewLoanAfterDateTime =
              res.userDetails?.allowToApplyNewLoanAfterDateTime;

            const isBeforeAllowToApplyNewLoanAfterDateTime =
              allowToApplyNewLoanAfterDateTime != null &&
              dayjs().isBefore(dayjs(allowToApplyNewLoanAfterDateTime));

            if (
              res.applicationDetails.applicationStatus ===
                ApplicationStatusEnum.REJECTED &&
              isBeforeAllowToApplyNewLoanAfterDateTime
            ) {
              return navigate(`/not-allow-new-loan?language=${language}`);
            }

            message.success(t("login:loginSuccessfulMsg"));

            if (
              res.applicationDetails?.applicationStatus ===
              ApplicationStatusEnum.DRAFT
            ) {
              await dispatch(setApplicationDraft(res.applicationDetails));
              await dispatch(setApplicationDetails(res.applicationDetails));
              return navigate(`/home?language=${language}`);
            }

            await dispatch(setApplicationDetails(res.applicationDetails));
            await dispatch(setAgreementDetails(res.agreementDetails));
            !registrationFormData?.currentlyEditing &&
              (await dispatch(setRegistrationFormDataLogin(res.userDetails)));

            return navigate(`/home?language=${language}`);
          } else {
            // message.error("User not found in system");

            // new register user,
            await dispatch(
              setRegistrationFormData({
                ...registrationFormData,
                phoneNo: values.callerId,
              }),
            );
            return navigate(`/registration?language=${language}`);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const onFinish = async () => {
    const recaptchaValue = recaptchaRef.current.getValue();

    if (recaptchaValue === "") {
      message.error(t("login:botCheckRequiredMsg"));
      return;
    }

    await form.validateFields().then(async (values) => {
      setLoading(true);

      authSetupAxiosInstance
        .post("/redCash/auth/api/v1.0/recapthaV2/verify-token", {
          token: recaptchaValue,
        })
        .then((res) => {
          if (res.data.success) {
            dispatch(
              redCashLoginThunk({
                email: values?.email?.trim(),
                password: values?.password,
              }),
            )
              .unwrap()
              .then(async (res) => {
                console.log("Login success! Injecting Data...");

                localStorage.setItem("redOneJwt", res.redOneJwt);
                if (localStorage.getItem("language") === "MS") {
                  handleLangChange(LANGUAGE.MS);
                }

                if (res.systemAccessJwt) {
                  localStorage.setItem("accessToken", res.systemAccessJwt);
                }

                if (res.systemRefreshJwt) {
                  localStorage.setItem("refreshToken", res.systemRefreshJwt);
                }

                if (res.userDetails != null) {
                  await dispatch(setHasLoggedUser(true));
                  await dispatch(setCurrentUser(res.userDetails));
                  !registrationFormData?.currentlyEditing &&
                    (await dispatch(
                      setRegistrationFormDataLogin(res.userDetails),
                    ));

                  if (
                    res.userDetails.language === "MS" ||
                    res.userDetails.language === "ms" ||
                    res.userDetails.language === "BM" ||
                    res.userDetails.language === "bm"
                  ) {
                    handleLangChange(LANGUAGE.MS);
                  } else {
                    handleLangChange(language);
                  }

                  // if the application applied by user is rejected before,
                  // the user can only apply after allowToApplyNewLoanAfterDateTime
                  // similar status check also exist in LoanApplicationStatusCard, case ApplicationStatusEnum.REJECTED
                  const allowToApplyNewLoanAfterDateTime =
                    res.userDetails?.allowToApplyNewLoanAfterDateTime;

                  const isBeforeAllowToApplyNewLoanAfterDateTime =
                    allowToApplyNewLoanAfterDateTime != null &&
                    dayjs().isBefore(dayjs(allowToApplyNewLoanAfterDateTime));

                  if (
                    res.applicationDetails.applicationStatus ===
                      ApplicationStatusEnum.REJECTED &&
                    isBeforeAllowToApplyNewLoanAfterDateTime
                  ) {
                    return navigate(`/not-allow-new-loan?language=${language}`);
                  }

                  message.success(t("login:loginSuccessfulMsg"));

                  if (
                    res.applicationDetails.applicationStatus ===
                    ApplicationStatusEnum.DRAFT
                  ) {
                    await dispatch(setApplicationDraft(res.applicationDetails));
                    await dispatch(
                      setApplicationDetails(res.applicationDetails),
                    );
                    return navigate(`/home?language=${language}`);
                  }

                  await dispatch(setApplicationDetails(res.applicationDetails));
                  await dispatch(setAgreementDetails(res.agreementDetails));
                  !registrationFormData?.currentlyEditing &&
                    (await dispatch(
                      setRegistrationFormDataLogin(res.userDetails),
                    ));

                  return navigate(`/home?language=${language}`);
                } else {
                  // message.error("User not found in system");

                  // new register user,
                  await dispatch(
                    setRegistrationFormData({
                      ...registrationFormData,
                      phoneNo: values.callerId,
                    }),
                  );
                  return navigate(`/registration?language=${language}`);
                }
              })
              .finally(() => {
                recaptchaRef?.current?.reset();
                setLoading(false);
              });
          } else {
            setLoading(false);
          }
        })
        .catch(() => {
          message.error(t("login:recapthaKeyInvalidMsg"));
          setLoading(false);
        });
    });
  };

  const byPassLogin = async () => {
    await form.validateFields().then(async (values) => {
      setLoading(true);
      dispatch(
        redCashLoginThunk({
          email: values?.email?.trim(),
          password: values?.password,
        }),
      )
        .unwrap()
        .then(async (res) => {
          console.log("Login success! Injecting Data...");

          localStorage.setItem("redOneJwt", res.redOneJwt);
          if (localStorage.getItem("language") === "MS") {
            handleLangChange(LANGUAGE.MS);
          }

          if (res.systemAccessJwt) {
            localStorage.setItem("accessToken", res.systemAccessJwt);
          }

          if (res.systemRefreshJwt) {
            localStorage.setItem("refreshToken", res.systemRefreshJwt);
          }

          if (res.userDetails != null) {
            await dispatch(setHasLoggedUser(true));
            await dispatch(setCurrentUser(res.userDetails));
            !registrationFormData?.currentlyEditing &&
              (await dispatch(setRegistrationFormDataLogin(res.userDetails)));

            if (
              res.userDetails.language === "MS" ||
              res.userDetails.language === "ms" ||
              res.userDetails.language === "BM" ||
              res.userDetails.language === "bm"
            ) {
              handleLangChange(LANGUAGE.MS);
            } else {
              handleLangChange(language);
            }

            // if the application applied by user is rejected before,
            // the user can only apply after allowToApplyNewLoanAfterDateTime
            // similar status check also exist in LoanApplicationStatusCard, case ApplicationStatusEnum.REJECTED
            const allowToApplyNewLoanAfterDateTime =
              res.userDetails?.allowToApplyNewLoanAfterDateTime;

            const isBeforeAllowToApplyNewLoanAfterDateTime =
              allowToApplyNewLoanAfterDateTime != null &&
              dayjs().isBefore(dayjs(allowToApplyNewLoanAfterDateTime));

            if (
              res.applicationDetails.applicationStatus ===
                ApplicationStatusEnum.REJECTED &&
              isBeforeAllowToApplyNewLoanAfterDateTime
            ) {
              return navigate(`/not-allow-new-loan?language=${language}`);
            }

            message.success(t("login:loginSuccessfulMsg"));

            if (
              res.applicationDetails.applicationStatus ===
              ApplicationStatusEnum.DRAFT
            ) {
              await dispatch(setApplicationDraft(res.applicationDetails));
              await dispatch(setApplicationDetails(res.applicationDetails));
              return navigate(`/home?language=${language}`);
            }

            await dispatch(setApplicationDetails(res.applicationDetails));
            await dispatch(setAgreementDetails(res.agreementDetails));
            !registrationFormData?.currentlyEditing &&
              (await dispatch(setRegistrationFormDataLogin(res.userDetails)));

            return navigate(`/home?language=${language}`);
          } else {
            // message.error("User not found in system");

            // new register user,
            await dispatch(
              setRegistrationFormData({
                ...registrationFormData,
                phoneNo: values.callerId,
              }),
            );
            return navigate(`/registration?language=${language}`);
          }
        })
        .finally(() => {
          recaptchaRef?.current?.reset();
          setLoading(false);
        });
    });
  };

  const sendTempPasswordLink = () => {
    if (!form.getFieldsValue().callerId) {
      message.error(t("login:phoneRequiredMsg"));
      return;
    }

    message.success(t("login:sendingTempPasswordMsg"));

    dispatch(
      resetPasswordThunk({ callerId: `0${form.getFieldsValue().callerId}` }),
    )
      .unwrap()
      .then((res) => {
        message.success(t("login:tempPasswordSentMsg"));
      });
  };

  const resetPassword = () => {
    navigate(`/reset-password-request?language=${language}`);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      onFinish();
    }
  };

  return (
    <>
      <Title level={4} className="login-modal-title">
        {styleBrandLabel(t("login:Login_Using_redCASH_Account"))}
      </Title>
      <p>{t("login:redCashPasswordRequiredMsg")}</p>

      <Form
        form={form}
        id="loginForm"
        name="loginForm"
        requiredMark="optional"
        layout="vertical"
        // validateMessages={validateMessages}
        scrollToFirstError
      >
        <Form.Item
          label={t("login:Email")}
          name={"email"}
          rules={[
            {
              required: true,
              message: t("login:emailRequiredMsg"),
            },
          ]}
        >
          <Input
            placeholder={t("login:inputPlaceholder")}
            onInput={(e) =>
              ((e.target as HTMLInputElement).value = (
                e.target as HTMLInputElement
              ).value.toLowerCase())
            }
          />
        </Form.Item>

        <Form.Item
          name="password"
          label={startCase(t("login:Password"))}
          validateFirst={true}
          rules={[
            {
              required: true,
              message: t("login:passwordRequiredMsg"),
            },
          ]}
        >
          <Input.Password
            placeholder={t("login:inputPlaceholder")}
            onKeyDown={handleKeyDown}
          />
        </Form.Item>

        <div className="flex-center">
          <button
            className="text-button"
            type={"button"}
            onClick={resetPassword}
          >
            {startCase(t("login:Forgot_password"))}
          </button>
        </div>

        {!HIDE_RECAPTCHA && (
          <>
            <div className="flex justify-center mt-4">
              {process.env.REACT_APP_RECAPTHA_SITE_KEY ? (
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_RECAPTHA_SITE_KEY}
                />
              ) : (
                <Alert
                  message={t("login:recapthaKeyMissingMsg")}
                  type="error"
                />
              )}
            </div>
          </>
        )}

        <div className="login-submit">
          <Space direction={"vertical"} style={{ width: "100%" }}>
            {IS_DEVELOPMENT_MODE && (
              <button
                type="button"
                className="primary-button"
                disabled={loading}
                onClick={onBypassReCapthaLogin}
              >
                {loading && (
                  <LoadingOutlined style={{ fontSize: 28, marginRight: 8 }} />
                )}
                Bypass reCAPTHA Login
              </button>
            )}
            <button
              type="button"
              className="primary-button"
              disabled={loading}
              onClick={!HIDE_RECAPTCHA ? onFinish : byPassLogin}
            >
              {loading && (
                <LoadingOutlined style={{ fontSize: 28, marginRight: 8 }} />
              )}
              {t("login:Login")}
            </button>
          </Space>
        </div>
      </Form>
    </>
  );
};

export default RedCashLoginForm;
