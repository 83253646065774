import "./index.less";

import { message, Upload } from "antd";
import imageCompression from "browser-image-compression";
import React, { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { UploadOutlined } from "@ant-design/icons";

import { beSetupAxiosInstance } from "../../../axios/beSetupAxios";
import { FileTypeEnum } from "../../../enums/fileType";
import { UploadedFile } from "../../../features/application/applicationSlice";
import { previewNewPage } from "../../../helpers/downloadLink";
import {
  fileDeleteThunk,
  getFileByIdThunk,
} from "../../../services/fileService/fileThunk";
import { AppDispatch, RootState } from "../../../store/store";
import { renameFile } from "../../../utils/file";

import type { RcFile, UploadProps, UploadFile } from "antd/es/upload";
import { patchApplicationStepErrorThunk } from "../../../services/applicationService/applicationThunk";
import { ApplicationStepStatusEnum } from "../../../enums/applicationStepStatus";

interface FileUploadFieldProps {
  title?: ReactNode;
  entityId: string;
  fileType: FileTypeEnum | string;
  defaultFileList?: any;
  setUploading?: any;
  callback?: any;
  errorMsg?: string;
}

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const SmeFileUploadField: React.FC<FileUploadFieldProps> = (
  props: any,
) => {
  const {
    title,
    entityId,
    defaultFileList,
    fileType,
    setUploading,
    callback,
    errorMsg,
  } = props;
  const dispatch = useDispatch<AppDispatch>();

  const { applicationDetails, applicationFiles } = useSelector(
    (state: RootState) => state.application,
  );

  const [fileList, setFileList] = useState(
    defaultFileList.map((file: any) => ({
      uid: file.id,
      name: file.filename.substring(file.filename.indexOf("-") + 1),
      status: "done",
    })),
  );

  const { t } = useTranslation(["common", "documentUpload"]);

  const addFileToList = (file: RcFile) => {
    const newFileList = [...fileList];
    newFileList.push(file);
    setFileList(newFileList);
  };

  const updateFileInList = (file: RcFile) => {
    const result = fileList.find(
      (uploadedFile: RcFile) =>
        uploadedFile?.name?.substring(uploadedFile?.name?.indexOf("-") + 1) ===
        file.name,
    );

    if (result) {
      const index = fileList.indexOf(result);
      const newFileList = [...fileList];
      newFileList[index] = file;
      setFileList(newFileList);
    }
  };

  const removeFileFromList = async (file: any) => {
    const result = fileList.find(
      (uploadedFile: RcFile) => uploadedFile?.uid === file.uid,
    );

    if (result) {
      const index = fileList.indexOf(result);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);

      if (!file.uid.startsWith("rc-upload")) {
        await dispatch(fileDeleteThunk({ fileId: file.uid }));
        await callback();
      }
    }
  };

  const handlePreview = async (file: UploadFile) => {
    await dispatch(getFileByIdThunk({ fileId: file.uid }))
      .unwrap()
      .then((res) =>
        previewNewPage({
          ...res,
          fileId: file.uid,
          defaultUrl:
            process.env.REACT_APP_BE_SETUP_URL +
            "/redCash/api/v1.0/previewFile",
        }),
      );
  };

  const uploadProps: UploadProps = {
    maxCount:
      fileType !== FileTypeEnum.BANKSTATEMENT &&
      fileType !== FileTypeEnum.OTHERSRELEVANTDOCUMENTS &&
      fileType !== FileTypeEnum.SALARYSLIP
        ? 3
        : 99,
    fileList: fileList,
    onPreview: handlePreview,
    beforeUpload: (file: RcFile) => {
      const isLt5M = file.size / 1024 / 1024 < 5;
      const correctFileType =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png" ||
        file.type === "application/pdf";
      const moreThan3 = fileList.length >= 3;
      const sameFile = applicationFiles.unGroupData?.find(
        (uploadedFile: UploadedFile) =>
          uploadedFile?.filename?.substring(
            uploadedFile?.filename?.indexOf("-") + 1,
          ) === file.name,
      );
      if (!correctFileType) {
        message.error(t("documentUpload:supportedFileTypeErrorMsg"));
        return Upload.LIST_IGNORE;
      }
      if (!isLt5M) {
        message.error(t("documentUpload:fileOverzisedErrorMsg"));
        return Upload.LIST_IGNORE;
      }
      if (sameFile) {
        message.error(t("documentUpload:sameFileErrorMsg"));
        return Upload.LIST_IGNORE;
      }
      if (
        fileType !== FileTypeEnum.BANKSTATEMENT &&
        fileType !== FileTypeEnum.OTHERSRELEVANTDOCUMENTS &&
        fileType !== FileTypeEnum.SALARYSLIP
      ) {
        if (moreThan3) {
          console.log(fileType);
          message.error(t("documentUpload:filesLimitErrorMsg"));
          return Upload.LIST_IGNORE;
        }
      }
    },

    customRequest: async (options: any) => {
      const data = new FormData();
      const file =
        options.file.name === "image.jpg" || options.file.name === "image.jpeg"
          ? renameFile(options.file, `image_${Date.now()}`)
          : options.file;

      if (
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png"
      ) {
        const compressOptions = {
          maxSizeMB: 0.7,
          fileType: "image/jpeg",
        };
        const compressedFile: any = await imageCompression(
          file,
          compressOptions,
        );
        const convertedFile = renameFile(compressedFile);
        convertedFile["uid"] = options.file.uid;
        data.append("file", convertedFile);
      } else {
        data.append("file", file);
      }
      data.append("entityId", entityId);
      data.append("fileType", fileType);

      const url = `/redCash/api/v1.0/file`;
      const config = {
        headers: {
          "content-Type": "multipart/form-data",
        },
      };
      setUploading(true);

      file["status"] = "uploading";
      addFileToList(file);

      beSetupAxiosInstance
        .post(url, data, config)
        .then((res: any) => {
          // options.onSuccess(res.data, options.file);
          file["uid"] = res.data[0].id;
          file["status"] = "done";
          updateFileInList(file);
          callback();
        })
        .catch(() => {
          const file = options.file;
          file["status"] = "error";
          updateFileInList(file);

          dispatch(
            patchApplicationStepErrorThunk({
              applicationId: applicationDetails?.data?.id,
              stepName: ApplicationStepStatusEnum.DOCUMENTUPLOADCOMPLETED,
            }),
          );
          message.error(t("documentUpload:unsupportFileTypeErrorMsg"));
        })
        .finally(() => setUploading(false));
    },

    onRemove: removeFileFromList,
  };

  return (
    <div className="file-upload-field">
      {title && (
        <div className="field-title">
          <b>•</b>
          {title}
        </div>
      )}
      {errorMsg && (
        <p className="form-error px-4 pt-2" style={{ marginBottom: -14 }}>
          {errorMsg}
        </p>
      )}
      <Upload {...uploadProps}>
        <button className="secondary-button reupload-button">
          <UploadOutlined /> {t("documentUpload:uploadBtnLabel")}
        </button>
        <span className="file-upload-field-text">
          *{t("documentUpload:fileUploadRule")}
        </span>
      </Upload>
    </div>
  );
};

SmeFileUploadField.defaultProps = { defaultFileList: [] };
