import { useNavigate } from "react-router";
import MobileFooter from "../../../components/MobileFooter/MobileFooter";

import "./MobileSmeTestingEligibilityPage.less";
import { NavBar } from "antd-mobile";
import { useTranslation } from "react-i18next";
import { SmeTestingEligibilityForm } from "../../../components/SmeTestingEligibilityForm";

const MobileSmeTestingEligibilityPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["common", "testingEligibility"]);

  return (
    <div className="mx-4">
      <NavBar onBack={() => navigate("/registration")}>
        <strong>{t("testingEligibility:title")}</strong>
      </NavBar>
      <SmeTestingEligibilityForm />
      <MobileFooter />
    </div>
  );
};

export default MobileSmeTestingEligibilityPage;
