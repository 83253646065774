import "./MobileSmeVideoAttestationPage.less";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { VideoAttestationContent } from "../../../components/VideoAttestationContent";
import { MobileTopNavHeaderLayout } from "../../../layouts/mobile/MobileTopNavHeaderLayout/MobileTopNavHeaderLayout";
import { SmeVideoAttestationContent } from "../../../components/SmeVideoAttestationContent";

const MobileSmeVideoAttestationPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["common", "videoAttestation"]);

  const onBack = () => navigate(`/sme/loan-status/attestation-option`);

  return (
    <MobileTopNavHeaderLayout
      title={t("videoAttestation:pageTitle")}
      onBack={onBack}
    >
      <div className="my-4">
        <SmeVideoAttestationContent />
      </div>
    </MobileTopNavHeaderLayout>
  );
};

export default MobileSmeVideoAttestationPage;
