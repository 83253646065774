import { NavBar } from "antd-mobile";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { UpdateProfileForm } from "../../../components/UpdateProfileForm";
import { WebTopNavLayout } from "../../../layouts/web/WebTopNavLayout";
import { RootState } from "../../../store/store";
import { useTranslation } from "react-i18next";
import { UpdateEInvoiceTinForm } from "../../../components/UpdateEInvoiceTinForm";

const WebUpdateEInvoiceTinPage = () => {
  const navigate = useNavigate();
  const { currentUser } = useSelector((state: RootState) => state.user);
  const hasUser = currentUser?.data?.id != null;
  const { t } = useTranslation(["common", "updateEInvoiceTin"]);

  const onBack = () => navigate(hasUser ? `/home` : `/`);

  return (
    <WebTopNavLayout>
      <div className="max-w-4xl m-auto p-2">
        <NavBar
          back={<strong>{t("common:Back_to_Homepage")}</strong>}
          onBack={onBack}
        />
        <div className="max-w-xl m-auto">
          <h2 className="page-header">{t("updateEInvoiceTin:pageTitle")}</h2>
          <UpdateEInvoiceTinForm />
        </div>
      </div>
    </WebTopNavLayout>
  );
};

export default WebUpdateEInvoiceTinPage;
