import { masterSetupAxiosInstance } from "../../axios/masterSetupAxios";

const getPreRecordedVideo = async () => {
  const params = { take: 1 };
  const url = `/redCash/master/api/v1.0/preRecordedVideo/${process.env.REACT_APP_COMPANY_ID}`;
  const res = await masterSetupAxiosInstance.get(url, { params });
  return res.data;
};

const getPreRecordedVideoBm = async () => {
  const params = { take: 1 };
  const url = `/redCash/master/api/v1.0/preRecordedVideoBm`;
  const res = await masterSetupAxiosInstance.get(url, { params });
  return res.data;
};

const productService = {
  getPreRecordedVideo,
  getPreRecordedVideoBm,
};

export default productService;
