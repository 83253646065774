import { createTheme } from "@mui/material/styles";

import { primaryColor } from "../assets/style/variables";

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
      light: primaryColor,
      dark: primaryColor,
    },
  },
});
