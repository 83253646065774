import "./WebSmeIndividualDocumentUploadPage.less";

import { PageHeader } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { WebApplyApplicationLayout } from "../../../layouts/web/WebApplyApplicationLayout";
import { RootState } from "../../../store/store";
import { useTranslation } from "react-i18next";
import { SmeDocumentUploadForm } from "../../../components/SmeDocumentUploadForm";
import { ORGANIZATION_TYPE } from "../../../enums/partnerType";

const WebSmeIndividualDocumentUploadPage = () => {
  const navigate = useNavigate();
  const { language } = useSelector((state: RootState) => state.config);
  const { t } = useTranslation(["common", "documentUpload"]);

  const onBack = () => navigate(`/application?language=${language}`);

  return (
    <WebApplyApplicationLayout step={2}>
      <div className="web-apply-application-container">
        <PageHeader
          style={{ padding: 12 }}
          onBack={onBack}
          title={<strong>{t("documentUpload:pageTitle")}</strong>}
        />
        <SmeDocumentUploadForm />
      </div>
    </WebApplyApplicationLayout>
  );
};

export default WebSmeIndividualDocumentUploadPage;
