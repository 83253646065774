import { CheckCircleFilled } from "@ant-design/icons";
import { nanoid } from "@reduxjs/toolkit";
import { Space } from "antd";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createBreakpoint } from "react-use";

const useBreakpoint = createBreakpoint({ XL: 1280, L: 768, S: 350 });

const EkycCheckList: React.FC<{ retry?: boolean }> = ({ retry }) => {
  const { t } = useTranslation(["common", "identityVerification"]);
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === "S";

  const ekycTipsList = useMemo(
    () =>
      [
        t("identityVerification:ekycTip1"),
        t("identityVerification:ekycTip2"),
        t("identityVerification:ekycTip3"),
        t("identityVerification:ekycTip4"),
      ].map((tip) => <CheckListItem key={nanoid()} tip={tip} />),
    [t],
  );

  return (
    <div className="mb-4">
      <h3 className="mb-3 text-center font-semibold text-[#000000d7]">
        {retry
          ? t("identityVerification:ekycTipsRetryTitle")
          : t("identityVerification:ekycTipsTitle")}
      </h3>
      <div className={`flex justify-center ${isMobile ? "px-6" : "px-8"}`}>
        <div className="flex flex-col justify-start">{ekycTipsList}</div>
      </div>
    </div>
  );
};

const CheckListItem: React.FC<{ tip: string }> = ({ tip }) => {
  return (
    <Space align="start" className="mb-3">
      <CheckCircleFilled style={{ color: "#52c41a" }} />
      <p className="mb-0 text-start text-[#000000a6]">{tip}</p>
    </Space>
  );
};

export default EkycCheckList;
