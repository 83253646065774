import { beSetupAxiosInstance } from "../../axios/beSetupAxios";
import { CreateApplicationDraftPayload } from "../../features/application/applicationSlice";

const getRequiredFileByPartnerType = async (requestData: any) => {
  const { partnerType } = requestData;
  const url = `/redCash/api/v1.0/sme/file/getRequiredFileByPartnerType/type/${partnerType}`;
  const res = await beSetupAxiosInstance.get(url);
  return res.data;
};

const smeFileService = {
  getRequiredFileByPartnerType,
};

export default smeFileService;
